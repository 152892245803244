import React from "react";
import {motion} from "framer-motion";
import {Menu, MenuItem} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useNavigate} from "react-router-dom";

const useStyles = makeStyles({
    root: {
        borderBottom: "1px solid var(--main-accent-color)",
    },
});

function NaviLis({setIsOpen}) {
    const navigate = useNavigate();
    const classes = useStyles();

    const naviItem = {
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.5,
                ease: "easeOut",
            },
        },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.5,
                ease: "easeOut",
            },
        }
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [veranstaltung, setVeranstalltung] = React.useState(null);
    const [verleih, setVerleih] = React.useState(null);
    const [team, setTeam] = React.useState(null);
    const [teamUnterMenu, setTeamUnterMenu] = React.useState(null);
    const [MottoUnterMenu, setMottoUnterMenu] = React.useState(null);
    const [spielundSpas, setSpielundSpas] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleOpenVeranstaltung = (event) => {
        setVeranstalltung(event.currentTarget);
    };

    const handleOpenVerleih = (event) => {
        setVerleih(event.currentTarget);
    };

    const handleOpenTeam = (event) => {
        setTeam(event.currentTarget);
    };

    const handleOpenTeamUntermenu = (event) => {
        setTeamUnterMenu(event.currentTarget);
        setTeam(null);
    };

    const handleOpenMottoUnterMenu = (event) => {
        setMottoUnterMenu(event.currentTarget);
        setVeranstalltung(null);
    };

    const handleOpenSpielundSpas = (event) => {
        setSpielundSpas(event.currentTarget);
    };

    const handleZweigstellenClick = (zweig) => {
        navigate("/zweigstelle/" + zweig);
        setAnchorEl(null);
        setIsOpen(false);
    };

    const handleVeranstalltungClick = (veranstaltung) => {
        navigate("/veranstaltung/" + veranstaltung);
        setVeranstalltung(null);
        setIsOpen(false);
        setMottoUnterMenu(null);
    };

    const handleVerleihClick = (verleihung) => {
        navigate("/verleih/" + verleihung);
        setVerleih(null);
        setIsOpen(false);
    };

    const handleTeamClick = (teamseite, ignoreNavi) => {
        if (!ignoreNavi)
            navigate("/team/" + teamseite);
        setTeam(null);
        setTeamUnterMenu(null);
        setIsOpen(false);
    };

    const handleSpielundSpasClick = (eventmodul) => {
        navigate("/eventmodule/" + eventmodul);
        setSpielundSpas(null);
        setIsOpen(false);
    };

    return (
        <>
            <motion.li variants={naviItem}>
                <a onMouseOver={handleOpenTeam} onClick={handleOpenTeam}>Wir über uns</a>
                <Menu style={{marginLeft: "100px"}} anchorEl={teamUnterMenu} keepMounted open={Boolean(teamUnterMenu)}
                      onClose={() => setTeamUnterMenu(null)}>
                    <MenuItem onClick={() => {
                        navigate("/Büro");
                        handleTeamClick("", true);
                    }} divider className={classes.root}>Mitarbeiter</MenuItem>
                    <MenuItem onClick={() => handleTeamClick("lager")} divider className={classes.root}>Lager</MenuItem>
                    <MenuItem onClick={() => handleTeamClick("animateur")} divider
                              className={classes.root}>Animateur</MenuItem>
                </Menu>
                <Menu style={{marginTop: "65px"}} anchorEl={team} keepMounted open={Boolean(team)}
                      onClose={() => setTeam(null)}>
                    <div onMouseLeave={() => setTeam(null)}>
                        <MenuItem onClick={handleOpenTeamUntermenu} divider className={classes.root}>Team</MenuItem>
                        <MenuItem onClick={() => handleTeamClick("fahrzeuge")} divider
                                  className={classes.root}>Fahrzeuge</MenuItem>
                        <MenuItem onClick={() => handleTeamClick("halle")} divider
                                  className={classes.root}>Halle/Büro</MenuItem>
                    </div>
                </Menu>
            </motion.li>
            <motion.li variants={naviItem}>
                <a onMouseOver={handleClick} onClick={handleClick}>Zweigstellen</a>
                <Menu style={{marginTop: "65px"}} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)}>
                    <div onMouseLeave={() => setAnchorEl(null)}>
                        <MenuItem onClick={() => handleZweigstellenClick("berlin")} divider
                                  className={classes.root}>Berlin</MenuItem>
                        <MenuItem onClick={() => handleZweigstellenClick("nrw")} divider
                                  className={classes.root}>NRW</MenuItem>
                        <MenuItem onClick={() => handleZweigstellenClick("hamburg")} divider
                                  className={classes.root}>Hamburg</MenuItem>
                        <MenuItem onClick={() => handleZweigstellenClick("bad-segeberg")} divider
                                  className={classes.root}>Bad Segeberg</MenuItem>
                    </div>
                </Menu>
            </motion.li>
            <motion.li variants={naviItem}>
                <a onClick={() => handleSpielundSpasClick("schlittschuhbahn")}>Schlittschuhbahn</a>
            </motion.li>
            <motion.li variants={naviItem}>
                <a onClick={() => handleSpielundSpasClick("animation")}>Animation</a>
            </motion.li>
            <motion.li variants={naviItem}>
                {/*EHEMALS SPIEL UND SPAß */}
                <a onMouseOver={handleOpenSpielundSpas} onClick={handleOpenSpielundSpas}>Eventmodule</a>
                <Menu style={{marginTop: "65px"}} anchorEl={spielundSpas} keepMounted open={Boolean(spielundSpas)}
                      onClose={() => setSpielundSpas(null)}>
                    <div onMouseLeave={() => setSpielundSpas(null)}>
                        <MenuItem onClick={() => handleSpielundSpasClick("actionundsport")} divider
                                  className={classes.root}>Action und Sport</MenuItem>
                        <MenuItem onClick={() => handleSpielundSpasClick("kinder")} divider
                                  className={classes.root}>Kinder</MenuItem>
                        <MenuItem onClick={() => handleSpielundSpasClick("spiele")} divider
                                  className={classes.root}>Spiele</MenuItem>
                        <MenuItem onClick={() => handleSpielundSpasClick("vr")} divider className={classes.root}>Virtual
                            Reality Simulator</MenuItem>
                    </div>
                </Menu>
            </motion.li>
            <motion.li variants={naviItem} onClick={() => setIsOpen(false)}>
                <a onClick={() => handleSpielundSpasClick("ausstattung")}>Ausstattung</a>
            </motion.li>
            <motion.li variants={naviItem}>
                <a onMouseOver={handleOpenVeranstaltung} onClick={handleOpenVeranstaltung}>Veranstaltungskonzepte</a>
                <Menu style={{marginTop: "65px"}} anchorEl={veranstaltung} keepMounted open={Boolean(veranstaltung)}
                      onClose={() => setVeranstalltung(null)}>
                    <div onMouseLeave={() => setVeranstalltung(null)}>
                        <MenuItem onClick={() => handleVeranstalltungClick("mottoparty")} divider
                                  className={classes.root}>Mottoparty</MenuItem>
                        <MenuItem onClick={() => handleVeranstalltungClick("abendveranstaltungen")} divider
                                  className={classes.root}>Abendveranstaltungen</MenuItem>
                        <MenuItem onClick={() => handleVeranstalltungClick("firmenevents")} divider
                                  className={classes.root}>Incentives und Firmenevents</MenuItem>
                        <MenuItem onClick={() => handleVeranstalltungClick("spielmobile")} divider
                                  className={classes.root}>Spielmobile</MenuItem>
                    </div>
                </Menu>
            </motion.li>
        </>
    )
}

export default NaviLis