import React from "react";
//Global Style
import GlobalStyle from "./components/GlobalStyle";
import {AnimatePresence} from "framer-motion";
import {createTheme} from "@material-ui/core";
import {ThemeProvider} from '@mui/material/styles';
import {Route, Routes, useLocation} from "react-router-dom";
import Startpage from "./pages/Startpage";
import AGB from "./pages/AGB";
import Datenschutz from "./pages/Datenschutz";
import Impressum from "./pages/Impressum";
import TopNav from "./components/TopNav";
import Zweigstelle from "./pages/Zweigstelle";
import Verleihung from "./pages/Verleihung";
import Veranstaltung from "./pages/Veranstaltung";
import Kataloge from "./pages/Kataloge";
import Copyright from "./components/Copyright";
import Footer from "./components/Footer";
import TeamBüro from "./pages/TeamBüro";
import Prices from "./pages/Prices";
import PreislisteÜberblick from "./pages/PreislisteÜberblick";

export const PUBLIC_CND_BASEURL = ""

function App() {
    const location = useLocation();
    const theme = createTheme({
        palette: {
            primary: {
                main: '#f00',
            },
            secondary: {
                main: '#fff',
            },
        },
    });

    return (
        <div className="App">
            <GlobalStyle/>

            {location.pathname !== "/veranstaltung/mottoparty" &&
                <Copyright/>
            }
            <ThemeProvider theme={theme}>
                {location.pathname !== "/" &&
                    <TopNav position={"relative"}/>
                }
                <AnimatePresence mode='wait'>
                    <Routes location={location}>
                        <Route path="/" exact element={<Startpage/>}/>
                        <Route path="/Büro" exact element={<TeamBüro/>}/>
                        <Route path="/zweigstelle/:zweig" element={<Zweigstelle/>}/>

                        <Route path="/team/:team" element={<Verleihung key={location.pathname}/>}/>
                        <Route path="/verleih/:verleihung" element={<Verleihung key={location.pathname}/>}/>
                        <Route path="/eventmodule/vr" exact element={<Verleihung key={location.pathname}/>}/>

                        <Route path="/eventmodule/:type" element={<Veranstaltung key={location.pathname}/>}/>
                        <Route path="/veranstaltung/:type" element={<Veranstaltung key={location.pathname}/>}/>
                        <Route path="/preisliste/:type" element={<Veranstaltung key={location.pathname}/>}/>

                        <Route path="/preisliste" element={<PreislisteÜberblick/>}/>
                        <Route path="/kataloge" element={<Kataloge/>}/>
                        <Route path="/prices/:url" element={<Prices/>}/>
                        <Route path="/impressum" element={<Impressum/>}/>
                        <Route path="/datenschutz" element={<Datenschutz/>}/>
                        <Route path="/agb" element={<AGB/>}/>
                    </Routes>
                    <Footer/>
                </AnimatePresence>
            </ThemeProvider>
        </div>
    );
}

export default App;
