import React, {useState} from "react";
import Veranstaltung from "./Veranstaltung";
import {Divider, IconButton, InputBase} from "@mui/material";
import {Paper} from "@material-ui/core";
import SearchIcon from '@mui/icons-material/Search';
import {preislistData} from "../preislistData";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Prices from "./Prices";
import {useNavigate} from "react-router-dom";

function PreislisteÜberblick() {
    const navigate = useNavigate();
    const [prices, setPrices] = useState(preislistData);
    const [searchedPrice, setSearchedPrice] = useState(null);

    const search = (e) => {
        e.preventDefault();
        const query = e.target.query.value;
        let results = [];
        prices.map((price) => {
            price.items.map((item, index) => {
                if (item.titel?.includes(query)) {
                    const newItem = item;
                    if(index === 0)
                        newItem.url = price.url;
                    results.push(item)
                }
            });
        });
        if (results.length <= 0)
            alert("Keine Ergebnisse");
        else
            setSearchedPrice(results);
    }

    return (
        <>
            {searchedPrice &&
                <Dialog
                    fullScreen
                    open={searchedPrice}
                    onClose={() => setSearchedPrice(null)}
                >
                    <AppBar sx={{position: 'relative'}}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={() => setSearchedPrice(null)}
                                aria-label="close"
                            >
                                <CloseIcon/>
                            </IconButton>
                            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                                Ergebnisse
                            </Typography>
                            <Button autoFocus color="inherit" onClick={() => navigate(searchedPrice[0].url)}>
                                Zur Unterseite begben
                            </Button>
                        </Toolbar>
                    </AppBar>
                    <div>
                        <Prices aPrice={searchedPrice}/>
                    </div>
                </Dialog>
            }
            <Paper
                component="form"
                sx={{p: '2px 4px', display: 'flex', alignItems: 'center'}}
                onSubmit={search}
            >
                <InputBase
                    sx={{ml: 1, flex: 1}}
                    placeholder="Artikel suchen"
                    name={"query"}
                    inputProps={{'aria-label': 'search article'}}
                />
                <IconButton type="submit" sx={{p: '10px'}} aria-label="search">
                    <SearchIcon/>
                </IconButton>
            </Paper>
            <Veranstaltung/>
        </>
    )
}

export default PreislisteÜberblick