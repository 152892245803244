import React from "react";

import {styled} from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import {useMediaQuery} from "@material-ui/core";
import {PUBLIC_CND_BASEURL} from "../App";

const ImageButton = styled(ButtonBase)(({theme}) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
        width: '100% !important', // Overrides inline-style
        height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid currentColor',
        },
    },
}));

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
});

const Image = styled('span')(({theme}) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({theme}) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.5,
    transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({theme}) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
}));

function Motto({type, handleClick, width, noMargin}) {
    const matches600 = useMediaQuery('(max-width:600px)');
    const matches1300 = useMediaQuery('(max-width:1300px)');

    return (
        <>

            <ImageButton
                focusRipple
                key={type.showLike}
                style={{
                    width: !width ? "33%" : width,
                    height: matches600 && "200px",
                    margin: noMargin ? "0px" : "1.5% 1.5% 1.5% 1.5%",
                    flex: !matches1300 ? "0 1 19%" : (!matches600 ? "0 1 23%" : "1 1 100%"),
                }}
                onClick={() => handleClick(type)}
            >
                <ImageSrc style={{backgroundImage: `url(${PUBLIC_CND_BASEURL + type.thumbnail})`}}/>
                <ImageBackdrop className="MuiImageBackdrop-root"/>
                <Image>
                    <Typography
                        component="span"
                        variant="subtitle1"
                        color="inherit"
                        maxWidth={"75%"}
                        sx={{
                            position: 'relative',
                            p: 2,
                            pt: 2,
                            pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                        }}
                    >
                        {type.showLike}
                        <ImageMarked className="MuiImageMarked-root"/>
                    </Typography>
                </Image>
            </ImageButton>
        </>
    )
}

export default Motto