import React from "react";
import {Box} from "@mui/material";
import PersonCard from "../components/PersonCard";
import {motion} from "framer-motion";
import {pageAnimation} from "../animation";
import ScrollTop from "../components/ScrollTop";
import Block from "../components/Block";

function TeamBüro() {
    return (
        <>
            <motion.div
                exit="exit"
                variants={pageAnimation}
                initial="hidden"
                animate="show"
            >
                <Block blockWidth={"75%"} title={"S.O.F.A. Team"}
                       desc={"Herzlich Willkommen bei der S.O.F.A. GmbH, einer Full-Service Eventagentur, basiert im wunderschönen Schleswig-Holstein.\n" +
                       "Deutschlandweit planen, organisieren und betreuen wir Ihre Veranstaltung, um Sie zu einem unvergesslichen Erlebnis zu verwandeln.\n" +
                       "Seit über 30 Jahren machen wir die Wünsche unserer Kunden zur Realität!"}/>
                <Box display={"flex"} margin={"50px"} flexWrap={"wrap"} justifyContent={"center"}>
                    <PersonCard name={"Lothar Genz"} stelle={"Geschäftsführung"} bereich={"Bad Segeberg"} tel={"04551 9994727"} mail={"genz@sofa-gmbh.de"} img={"/img/persons/Lothar Genz.JPG"}/>
                    <PersonCard name={"Christina Moldt"} stelle={"Büroleitung"} bereich={"Hamburg"} tel={"040 50740565"} mail={"moldt@sofa-hamburg.de"} img={"/img/persons/Christina Moldt.JPG"}/>
                    <PersonCard name={"Vanessa Ulicnik"} stelle={"Projektleitung Animation"} bereich={"Berlin"} tel={"030 34787215"} mail={"ulicnik@sofa-gmbh.de"} img={"/img/persons/Vanessa Ulicnik.JPG"}/>
                    <PersonCard name={"Ruzanna Aleksanyan"} stelle={"Projektleitung Schlittschuhbahn"} bereich={"NRW"} tel={"02195 927877"} mail={"Aleksanyan@sofa-gmbh.de"} img={"/img/persons/Ruzanna Aleksanyan.jpg"}/>
                </Box>
            </motion.div>
            <ScrollTop/>
        </>
    )
}

export default TeamBüro