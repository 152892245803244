import React from "react";
import {Paper} from '@material-ui/core'
import styled from "@emotion/styled";
import {PUBLIC_CND_BASEURL} from "../App";

function CarouselItem({item}) {

    return (
        <>
            <Paper>
                <IMG>
                    <img src={PUBLIC_CND_BASEURL + item.src} alt="img"/>
                </IMG>

                {/*<Content>*/}
                {/*    <div className={"flex-middle"}>*/}
                {/*        <h1>{item.heading}</h1>*/}
                {/*        <p>{item.description}</p>*/}
                {/*        <a href="/objects" className={"styled_button"}>Alle Bungalows</a>*/}
                {/*    </div>*/}
                {/*</Content>*/}
            </Paper>
        </>
    )
}

const IMG = styled.div`
  height: 100vh;
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--other-color);
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 10;

  .flex-middle {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-color: rgb(0, 0, 0, 0.5);
    padding: 20px 50px;
    width: 50%;
    @media (max-width: 1200px) {
      width: unset !important;
    }
    @media (max-width: 400px) {
      text-align: center;
    }
  }

  a {
    text-decoration: none;
  }

  h1, p {
    color: white;
    text-align: right;
  }

  h1 {
    font-size: 50px;
    width: 100%;
    @media (max-width: 650px) {
      font-size: 30px;
    }
    @media (max-width: 400px) {
      font-size: 25px;
      width: 100%;
    }
  }

  p {
    font-size: 25px;
    margin: 20px 0;
    width: 75%;
    float: right;
    @media (max-width: 650px) {
      font-size: 15px;
      margin: 10px 0;
    }
    @media (max-width: 400px) {
      font-size: 12.5px;
      width: 90%;
      margin: 5px 0;
    }
  }
`;

export default CarouselItem