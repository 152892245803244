import React, {useState} from "react";
import {Link} from "react-router-dom";
import {motion} from "framer-motion";
import {Box, useMediaQuery} from "@material-ui/core";
import MenuOutlinedIcon from '@material-ui/icons/MenuOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import {makeStyles} from "@material-ui/core/styles";
import styled from "styled-components";
import NaviLis from "./NaviLis";
import {PUBLIC_CND_BASEURL} from "../App";

const useStyles = makeStyles((theme) => ({
    root: {
        color: "black",
        fontSize: "40px",
        marginRight: "15px",
    },
}));

const navigator = {
    hidden: {
        y: -100,
        height: "0px",
        transition: {
            duration: 0.75,
            ease: "easeOut",
        },
    },
    visible: {
        y: 0,
        height: "100%",
        transition: {
            duration: 0.5,
            ease: "easeOut",
            delayChildren: 0.3,
            staggerChildren: 0.1
        },
    }
};

const slideIn = {
    hidden: {
        y: -100,
        transition: {
            duration: 0.5,
            ease: "easeOut",
        },
    },
    visible: {
        y: 0,
        transition: {
            duration: 0.5,
            ease: "easeOut",
        },
    }
}

function TopNav({position}) {
    const [isOpen, setIsOpen] = useState(false);
    const matches = useMediaQuery('(max-width:600px)');
    const classes = useStyles();

    const toggleNavi = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>
            <StyledNav style={{position: `${position}`}} variants={slideIn} initial={"hidden"} animate={"visible"} exit={"hidden"}>
                <Box display={"flex"} width={matches && "100%"}
                     justifyContent={!matches ? "center" : "space-between"}
                     alignItems={"center"}>
                    <Box>
                        <Link to={"/"}>
                            <img src={PUBLIC_CND_BASEURL + "/img/logo.png"} alt="logo"/>
                        </Link>
                    </Box>
                    {matches &&
                    <>
                        {isOpen
                            ?
                            <CloseOutlinedIcon classes={{root: classes.root}} onClick={toggleNavi}/>
                            :
                            <MenuOutlinedIcon classes={{root: classes.root}} onClick={toggleNavi}/>
                        }
                    </>
                    }
                </Box>
                <motion.ul variants={navigator} animate={(isOpen || !matches) ? "visible" : "hidden"}
                           initial={"hidden"}
                           style={matches && {paddingTop: "0px"}}>
                    <NaviLis setIsOpen={setIsOpen}/>
                </motion.ul>
            </StyledNav>
        </>
    )
}

const StyledNav = styled(motion.nav)`
  min-height: 10vh;
  width: 100%;
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 10rem;
  background: var(--main-bg-color-sec);
  top: 0;
  z-index: 1000;
  a {
    color: var(--text-color);
    text-decoration: none;
    font-size: 17px;
  }
  ul {
    display: flex;
    list-style: none;
  }
  li {
    padding-left: 7rem;
    position: relative;
    cursor: pointer;
    transition: 0.3s;
    
    &:hover:not(.logo) {
        text-decoration: underline;
        text-decoration-thickness: 3px;
    }
  }
  @media (max-width: 1300px) {
    flex-direction: column;
    padding: 2rem 1rem;
    #logo {
      display: inline-block;
      margin: 1rem;
    }
    ul {
      padding: 2rem;
      justify-content: space-around;
      width: 100%;
      li {
        padding: 0;
      }
    }
  }
  @media (max-width: 600px) {
    ul {
      flex-direction: column;
      padding: 2rem;
      padding-top: 1rem;
      padding-bottom: 0px;
      align-items: center;
      
      li {
        padding-bottom: 2rem;
      }
    }
  }
`;

const Line = styled(motion.div)`
  height: 0.3rem;
  background: var(--main-accent-color);
  width: 0%;
  position: absolute;
  bottom: -80%;
  left: 60%;
  @media (max-width: 1300px) {
    left: 0%;
  }
  
  @media (max-width: 600px) {
    bottom: 30%; 
  }
`;

export default TopNav