import React from "react";
import {Box, useMediaQuery} from "@material-ui/core";
import KatalogCard from "../components/KatalogCard";
import {pageAnimation} from "../animation";
import ScrollTop from "../components/ScrollTop";
import {motion} from "framer-motion";

function Kataloge() {
    const matches500 = useMediaQuery('(max-width:500px)');

    return (
        <>
            <motion.div
                exit="exit"
                variants={pageAnimation}
                initial="hidden"
                animate="show"
            >
            <Box margin={!matches500 ? "3% 50px 0 50px" : "5% 15px 0 15px"} display={"grid"} gridGap={!matches500 ? "50px" : "10px"}
                 gridTemplateColumns={matches500 ? "repeat(auto-fill, minmax(250px, 1fr))" : "1fr 1fr 1fr"}>
                <KatalogCard redirect={"https://online.flipbuilder.com/Ruzanna/bklu/"} thumbnail={"/img/kataloge/faces/face_Hauptkatalog.png"} buttonText={"Hauptkatalog anschauen"}/>
                <KatalogCard redirect={"https://online.flipbuilder.com/Ruzanna/svdn/"} thumbnail={"/img/kataloge/faces/face_Animation.png"} buttonText={"Animation anschauen"}/>
                <KatalogCard redirect={"https://online.flipbuilder.com/Ruzanna/twjl/"} thumbnail={"/img/kataloge/faces/face_Erlebnis_Shopping.png"} buttonText={"Erlebnis Shopping anschauen"}/>
                <KatalogCard redirect={"https://online.flipbuilder.com/Ruzanna/lmdo/"} thumbnail={"/img/kataloge/faces/face_Spielmobile.png"} buttonText={"Spielmobile anschauen"}/>
                <KatalogCard redirect={"https://online.flipbuilder.com/Ruzanna/yijh/"} thumbnail={"/img/kataloge/faces/face_Veranstaltungshighlights.png"} buttonText={"Veranstaltungshighlights anschauen"}/>
                <KatalogCard redirect={"https://online.flipbuilder.com/Ruzanna/kxqe/"} thumbnail={"/img/kataloge/faces/face_Veranstaltungskonzepte.png"} buttonText={"Veranstaltungskonzepte anschauen"}/>
                <KatalogCard redirect={"https://online.flipbuilder.com/Ruzanna/ynsh/"} thumbnail={"/img/kataloge/faces/face_Virtual Reality.png"} buttonText={"Virtual Reality anschauen"}/>
                <KatalogCard redirect={"https://online.flipbuilder.com/Ruzanna/zunq/"} thumbnail={"/img/kataloge/faces/face_Winterzauber.png"} buttonText={"Winter Spezial anschauen"}/>
                <KatalogCard redirect={"https://online.flipbuilder.com/Ruzanna/fbul/"} thumbnail={"/img/kataloge/faces/face_animationandentertain.png"} buttonText={"Animation und Entertainment anschauen"}/>
            </Box>
            </motion.div>
            <ScrollTop/>
        </>
    )
}

export default Kataloge