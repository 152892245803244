// https://www.convertcsv.com/csv-to-json.htm
export const preislistData = [
    {
        url: "/prices/wegmitdemchef",
        items: [
            {
                "titel": "Beach Games",
                "bild": "",
                "beschreibung": "Mögliche Stationen sind:\n- Boccia\n- Beachvolleyball\n- Strandski\n- Luftmatratzen Rallye\n- Wissensquiz\n- Limbo\n\nJe nach Anzahl der Gäste können beliebig viele Stationen gebucht werden.                                                                                              Des weiteren dazu buchbar: Hai Attacke, Kokosnussfangen, Surfsimulator,Wasserzorbs mit Pool\nAnzahl Personen: von 10 bis 200 Personen ",
                "personenanzahl": "10 - 20 Pers.\n21 - 30 Pers.\n31 - 40 Pers.\n41 - 50 Pers.\nab 51 Pers.",
                "netto": "49,00 €              39,00 €      29,00 €         19,00 €        14,00 €",
                "brutto": "58,31 €       46,41 €        34,51 €       22,61 €             16,66 €",
                "platz_and_tech": ""
            },
            {
                "titel": "Nostalgischer Jahrmarkt",
                "bild": "/img/preisliste/detailed/Cirque du S.O.F.A.jpg",
                "beschreibung": "Unser Angebot enthält:                                                                                                                                              - drei Marktstände mit den klassischen Spielen wie: Dosenwerfen, Dart, Hau den Lukas, sowie eine Popcornmaschiene, kunterbunte Zuckerwatte und einen passeneden Zirkusworkshop für alle Beteiligten. \n\nOptional sorgen wir natürlich auch für die passende Location und Verpflegung. Zudem bieten wir Ihnen zuzügliche Unterhaltung in Form von Wahrsagerinnen, Clowns, Artisten und vielen anderen wunderbaren Kleinkünstlern.\nAnzahl Personen: von 20 bis 500 Personen ",
                "personenanzahl": "10 - 20 Pers.                              21 - 30 Pers.                                     31 - 40 Pers.                                      41 - 50 Pers.                                    Ab 51 Pers.",
                "netto": "49,00 €              39,00 €      29,00 €         19,00 €        14,00 €",
                "brutto": "58,31 €       46,41 €        34,51 €       22,61 €             16,66 €",
                "platz_and_tech": ""
            },
            {
                "titel": "Fitness & Fun",
                "bild": "",
                "beschreibung": "Mögliche Stationen sind:\n- Tischtennis\n- Golfabschlag\n- Interactive Playsystem \n- Fußballdart\n- Human Table Soccer\n- Basketball\n\nJe nach Anzahl der Gäste können beliebig viele Stationen gebucht werden.                   Des weiteren dazu buchbar: Surfsimulator, Kletterwand, Bungee Run, Fußballbouncer und vieles mehr.\nAnzahl Personen: von 10 bis 500 Personen ",
                "personenanzahl": "10 - 20 Pers.                              21 - 30 Pers.                                     31 - 40 Pers.                                      41 - 50 Pers.                                    Ab 51 Pers.",
                "netto": "59,00 €              49,00 €      39,00 €         29,00 €        19,00 €",
                "brutto": "70,21 €      58,31 €       46,41 €        34,51 €       22,61 €             ",
                "platz_and_tech": ""
            },
            {
                "titel": "Friesenabitur",
                "bild": "",
                "beschreibung": "Folgende Disziplinen sind zu meistern:\n- Bosseln                                                                                                                                               - Gummistiefelweitwurf\n- Friesenquiz\n- Wettmelken\n- Schnäpse raten\n- Tau ziehen\n\nPassend dazu kann auch für alle noch eine Friesische Spezialität nach Wahl serviert werden, wie zum Beispiel Bohnensopp, Labskaus oder Grünkohl.\nOptional hinzubuchbar: Milchkübel werfen, Schlauchboot- Wettrennen, Rätsel Aufgabe\nAnzahl Personen: von 10 bis 200 Personen ",
                "personenanzahl": "10 - 20 Pers.                              21 - 30 Pers.                                     31 - 40 Pers.                                      41 - 50 Pers.                                    ab 51 Pers.",
                "netto": "49,00 €              39,00 €      29,00 €         19,00 €        14,00 €",
                "brutto": "58,31 €       46,41 €        34,51 €       22,61 €             16,66 €",
                "platz_and_tech": ""
            },
            {
                "titel": "Gamer Days",
                "bild": "",
                "beschreibung": "Mit im Grundpaket enthalten:\n- Virtual Reality 5D Simulator\n- Virtual Reality Gaming Ecke\n- Nostalgie Flipper\n- Airhockey Tisch\n\nMit im Preis enthalten sind natürlich flauschige Sitzsäcke und weiche Loungemöbel zum entspannten Zocken.                                                           Außerdem dazubuchbar: IPS Shooting System, Rennsimulator, Wii Sports, Carrera Bahn, Dartautomat, Boxautomat, VR Tischkicker und vieles mehr.\nAnzahl Personen: von 20 bis 500 Personen ",
                "personenanzahl": "20 - 30 Pers.                              31 - 40 Pers.                                     41 - 50 Pers.                                      51 - 60 Pers.                                    ab 61 Pers.",
                "netto": "110,00 €              89,00 €      69,00 €         59,00 €        49,00 €",
                "brutto": "130,90 € 105,91 €    82,11 €     70,21 €     58,31 €",
                "platz_and_tech": ""
            },
            {
                "titel": "Gatsby Abend",
                "bild": "",
                "beschreibung": "Im Grundpaket enthalten:\n- Black Jack\n- Poker\n- Roulette\n- inkl. geschulter Croupies\n\nSelbstverständlich bieten wir auf Wunsch auch gerne die passende Location, inklusive musikalische Untermalung, Getränke und Gadgets, sowie weitere spannende Nebenprogramme wie z.B.: Fotobox, Swingkurs, eine spannende Mafiarunde oder ein klassisches Bühnenprogramm\nAnzahl Personen: von 20 bis 500 Personen ",
                "personenanzahl": "20 - 30 Pers.                              31 - 40 Pers.                                     41 - 50 Pers.                                      51 - 60 Pers.                                    ab 61 Pers.",
                "netto": "95,00 €              79,00 €      65,00 €         49,00 €        35,00 €",
                "brutto": "113,05 €       94,01 €        77,35 €       58,31 €             41,65 €",
                "platz_and_tech": ""
            },
            {
                "titel": "Firmenfeier am Kalkberg im Indian Village",
                "bild": "",
                "beschreibung": "Mögliche Stationen sind:\n" +
                    "- Rodeo\n" +
                    "- Bogenschießen\n" +
                    "- Schnäpse raten\n" +
                    "Lasso werfen\n" +
                    "Kuhwettmelken\n" +
                    "Hufeisen werfen\n" +
                    "\n" +
                    "Im Paket enthalten:\n" +
                    "Betreuung\n" +
                    "Eintrittskarte für die Karl-May-Spiele\n" +
                    "Eintrittskarte für das Indian Village",
                "personenanzahl": "10 - 100 Pers.",
                "netto": "ab 90,00 €",
                "brutto": "ab 107,10 €",
                "platz_and_tech": ""
            },
            {
                "titel": "Kanufahrt rund um Lübeck",
                "bild": "/img/incentives/kanufahrt/thumbnail.jpg",
                "beschreibung": "Nach der Einführung in die technischen Gegebenheiten, erhalten Sie Schwimmweste, Paddel und springen ins Kanu. Genießen Sie Ihre Kanufahrt durch die wunderschöne Natur.\n" +
                    "Mit im Grundpaket enthalten sind:\n" +
                    "- Einweisung und Betreuung durch einen professionellen Trainer\n" +
                    "- Tourenbeschreibung\n" +
                    "- Schwimmweste und Paddel\n" +
                    "- Regenjacken bei Bedarf\n" +
                    "- Bootstransport",
                "personenanzahl": "10-100 Pers.",
                "netto": "ab 50,00 €",
                "brutto": "ab 59,50 €",
                "platz_and_tech": ""
            },
            {
                "titel": "Floßbau",
                "bild": "/img/incentives/floßbau/thumbnail.jpg",
                "beschreibung": "Erleben Sie ein frisches und unverbrauchtes Teamevent mit hohem Spaßfaktor. Es ist wirklich leicht, ein Floß zu bauen. Aber ohne Teamarbeit, gute Kommunikation und Ideenfindung wird es nichts. Das Ergebnis wird direkt getestet. Erleben Sie einen Ausflug am Wasser. ",
                "personenanzahl": "10-100 Pers.",
                "netto": "ab 95,00 €",
                "brutto": "ab 113,05 €",
                "platz_and_tech": ""
            },
            {
                "titel": "Piratenfest",
                "bild": "/img/incentives/piratenfest/thumbnail.JPG",
                "beschreibung": "Mögliche Stationen sind:\n" +
                    "- Bogenschießen\n" +
                    "- Haileiter\n" +
                    "Tau ziehen\n" +
                    "Säbel fangen\n" +
                    "\n" +
                    "Im Paket enthalten:\n" +
                    "Betreuung\n" +
                    "Dekoration\n" +
                    "Personal im passenden Outfit\n" +
                    "Piratenpass absolvieren",
                "personenanzahl": "10-100 Pers.",
                "netto": "ab 60,00 €",
                "brutto": "ab 71,40 €",
                "platz_and_tech": ""
            },
            {
                "titel": "Mini WM",
                "bild": "",
                "beschreibung": "Mit im Grundpaket enthalten sind:\n- XXL Kicker\n- Torwand\n- Human Table Soccer\n- Fußball Dart\n- Shoot Out\n\nAnschließend können Ihre Mitarbeiter sich auf Wunsch noch an unserem Reichhaltigen Cateringbuffet stärken und dannach gespannt ein Spiel auf einer XXL Leinwand verfolgen.\nZudem optional hinzubuchbar: Fußball fangen, Fußball Rodeo,Fußball Ringen, Kickpoint Simulator, Fußball Parcours\nAnzahl Personen: von 10 bis 600 Personen ",
                "personenanzahl": "10 - 20 Pers.                              21 - 30 Pers.                                     31 - 40 Pers.                                      41 - 50 Pers.                                    ab 51 Pers.",
                "netto": "49,00 €              39,00 €      29,00 €         19,00 €        14,00 €",
                "brutto": "58,31 €       46,41 €        34,51 €       22,61 €             16,66 €",
                "platz_and_tech": ""
            },
            {
                "titel": "Natural Sources",
                "bild": "",
                "beschreibung": "Im Grundpaket enthaltene Aktivitäten sind:\n- Bogenschießen\n- Kuh melken\n- Tauziehen\n- Rasenski\n- Insektenhotel bauen\n- Wasser filtern\n\nAuf Wunsch lassen sich die verschiedenen Aufgaben auch gerne erweitern. \nAnzahl Personen: von 10 bis 100 Personen ",
                "personenanzahl": "10 - 20 Pers.                              21 - 30 Pers.                                     31 - 40 Pers.                                      41 - 50 Pers.                                    ab 51 Pers.",
                "netto": "49,00 €              39,00 €      29,00 €         19,00 €        14,00 €",
                "brutto": "58,31 €       46,41 €        34,51 €       22,61 €             16,66 €",
                "platz_and_tech": ""
            },
            {
                "titel": "Oktoberfest",
                "bild": "",
                "beschreibung": "Mit im Grundpaket enthalten sind:\n- Maßkrugstemmen\n- Nagelschlagen\n- Hau den Lukas\n- Rodeo Bulle\n- Maßkrug schieben\n\nMit dem passenden Catering runden Sie Ihr Oktoberfest noch gebührend ab. Alle bayerischen Spezialitäten sind vertreten, die Klassiker wie Weißwurst, Schnitzel, Haxe, Kaiserschmarrn und Brezel. Und mit das wichtigste auf einem Oktoberfest: a Mass Bier!\nAnzahl Personen: von 10 bis 1000 Personen ",
                "personenanzahl": "10 - 20 Pers.                              21 - 30 Pers.                                     31 - 40 Pers.                                      41 - 50 Pers.                                    ab 51 Pers.",
                "netto": "49,00 €              39,00 €      29,00 €         19,00 €        14,00 €",
                "brutto": "58,31 €       46,41 €        34,51 €       22,61 €             16,66 €",
                "platz_and_tech": ""
            },
            {
                "titel": "Seifenkistenrennen",
                "bild": "",
                "beschreibung": "Es werden gleichgroße Teams gebildet, welche vor Ort ihre selbst entworfene Seifenkiste bauen. Wir bringen genug Material mit, so dass jede Seifenkiste ein Unikat wird.\n\nFalls vor Ort eine Abschüssige Fläche vorhanden ist, statten wir die Rennbahn mit allem aus, was dazu gehört. Falls dies nicht gegeben ist, bauen wir auch gerne eine Rampe auf, von welcher die Seifenkisten dann starten können. Für die Teams, welche mit dem Bau Ihrer Seifenkiste schon eher fertig sind, oder schon Ihr Rennen gefahren sind, gibt es die Möglichkeit ihre Zeit an unseren leckeren Cateringständen zu vertreiben.\nAnzahl Personen: von 20 bis 200 Persone",
                "personenanzahl": "20 - 30 Pers.                              31 - 40 Pers.                                     41 - 50 Pers.                                      51 - 60 Pers.                                    ab 61 Pers.",
                "netto": "109,00 €              99,00 €      89,00 €         79,00 €        59,00 €",
                "brutto": "129,71 €       117,81 €        105,91 €       94,01 €             70,21 €",
                "platz_and_tech": ""
            },
            {
                "titel": "Wild West Abend",
                "bild": "",
                "beschreibung": "Mögliche Stationen sind:\n- Rodeo Bulle \n- Melkkuh\n- Hufeisen werfen\n- Stockfangspiel\n- Bogenschießen\n\nJe nach Anzahl der Gäste können beliebig viele Stationen gebucht werden. Für eine kleine Verschnaufpause zwischendurch bieten wir auf Wunsch rustikale Sitzmöglichkeiten und ein leckeres BBQ.                                                         Des weiteren dazu buchbar: Ringstechbahn, Goldwaschen, Lasso werfen, Saloon Casino, Schießstand\nAnzahl Personen: von 10 bis 1000 Personen ",
                "personenanzahl": "10 - 20 Pers.                              21 - 30 Pers.                                     31 - 40 Pers.                                      41 - 50 Pers.                                    ab 51 Pers.",
                "netto": "59,00 €              49,00 €      39,00 €         29,00 €        19,00 €",
                "brutto": "70,21 €      58,31 €       46,41 €        34,51 €       22,61 €             ",
                "platz_and_tech": ""
            },
            {
                "titel": "Winterzauber",
                "bild": "",
                "beschreibung": "Ihre Gäste können sich zwischendurch an unseren Marktständen mit einem Glühwein aufwärmen oder sich mit Flammkuchen stärken.\n\nOptionale Spielstationen sind:\n- Schneemann Wrestling\n- Pinguinrennbahn\n- Klettereiswand\n- Biathlon Simulator\n- Rentier Rodeo\n- Snowboard Simulator\n- Schneekugel\n\nAnzahl Personen: von 10 bis 800 Personen ",
                "personenanzahl": "10 - 20 Pers.                              21 - 30 Pers.                                     31 - 40 Pers.                                      41 - 50 Pers.                                    ab 51 Pers.",
                "netto": "59,00 €              49,00 €      39,00 €         29,00 €        19,00 €",
                "brutto": "70,21 €      58,31 €       46,41 €        34,51 €       22,61 €             ",
                "platz_and_tech": ""
            },
            {
                "titel": "     Alle Bruttopreise enthalten die gesetzliche Mehrwertsteuer. Auf Wunsch  besteht es die Möglichkeit für Ihre Veranstaltung ein schones Catering anzurichten. Die Angaben sind ohne Gewähr. Änderungen sind vorbehalten. ",
                "bild": "",
                "beschreibung": "%footer%",
                "personenanzahl": "",
                "netto": "",
                "brutto": "",
                "platz_and_tech": ""
            }
        ],
    },
    {
        url: "/prices/vr",
        items: [
            {
                "titel": "Formel 1 Simulator",
                "bild": "",
                "beschreibung": "Fahren Sie alle gängigen F1 Strecken. Der rote Bolide ist größenverstellbar und bietet somit Rennspaß für Groß und Klein. Durch die hervorragende Übertragung zwischen Boliden und Videospiel fühlen Sie sich wie ein echter Rennfahrer. \n",
                "platz_and_tech": "Platzbedarf: 5 m x 3 m\ntechnische Daten: 230 V / 16 A",
                "netto": "730,00 €",
                "brutto": "868,70 €"
            },
            {
                "titel": "VR 5D Simulator",
                "bild": "",
                "beschreibung": "Mit dem Virtual Reality 5D Simulator nehmen wir Sie mit, auf eine Reise in andere Welten. Durch die Oculus Rift Brillen sehen Sie themenbezogene Filme. Die Bewegung des Sitzes und der simulierte Wind garantieren Ihnen den optimalen 5D Effekt.\n\n",
                "platz_and_tech": "Platzbedarf: ca. 3 m x 2,5 m x 2,5 m\ntechnische Daten: 230 V / 32 A / 5 kWh Bemerkung: Überdachung ist zwingend erforderlich, sowie ein ebern, glatter Anlieferunsweg",
                "netto": "1250,00 €",
                "brutto": "1487,50 €"
            },
            {
                "titel": "VR Holo Cafe",
                "bild": "",
                "beschreibung": "Mit der Holo Cafe Multiplayer VR-Station lässt sich Virtual-Reality gemeinsam völlig neu erleben. Zu viert können hier virtuelle Raumstationen gesteuert, Arenen verteidigt, oder Puzzle gelöst werden. Alles basiert dabei auf Teamwork.",
                "platz_and_tech": "Platzbedarf: 10 m x 10 m\ntechnische Daten: 230 V / 16 A",
                "netto": "2.900,00 €",
                "brutto": "3.451,00 €"
            },
            {
                "titel": "VR Rennsimulator",
                "bild": "",
                "beschreibung": "Das Cockpit wird durch die VR-Brille simuliert und der bewegliche Motion-Simulator macht den Realismus perfekt.\nWährend das Lenkrad Vibrationen und Gegenkräfte der Strecke überträgt, sorgt das Motion System für echte G-Kräfte.",
                "platz_and_tech": "Platzbedarf: 5 m x 3 m\ntechnische Daten: 230 V / 16 A",
                "netto": "1.400,00 €",
                "brutto": "1.666,00 €"
            },
            {
                "titel": "VR Tischkicker",
                "bild": "",
                "beschreibung": "Seit Jahrzehnten sorgen Kickertische weltweit für spannende Wettkämpfe und Entertainment.Bringen Sie die altbewährte Spielmechanik des Tischfußballs auf das nächste Level und machen Sie jedes zwei gegen zwei Spiel zum packenden Duell.",
                "platz_and_tech": "Platzbedarf: 4 m x 4 m\ntechnische Daten: 230 V / 16 A",
                "netto": "1.800,00 €",
                "brutto": "2.142,00 €"
            },
            {
                "titel": "Alle Bruttopreise enthalten die gesetzliche Mehrwertsteuer. Die oben erwähnten Geräte können nur durch die Personal der S.O.F.A. GmbH  benutzt werden. Die Preise beziehen sich auf eine Einsatzdauer von sechs Stunden pro Tag + Auf - und Abbau und zuzüglich Betreuung. Die Angaben sind ohne Gewähr. Änderungen sind vorbehalten. Die Artikel von den Bildern können  abweichen.",
                "bild": "",
                "beschreibung": "%footer%",
                "platz_and_tech": "",
                "netto": "",
                "brutto": ""
            }
        ],
    },
    {
        url: "/prices/spielmobile",
        doNotSort: true,
        items: [
            {
                "titel": "Die Grundausstattung ist in allen Spielmobilen enthalten:\n- XXL Menschen ärgere dich nicht & Spielteppich\n- Bobby Cars & XXL Lego Baustelle\n                    - Stelzen & Pedalos & XXL 4 Gewinnt                                                           \n- lustige Hüpfbälle & Tiere\n- und natürlich Kinderschminken!",
                "bild": "",
                "beschreibung": "%footer%",
                "netto": "",
                "brutto": ""
            },
            {
                "titel": "Mini",
                "bild": "",
                "beschreibung": "Im Mietpreis enthalten sind:                                                                                - Grundausstattung                                                                                               - Hüpfburg: Lila Launeburg                                                                                                                                                            ",
                "netto": "450,00 €",
                "brutto": "535,50 €"
            },
            {
                "titel": "Mittel",
                "bild": "",
                "beschreibung": "Im Mietpreis enthalten sind:                                                                            - Grundausstattung                                                                                                - Hüpfburg: Big Circus                                                                                          - Dosen werfen                                                                                                                      - Kinder \"Hau den Lukas\"                                                                                                              ",
                "netto": "750,00 €",
                "brutto": "892,50 €"
            },
            {
                "titel": "Mega",
                "bild": "",
                "beschreibung": "Im Mietpreis enthalten sind:                                                                            - Grundausstattung                                                                                                - Hüpfburg: Villa Kunterbunt                                                                                        - Rodeo Bulle                                                                                                            - Spieltafeln                                                                                                                                                                                                                               ",
                "netto": "1.150,00 €",
                "brutto": "1.368,50 €"
            },
            {
                "titel": "Kreativ",
                "bild": "",
                "beschreibung": "Im Mietpreis enthalten sind:                                                                            - Grundausstattung                                                                                                - Hüpfburg: Villa Kunterbunt                                                                                - Heißer Draht                                                                                                                          - Bastelstation                                                                                                           - Glitzertattoos                                                                                                         ",
                "netto": "790,00 €",
                "brutto": "940,10 €"
            },
            {
                "titel": "Sport",
                "bild": "",
                "beschreibung": "Im Mietpreis enthalten sind:                                                                            - Grundausstattung                                                                                                - Hüpfburg: Villa Kunterbunt                                                                                      - Fußball Dart                                                                                                                 - Kinder Torwand                                                                                                                   ",
                "netto": "750,00 €",
                "brutto": "892,50 €"
            },
            {
                "titel": "Wasser",
                "bild": "",
                "beschreibung": "Im Mietpreis enthalten sind:                                                                            - Grundausstattung                                                                                                - Hüpfburg: Meerestraum                                                                                      - Entenangeln                                                                                                                   - Kletterleuchturm                                                                                                                  ",
                "netto": "890,00 €",
                "brutto": "1.059,10 €"
            },
            {
                "titel": "Alle Bruttopreise enthalten die gesetzliche Mehrwertsteuer. Die oben erwähnten Geräte können nur durch die Personal der S.O.F.A. GmbH  benutzt werden. Die Preise beziehen sich auf eine Einsatzdauer von sechs Stunden pro Tag + Auf - und Abbau und zuzüglich Betreuung. Die Angaben sind ohne Gewähr. Änderungen sind vorbehalten. Die Artikel von den Bildern können  abweichen.",
                "bild": "",
                "beschreibung": "%footer%",
                "netto": "",
                "brutto": ""
            }
        ],
    },
    {
        url: "/prices/klettern",
        items: [
            {
                "titel": "Hai Leiter",
                "bild": "",
                "beschreibung": "Bei der Haileiter geht es darum, die Strickleiter zu erklimmen und eine Glocke zu läuten, ohne von den Haien erwischt zu werden. Unter den Leitern liegt ein aufblasbares Kissen mit eingenähten Haifischflossen und fängt den Sturz ab.\n",
                "platz_and_tech": "Platzbedarf: 3 m x 3 m\ntechnische Daten: 230 V / 16 A",
                "netto": "300,00 €",
                "brutto": "357,00 €"
            },
            {
                "titel": "Hochseilgarten",
                "bild": "",
                "beschreibung": "Ein außergewöhnlicher Kletterspaß in 10 Metern Höhe. Der Hochseilgarten besteht aus einem Traversensystem. \nDie Kletterer sind mit Klettergurten und Seilen gesichert. Es können bis zu vier Personen gleichzeitig den Kletterparcour.",
                "platz_and_tech": "Platzbedarf: 12 m x 12 m\ntechnische Daten: 230 V / 16 A",
                "netto": "4.800,00 €",
                "brutto": "5.533,50 €"
            },
            {
                "titel": "Kistenstapeln",
                "bild": "",
                "beschreibung": "Der Kletterer versucht mit Hilfe von Getränkekisten bis nach ganz oben zu gelangen.  Gesichert wird dieser mit einem Klettergurt und mit Seilen. Durch das Netz im Turm werden die Zuschauer von den herabstürzenden Kisten geschützt.\n",
                "platz_and_tech": "Platzbedarf: 6 m x 6 m\ntechnische Daten: 230 V / 16 A",
                "netto": "610,00 €",
                "brutto": "725,90 €"
            },
            {
                "titel": "Klettereisberg",
                "bild": "",
                "beschreibung": "Das Ziel des Klettereisbergs ist es, die neun Meter hohe Spitze zu erklimmen. Die Teilnehmer werden mit einem Seil gesichert, zusätzlich ist ein Sturz ganz ungefährlich, da die aufblasbare Matte den Sturz abfedert.\n",
                "platz_and_tech": "Platzbedarf: ca. Ø 8m\ntechnische Daten: 230 V / 16 A",
                "netto": "900,00 €",
                "brutto": "1.071,00 €"
            },
            {
                "titel": "Kletterpfahl",
                "bild": "",
                "beschreibung": "Die Kletterhöhe ist beliebig wählbar, zwischen 4 und 6 Metern. Die Balken werden untereinander befestigt und können in einem Traversensystem oder an einer stabilen Decke auf gehangen werden. Inkl. Klettergurten und Seilen.\n",
                "platz_and_tech": "Platzbedarf: 5 m x 5 m\ntechnische Daten: keine ",
                "netto": "450,00 €",
                "brutto": "535,50 €"
            },
            {
                "titel": "Kletterwand",
                "bild": "",
                "beschreibung": "Diese Kletterwand ist 8,5 Meter hoch.\nDurch die Sicherung von Seilen ist ein sicherer Aufstieg gewährleistet und es kann somit niemandem etwas passieren.\n",
                "platz_and_tech": "Platzbedarf: 7 m x 7 m\ntechnische Daten: 230 V / 16 A",
                "netto": "550,00 €",
                "brutto": "654,50 €"
            },
            {
                "titel": "Kletterwand 8 m",
                "bild": "",
                "beschreibung": "Die 8 Meter hohe Kletterwand besteht aus einem Traversenrahmen, in dem eine Plane mit Klettergriffen eingespannt wird. Die Kletterwand wird mit einem Tank mit 1000 Litern Fassungsvermögen oder mit einem Auto stabilisiert.\n",
                "platz_and_tech": "Platzbedarf: 12 m x 4 m\ntechnische Daten: keine",
                "netto": "450,00 €",
                "brutto": "535,50 €"
            },
            {
                "titel": "Kletterwand Eis",
                "bild": "",
                "beschreibung": "Unsere 10 Meter hohe Kletterwand aus Kunsteis bietet nicht nur einen bildgewaltigen Ausblick, sondern auch eine Menge Spaß!\nDie Kletterer sind natürlich mit Seilen gesichert.\n",
                "platz_and_tech": "Platzbedarf: 10 m x 10 m\ntechnische Daten: 230 V",
                "netto": "1.900,00 €",
                "brutto": "2.261,00 €"
            },
            {
                "titel": "Niedrigseilgarten",
                "bild": "",
                "beschreibung": "Unser mobiler Klettergarten lässt das Herz eines jeden Kleinen mit einer Körpergröße von ca. 1 m bis ca. 1,60 m höher schlagen. In luftiger Höhe hangeln sich die kleinen Freibeuter, abgesichert und von motivierten Betreuern, von Hindernis zu Hindernis.\n\n",
                "platz_and_tech": "Platzbedarf: 15 m x 6 m                                                                                             technische Daten: 230 V",
                "netto": "Preis auf Anfrage.",
                "brutto": ""
            },
            {
                "titel": "Piratennetz",
                "bild": "",
                "beschreibung": "Bestehend aus drei Netzen. Jedes Netz ist 3 m x 5 m. Die Netze werden miteinander verbunden, somit ist das Klettern in 5 m,   10 m und 15 m Höhe möglich.  Je nach Höhe ist ein Kran nötig.\nInkl. Klettergurten und Seilen.\n                                                                                         ",
                "platz_and_tech": "Platzbedarf: ca. 6 m x 6 m\ntechnische Daten: keine      ",
                "netto": "Preis auf Anfrage.",
                "brutto": ""
            },
            {
                "titel": "Alle Bruttopreise enthalten die gesetzliche Mehrwertsteuer. Die oben erwähnten Geräte können nur durch die Personal der S.O.F.A. GmbH  benutzt werden. Die Preise beziehen sich auf eine Einsatzdauer von sechs Stunden pro Tag + Auf - und Abbau und zuzüglich Betreuung. Die Angaben sind ohne Gewähr. Änderungen sind vorbehalten. Die Artikel von den Bildern können  abweichen.",
                "bild": "",
                "beschreibung": "%footer%",
                "platz_and_tech": "",
                "netto": "",
                "brutto": ""
            }
        ],
    },
    {
        url: "/prices/huepfburgen",
        items: [
            {
                "titel": "Big Circus",
                "bild": "",
                "beschreibung": "Die Zirkushüpfburg mit den aufgemalten Clowns und dem Dach bietet Platz für mehr als 20 Kinder. Selbst bei Regen können die Kinder im trockenen hüpfen.",
                "platz_and_tech": "Platzbedarf: ca. 6 m x 7 m\ntechnische Daten: 230 V / 16 A",
                "netto": "200,00 €",
                "brutto": "238,00 €"
            },
            {
                "titel": "Erlebniswelt",
                "bild": "",
                "beschreibung": "Die Erlebniswelt ist ein Hindernissparcour in dem die Kinder eine Wand mit Hilfe von Haltegriffen erklettern und wieder herunterrutschen. Man muss sich durch viele aufblasbare Säulen hindurch winden und durch Gitter und Tunnel klettern können.         \n",
                "platz_and_tech": "Platzbedarf: ca. 6m x 25 m\ntechnische Daten: 230 V / 16 A",
                "netto": "650,00 €",
                "brutto": "773,50 €"
            },
            {
                "titel": "Fressender Fisch",
                "bild": "",
                "beschreibung": "Die Kinder klettern in das Maul des Fisches. Dieser schließt seinen Mund von Zeit zu Zeit und verschluckt die Teilnehmer. Die Kinder können nun über eine Rutsche zum Schwanz des Fisches gelangen und von dort wieder zum Start gehen.                                   ",
                "platz_and_tech": "Platzbedarf: 8 m x 5 m x 5 m                                                                      technische Daten: 230 V / 16 A",
                "netto": "650,00 €",
                "brutto": "773,50 €"
            },
            {
                "titel": "Fressender Hund",
                "bild": "",
                "beschreibung": "Das Maul des Hundes kann sich öffnen und schließen. Ist es offen, können max. vier Kinder in den Hund klettern. Ist das Maul geschlossen, können die Kinder im Hund herunterrutschen und kommen auf der anderen Seite wieder heraus.\n",
                "platz_and_tech": "Platzbedarf: 10 m x 10 m\ntechnische Daten: 230 V / 16 A",
                "netto": "650,00 €",
                "brutto": "773,50 €"
            },
            {
                "titel": "Leuchtturm",
                "bild": "",
                "beschreibung": " In dem Turm sind Haltegriffe eingenäht, die es den Kindern ermöglichen den Turm hoch zu klettern. Durch den weichen Untergrund und die hohen Wände steht dem Kletterspaß nichts im Weg!\n",
                "platz_and_tech": "Platzbedarf: ca. 5 m x 5 m\ntechnische Daten: 230 V / 16 A",
                "netto": "300,00 €",
                "brutto": "357,00 €"
            },
            {
                "titel": "Lila Launeburg",
                "bild": "",
                "beschreibung": "Mit unserer bunten Lila Launeburg ist gute Laune vorprogrammiert. Mit ihren vielen leuchten farben ist sie sehr auffällig und durch das Dach kann man auch im Regen trocken hüpfen.",
                "platz_and_tech": "Platzbedarf: ca. 5 m x 5 m\ntechnische Daten: 230 V / 16 A",
                "netto": "200,00 €",
                "brutto": "238,00 €"
            },
            {
                "titel": "Piratenschiff",
                "bild": "",
                "beschreibung": "Die Hüpfburg in Form eines Piratenschiffes lädt zum Rutschen, Klettern und Toben ein. In dem Schiff befinden sich mehrere aufblasbare Piraten, mit denen gekämpft werden kann. \n",
                "platz_and_tech": "Platzbedarf: 13,5m x 4 m x 6,5 m\ntechnische Daten: 230 V / 16 A",
                "netto": "650,00 €",
                "brutto": "773,50 €"
            },
            {
                "titel": "Villa Kunterbunt",
                "bild": "",
                "beschreibung": "Kunterbunt und mit kleinen integrierten Spielemodulen kommt unsere \"Villa\" Hüpfburg daher und bietet fantastischen Hüpfspaß für bis zu zehn Kinder zeitgleich.\n",
                "platz_and_tech": " Platzbedarf: 5 m x 6 m\ntechnische Daten: 230 V / 16 A",
                "netto": "200,00 €",
                "brutto": "238,00 €"
            },
            {
                "titel": "Winterbahn",
                "bild": "",
                "beschreibung": "Unsere Megabahn ist ein winterlicher Riesenspaß für Groß und Klein. Sie bietet verschiedene Kletter- und Hüpfflächen auf denen sich Jedermann austoben kann. ",
                "platz_and_tech": "Platzbdarf: 27 m x 4 m                                                                                      technische Daten: 230 V",
                "netto": "2.370,00 €",
                "brutto": "2.820,30 €"
            },
            {
                "titel": "Alle Bruttopreise enthalten die gesetzliche Mehrwertsteuer. Die oben erwähnten Geräte können nur durch die Personal der S.O.F.A. GmbH  benutzt werden. Die Preise beziehen sich auf eine Einsatzdauer von sechs Stunden pro Tag + Auf - und Abbau und zuzüglich Betreuung. Die Angaben sind ohne Gewähr. Änderungen sind vorbehalten. Die Artikel von den Bildern können  abweichen.",
                "bild": "",
                "beschreibung": "%footer%",
                "platz_and_tech": "",
                "netto": "",
                "brutto": ""
            }
        ],
    },
    {
        url: "/prices/aufblasbar",
        items: [
            {
                "titel": "Aqua Jump Trampolin",
                "bild": "",
                "beschreibung": "Das Aqua Jump-Trampolin ist ein Trampolin, das durch den aufblasbaren Rand im Wasser schwimmen kann. Kinder können von da aus ins Wasser springen oder sich einfach für einen Moment auf der Auflagefläche ausruhen.\n                                                                                                    ",
                "platz_and_tech": "Platzbedarf: ca. Ø 5 m\ntechnische Daten: 230 V / 16 A",
                "netto": "460,00 €",
                "brutto": "547,40 €"
            },
            {
                "titel": "Basketball Duell",
                "bild": "",
                "beschreibung": "Die beliebte Jahrmarktattraktion für jede Zeit im Jahr. Es kann gegeneinander oder einzeln gespielt werden.  Der Spieler, der die meisten Bälle versenkt, hat gewonnen. Nach dem Wurf rollt der Ball wieder zum Spieler zurück. Inklusive vier Basketbälle.\n",
                "platz_and_tech": "Platzbedarf: ca. 5,5 m x 4 m\ntechnische Daten: 230 V / 16 A",
                "netto": "650,00 €",
                "brutto": "535,50 €"
            },
            {
                "titel": "Bubble Fußball",
                "bild": "",
                "beschreibung": "Fußball mal anders. Schlüpfen Sie in unsere aufblasbaren \"Bubble Bälle\" und zeigen Sie ihren Gegnern wer mehr Schwung hat. Im Mietpreis sind sechs \"Bubble Bälle\" inkl. Umrandung enthalten.\n",
                "platz_and_tech": "Platzbedarf: 10 m x 5 m\ntechnische Daten: 230 V / 16 A",
                "netto": "850,00 €",
                "brutto": "1011,50 €"
            },
            {
                "titel": "Bungee Run",
                "bild": "",
                "beschreibung": "Erleben Sie den klassischen Wettlauf einmal anders mit dem Bungee Run. Jeder Spieler versucht, an einem Bungeeseil befestigt, den Klettschwamm soweit wie möglich nach vorne zu legen. Es gibt verschiedene Schwierigkeitsstufen.\n",
                "platz_and_tech": "Platzbedarf: ca. 12 m x 5 m\ntechnische Daten: 230 V / 16 A",
                "netto": "300,00 €",
                "brutto": "357,00 €"
            },
            {
                "titel": "Bungee Soccer",
                "bild": "",
                "beschreibung": "Fußball spielen, während man an einem Bungeeseil festgeschnallt ist. Das gibt den besonderen Nervenkitzel und extra Spaß bei jedem Spiel.\n",
                "platz_and_tech": "Platzbedarf: 10 m x 6 m x 5 m                                                              technische Daten: 230 V / 16 A",
                "netto": "990,00 €",
                "brutto": "1.178,10 €"
            },
            {
                "titel": "Fußball Dart",
                "bild": "",
                "beschreibung": "Unser Fußballdart verspricht jede Menge Spaß und Action für Groß und Klein. Mit einem Klettball wird abwechselnd auf die aufblasbare XXL-Dartfläche geschossen. Wer am meisten Punkte erzielt, gewinnt den Durchgang.\n",
                "platz_and_tech": "Platzbedarf: 5 m x 5 m                                                                                  technische Daten: 230 V / 16 A",
                "netto": "300,00 €",
                "brutto": "357,00 €"
            },
            {
                "titel": "Fußball Rodeo",
                "bild": "",
                "beschreibung": "Rodeo reiten einmal anders. Bezwingen Sie den bockenden und drehenden Fußball. Ein Riesenspaß, nicht nur für Fußballfans. Wie auch unsere anderen Rodeos verfügt das Gerät über unterschiedliche Schwierigkeitsstufen.\n",
                "platz_and_tech": "Platzbedarf: 5 m x 5 m\ntechnische Daten: 230 V / 16 A",
                "netto": "500,00 €",
                "brutto": "595,00 €"
            },
            {
                "titel": "Gladiatorenkampf",
                "bild": "",
                "beschreibung": "Erleben Sie einen ganz besonderen Kampf der Gladiatoren. Die Spieler bekommen gepolsterte Stäbe und müssen versuchen sich mit diesen gegenseitig von den Podesten zu stoßen. Die Spieler tragen einen Helm und fallen auf ein Luftpolster.\n",
                "platz_and_tech": "Platzbedarf: 5 m x 5 m                                                                                  technische Daten: 230 V / 16 A",
                "netto": "500,00 €",
                "brutto": "595,00 €"
            },
            {
                "titel": "Golf Geschwindigkeitsmesser",
                "bild": "",
                "beschreibung": "Bei diesem Geschwindigkeitsspiel wird ermittelt, wer den Golfball am schnellsten schlagen kann. Durch Technik und Kraft werden hier ungeahnte Geschwindigkeiten gemessen.  ",
                "platz_and_tech": "Platzbedarf: ca. 3 m x 3 m\ntechnische Daten: 230 V / 16 A",
                "netto": "300,00 €",
                "brutto": "357,00 €"
            },
            {
                "titel": "Human Table Soccer",
                "bild": "",
                "beschreibung": "In dem aufblasbaren Spielfeld ist für zehn Spieler Platz. Die Mannschaften versuchen den Ball ins gegnerische Tor zu schießen. Die Spieler können sich zu den Seiten hin bewegen, aber müssen in ihrer Polsterung bleiben.                                    ",
                "platz_and_tech": " Platzbedarf: ca. 17 m x 7 m\ntechnische Daten: 230 V / 16 A",
                "netto": "350,00 €",
                "brutto": "416,50 €"
            },
            {
                "titel": "Interaktives Playsystem",
                "bild": "",
                "beschreibung": "Unser neues IPS Modul ist eine interaktive Zielwand. Es werden einzelne Ziele geschaltet, die es dann via gekonntem Wurf zu treffen gilt. Ein automatisches Zählsystem addiert im Nachhinein die Punkte und gibt den Gewinner bekannt.\n",
                "platz_and_tech": "Platzbedarf: 5 m x 10 m\ntechnische Daten: 230 V / 16 A",
                "netto": "550,00 €",
                "brutto": "654,50 €"
            },
            {
                "titel": "Luftgebläsekicker",
                "bild": "",
                "beschreibung": "Gespielt wird mit vier Spielern. Ziel ist es die meisten Tore zu schießen. Das besondere an diesem Spiel ist, dass man den Ball nicht durch Spielfiguren, sondern durch Lufttüllen ins gegnerische Tor gefördert muss.\n",
                "platz_and_tech": "Platzbedarf: ca. 4 m x 2 m\ntechnische Daten: 230 V / 16 A",
                "netto": "400,00 €",
                "brutto": "476,00 €"
            },
            {
                "titel": "Pool mit Wasserzorbs",
                "bild": "",
                "beschreibung": "Ein riesen Spaß für die Kleinen. In unseren aufblasbaren Wasserzorbs können sie übers Wasser laufen. Der Pool ist ca. 0,50 m hoch und somit auch komplett ungefährlich für die Zuschauer nebenan. Inkl. drei Waterzorbs.\n",
                "platz_and_tech": "Platzbedarf: 9 m x 9 m\ntechnische Daten: 230 V / 16 A",
                "netto": "900,00 €",
                "brutto": "1.071,00 €"
            },
            {
                "titel": "Rentier Rodeo",
                "bild": "",
                "beschreibung": "Hierbei wird das echte Rodeo reiten auf einem Rentier nachgeahmt. Es eignet sich besonders gut für Weihnachtsfeiern, kann aber auch ganzjährig eingesetzt werden.\n",
                "platz_and_tech": "Platzbedarf ca. 6,00 m x 7,00 m                                                                       technische Daten: 230 V / 16 A",
                "netto": "500,00 €",
                "brutto": "595,00 €"
            },
            {
                "titel": "Rodelbahn",
                "bild": "/img/preisliste/detailed/Rodelbahn2.0.jpg",
                "beschreibung": "Unsere aufblasbare Rodelbahn sorgt für Winterspaß an sonnigen Tagen. Durch schnelles herrunter rutschen ist ein riesen Spaß garantiert.",
                "platz_and_tech": "Platzbedarf: 15 m x 5,5 m                                                                                                  technische Daten: 230 V / 16 A",
                "netto": "1.515,00 €",
                "brutto": "1.802,85 €"
            },
            {
                "titel": "Rodeo Bulle",
                "bild": "",
                "beschreibung": "Fühlen Sie sich wie ein Cowboy / Cowgirl. Mit verschiedenen Geschwindigkeitsstufen ist der Bulle für erfahrene sowie unerfahrene Personen geeignet. Durch das aufblasbare Kissen kann dem Reiter bei seinem Sturz nichts passieren. \n",
                "platz_and_tech": "Platzbedarf:ca. 5m x 5m\ntechnische Daten: 230 V / 16 A",
                "netto": "500,00 €",
                "brutto": "595,00 €"
            },
            {
                "titel": "Shoot Out - Fußball",
                "bild": "",
                "beschreibung": "Beim Shoot Out können Sie die Geschwindigkeit Ihres Schusses messen. Das Shoot Out besteht aus einem aufblasbarem Tor, Fußbällen und einem Geschwindigkeitsmesser, der nach jedem Schuss die Geschwindigkeit anzeigt.\n",
                "platz_and_tech": "Platzbedarf: ca. 3 m x 5 m\ntechnische Daten: 230 V / 16 A",
                "netto": "300,00 €",
                "brutto": "357,00 €"
            },
            {
                "titel": "Snowboard Simulator",
                "bild": "",
                "beschreibung": "Beim Snowboardsimulator versucht der Spieler so lange wie möglich seine Position zu halten. Geschicklichkeit und die richtige Gewichtsverlagerung sind gefragt, denn die verschiedenen Geschwindigkeitsstufen habe es in sich.",
                "platz_and_tech": "Platzbedarf: 5 m x 5 m\ntechnische Daten: 230 V / 16 A",
                "netto": "500,00 €",
                "brutto": "595,00 €"
            },
            {
                "titel": "Surf Simulator",
                "bild": "",
                "beschreibung": "Einmal \"auf der Welle reiten\" ohne nass zu werden?\nBeim Surfsimulator steht der Spieler auf dem Surfbrett und versucht so lange wie möglich die Balance zu halten. Wenn der Spieler fällt, landet er auf einem weichen Kissen.\n",
                "platz_and_tech": "                                                                                                                   Platzbedarf: ca. 5 m x 5 m                                                                              technische Daten: 230 V / 16 A",
                "netto": "500,00 €",
                "brutto": "595,00 €"
            },
            {
                "titel": "Twister XXL",
                "bild": "",
                "beschreibung": "Das beliebte Verrenkungsspiel in Großformat für bis zu 15 Spieler. Ziel des Spiels ist es die gedrehten Befehle des Rads auszuführen, ohne dabei umzufallen.",
                "platz_and_tech": "Platzbedarf: 5 m x 5 m\ntechnische Daten: 230 V / 16 A",
                "netto": "485,00 €",
                "brutto": "577,15 €"
            },
            {
                "titel": "Wasserrutsche",
                "bild": "",
                "beschreibung": "Mit dieser Wasserrutsche ist Wasserspaß garantiert! Rutschen Sie superschnell von dieser Strecke und spritzen Sie ins Wasser.",
                "platz_and_tech": "Platzbedarf: 5 m x 6 m x 2 m",
                "netto": "600,00 €",
                "brutto": "714,00 €"
            },
            {
                "titel": "Wasserzorbs",
                "bild": "",
                "beschreibung": "Unsere Wasserbälle sowohl Indoor- als auch Outdoor zu bespielen. Sie werden mittels eines Gebläses mit Luft befüllt und sind dann von innen heraus zu begehen. So lässt es sich ungefährlich über das Wasser gleiten.                                                                                                                                                                                 ",
                "platz_and_tech": "technische Daten: 230 V / 16 A   ",
                "netto": "Preis auf Anfrage.",
                "brutto": ""
            },
            {
                "titel": "Alle Bruttopreise enthalten die gesetzliche Mehrwertsteuer. Die oben erwähnten Geräte können nur durch die Personal der S.O.F.A. GmbH  benutzt werden. Die Preise beziehen sich auf eine Einsatzdauer von sechs Stunden pro Tag + Auf - und Abbau und zuzüglich Betreuung. Die Angaben sind ohne Gewähr. Änderungen sind vorbehalten. Die Artikel von den Bildern können  abweichen.",
                "bild": "",
                "beschreibung": "%footer%",
                "platz_and_tech": "",
                "netto": "",
                "brutto": ""
            }
        ],
    },
    {
        url: "/prices/action_sport",
        items: [
            {
                "titel": "Air Hockey Tisch",
                "bild": "",
                "beschreibung": "Während der Puck über die polierte\nGleitfläche saust, zählt ein elektrischer Punktescanner während des Spieles die Erfolge der Kontrahenten.                                                                                                \n",
                "platz_and_tech": "Platzbedarf: 2,45 m x 1,25 m x 0,8 m\ntechnische Daten: 230 V / 16 A",
                "netto": "300,00 €",
                "brutto": "357,00 €"
            },
            {
                "titel": "Armdrücktisch",
                "bild": "",
                "beschreibung": "An dieser professionellen Armdrückmaschine kann ermittelt werden, wer der Stärkste ist. Das Besondere an der Armdrückmaschine ist, dass durch die vorgegebene Haltung und die Sicherheitsbügel das Schummeln nahezu unmöglich ist. \n",
                "platz_and_tech": "Platzbedarf: ca. 1 m x 1 m x 1,3 m\ntechnische Daten: keine",
                "netto": "330,00 €",
                "brutto": "392,70 €"
            },
            {
                "titel": "Astro Trainer",
                "bild": "",
                "beschreibung": "Der Astrotrainer ist zum Stehen und zum Sitzen erhältlich. Durch manuelle Steuerung ist es dem Personal möglich individuell die Geschwindigkeit anzupassen und somit den Spieler zu fordern. \n",
                "platz_and_tech": "Platzbedarf: 4 m x 4 m\ntechnische Daten: 230 V / 16 A",
                "netto": "330,00 €",
                "brutto": "392,70 €"
            },
            {
                "titel": "Biathlonanlage",
                "bild": "",
                "beschreibung": "Biathlon auch im Sommer. Wir bauen eine Bahn aus Kunststoff auf. Hier können dann die Personen eine Strecke laufen und am Ende der Strecke müssen sie mit einen Lasergewehr auf fünf Zielscheiben schießen.",
                "platz_and_tech": "Platzbedarf: ca. 15 m x 4 m\ntechnische Daten: 230 V",
                "netto": "Preis auf Anfrage.",
                "brutto": ""
            },
            {
                "titel": "Billiardtisch",
                "bild": "",
                "beschreibung": "Der beliebte Klassiker, geliefert und platziert an einem Ort Ihrer Wahl. Unser Billiardtisch kommt mit dem passenden Set Kugeln und ausreichend Queues für einen gelungenen Abend.",
                "platz_and_tech": "Platzbedarf: 3 m x 2 m\ntechnische Daten: keine",
                "netto": "400,00 €",
                "brutto": "476,00 €"
            },
            {
                "titel": "Bimmelbahn",
                "bild": "",
                "beschreibung": "Durch die StVZO Zulassung ist es möglich, diese Bahn mit vorheriger Genehmigung der Behörde, durch die ortsansässigen Straßen oder durch das Betriebsgelände fahren zu lassen. Sie bietet Platz für ca. 20 - 30 Personen (je nach Körpergröße).\n",
                "platz_and_tech": "Platzbedarf: 15 m x 3 m                      technische Daten: ein LKW 7,5 t Stellplatz wird benötigt",
                "netto": "1.880,00 €",
                "brutto": "2.237,20 €"
            },
            {
                "titel": "Bungee Jumper 4-fach",
                "bild": "",
                "beschreibung": "Der Bungee Jumper ermöglicht Trampolinspringen in ganz neuen Dimensionen. Die Person wird mit Hilfe eines Gurtes an Bungeeseilen gebunden und auf optimale Höhe gezogen. Durch die Seile kann man höher und sicherer springen.",
                "platz_and_tech": "Platzbedarf: ca. 10 m x 10 m x 5 m hoch\ntechnische Daten: 230 V / 16 A",
                "netto": "950,00 €",
                "brutto": "1.130,50 €"
            },
            {
                "titel": "Dartautomaten",
                "bild": "",
                "beschreibung": "Ziel des Spieles ist, zum Beispiel, bei den Varianten 301, 501 und 701 mit der angegebenen Höchstpunktzahl beginnend am schnellsten durch Treffen der entsprechenden Dartsegmente auf genau 0 Punkte herunterzuspielen.\n                                                                                          ",
                "platz_and_tech": "Platzbedarf: B 0,66 m x T 0,66 m x H 2,1 m\ntechnische Daten: 230 V / 16 A",
                "netto": "300,00 €",
                "brutto": "357,00 €"
            },
            {
                "titel": "Dschungel Rafting",
                "bild": "",
                "beschreibung": "Dank innovativem Design ist die Bahn wunderbar beweglich und innerhalb kürzester Zeit aufzubauen!\nInkl. 3 Speedtubes und Gleitmittel.                                                                    ",
                "platz_and_tech": "Platzbedarf: 25 m x 4 m x 6 m                                                                              technische Daten: keine",
                "netto": "950,00 €",
                "brutto": "1.130,50 €"
            },
            {
                "titel": "Eisstockschießen mit Ziel",
                "bild": "",
                "beschreibung": "Auf der 10 Meter langen Kunststoffbahn versuchen zwei Teams oder zwei Spieler, die Eisstöcke so nah wie möglich an die Daube zu schießen. Der Spieler oder das Team, dem dies gelingt, hat gewonnen. ",
                "platz_and_tech": "Platzbedarf: ca. 3 m x 14 m\ntechnische Daten: keine",
                "netto": "350,00 €",
                "brutto": "416,50 €"
            },
            {
                "titel": "Eisstockschießen ohne Ziel",
                "bild": "",
                "beschreibung": "Auf der 10 Meter langen Kunststoffbahn versuchen zwei Teams oder zwei Spieler die Eisstöcker so nah wie möglich an das Ende der Bahn zu schießen. Der Spieler oder das Team, dem dies gelingt, hat gewonnen. ",
                "platz_and_tech": "Platzbedarf: ca. 3 m x 14 m\ntechnische Daten: keine",
                "netto": "350,00 €",
                "brutto": "416,50 €"
            },
            {
                "titel": "Fahrradrennen",
                "bild": "",
                "beschreibung": "Zwei Spieler fahren um die Wette Fahrrad. Die Zuschauer können über eine Anzeigetafel hinter den Fahrern sehen wer vorn liegt und wer noch dringend aufholen muss.",
                "platz_and_tech": "Platzbedarf: 2,5 m x 2,5 m                                                                                technische Daten: 230 V / 16 A",
                "netto": "500,00 €",
                "brutto": "595,00 €"
            },
            {
                "titel": "Flipperautomat",
                "bild": "",
                "beschreibung": "Flipperautomat verschiedene Designs.\nKlassisch, kultig und ein echter Blickfang auf jeder Veranstaltung, welcher sowohl Erwachsene als auch Kinderaugen zum Strahlen bringt. \n",
                "platz_and_tech": "Platzbedarf: 2 m x 1,50 m\ntechnische Daten: 230 V / 16 A",
                "netto": "500,00 €",
                "brutto": "595,00 €"
            },
            {
                "titel": "Golfsimulator",
                "bild": "",
                "beschreibung": "Golfspielen einmal anders, bei dieser Simulationen müssen sie ein imaginäres Golfspiel meistern. Abschlag und Putten sind nur einige Hindernisse, die Sie meistern müssen. Ein sportiver Spaß, nicht nur für Golf Fans. Gerät ähnlich der Abbildung \n                                                                             ",
                "platz_and_tech": "Platzbedarf: 4 m x 3 m                                                                                                   technische Daten: 230 V / 16 A    ",
                "netto": "Preis auf Anfrage.",
                "brutto": ""
            },
            {
                "titel": "Hau den Lukas",
                "bild": "",
                "beschreibung": "Das Ziel des Spiels ist es den Lukas \"klingeln\" zu lassen, indem mit dem Hammer mit aller Kraft auf die hervorstehende Platte gehauen wird. Je nachdem wie kräftig auf die Platte geschlagen wurde, schlägt die Kugel nach oben aus. \n",
                "platz_and_tech": "Platzbedarf: ca. 5 m hoch\ntechnische Daten: keine",
                "netto": "300,00 €",
                "brutto": "357,00 €"
            },
            {
                "titel": "Hau den Lukas elektronisch",
                "bild": "",
                "beschreibung": "Das Ziel des Spiels ist es den Lukas \"klingeln\" zu lassen, indem mit dem Hammer mit aller Kraft auf die hervorstehende Platte gehauen wird. Je nachdem wie kräftig auf die Platte geschlagen wurde, desto höher schlägt der Punktestand aus.                                           ",
                "platz_and_tech": "Platzbedarf: 1,40 m x 0,90 m x H 2,30 m\ntechnische Daten: 230 V / 16 A",
                "netto": "660,00 €",
                "brutto": "785,40 €"
            },
            {
                "titel": "Kokosnussfangen",
                "bild": "",
                "beschreibung": "Das besondere Geschicklichkeitsspiel. Hier kommt es darauf an die Kokosnüsse aufzufangen, die in unterschiedlichen Abständen, ohne Reihenfolge herunterfallen. Als Alternative zu den Kokosnüsse gibt es unter anderem auch Stöcke und Pfeile.                                         \n",
                "platz_and_tech": "Platzbedarf: 2 m x 1 m\ntechnische Daten: 230 V / 16 A",
                "netto": "200,00 €",
                "brutto": "238,00 €"
            },
            {
                "titel": "Pfeilfangspiel",
                "bild": "",
                "beschreibung": "Das besondere Geschicklichkeitsspiel. Hier kommt es darauf an die Pfeile aufzufangen, die in unterschiedlichen Abständen, ohne Reihenfolge herunterfallen. Als Alternative zu den Pfeilen gibt es unter anderen auch Kokosnüsse und Stöcke\n",
                "platz_and_tech": "Platzbedarf: ca. 2 m x 1 m\ntechnische Daten: 230 V / 16 A",
                "netto": "200,00 €",
                "brutto": "238,00 €"
            },
            {
                "titel": "Pinguinrennbahn",
                "bild": "",
                "beschreibung": "Jeder Spieler hat vier Bälle und versucht in die Löcher mit besonders hoher Punktzahl zu treffen. Je mehr Punkte der Spieler hat, umso weiter kann sein Pinguin laufen.",
                "platz_and_tech": "Platzbedarf: 1,5 m x 3 m\ntechnische Daten: keine",
                "netto": "500,00 €",
                "brutto": "595,00 €"
            },
            {
                "titel": "Rennwagen Simulator",
                "bild": "",
                "beschreibung": "Der Rennsimulator hat eine 2-achsige Motion-Technologie mit Power Motion. Mit zwei Simulatoren kann zeitgleich gegeneinander gefahren werden.\nEr ist outdoorfähig (Voraussetzung: Überdachung durch Pavillon).\n",
                "platz_and_tech": "Platzbedarf: 2 m x 2 m\ntechnische Daten: 230 V / 16 A",
                "netto": "Preis auf Anfrage.",
                "brutto": ""
            },
            {
                "titel": "Rodelbahn",
                "bild": "",
                "beschreibung": "Mit unserer mobilen Rodelbahn hat Tubing das ganze Jahr Saison! Egal bei welcher Veranstaltung, diese Bahn steht immer im Mittelpunkt und wird begeistert angenommen. Dieses Modul ist ganzjährig nutzbar.",
                "platz_and_tech": "Platzbedarf: 24 m x 30 m x 6 m                                                                      technische Daten: keine          Bemerkung: ebener Boden, kein Gefälle, kein Ascheplatz",
                "netto": "950,00 €",
                "brutto": "1.130,50 €"
            },
            {
                "titel": "Schneeball Fangen",
                "bild": "",
                "beschreibung": "Das besondere Geschicklichkeitsspiel. Hier kommt es darauf an die Schneebälle aufzufangen, die in unterschiedlichen Zeitabständen und ohne Reihenfolge herunterfallen. Der Spieler, der die meisten Schneebälle fängt, hat gewonnen.",
                "platz_and_tech": "Platzbedarf: 2 m x 0,5 m\ntechnische Daten: 230 V / 16 A",
                "netto": "200,00 €",
                "brutto": "238,00 €"
            },
            {
                "titel": "Schneemann Wrestling",
                "bild": "",
                "beschreibung": "Fühlen Sie sich wie ein Wrestler im Schneemannkostüm und versuchen Sie Ihren Gegner auf die Matte zu legen.\nDurch die stark gepolsterten Anzüge erleben Sie einen ganz besonderen Spaß mit eingeschränkter Bewegungsfreiheit. ",
                "platz_and_tech": "Platzbedarf: ca. 5 m x 5 m\ntechnische Daten: keine",
                "netto": "495,00 €",
                "brutto": "589,05 €"
            },
            {
                "titel": "Stockfangspiel",
                "bild": "",
                "beschreibung": "Das besondere Geschicklichkeitsspiel. Hier kommt es darauf an die Stöcke aufzufangen, die in unterschiedlicher Abständen, ohne Reihenfolge herunterfallen. Als Alternative zu den Stöcken gibt es auch Kokosnüsse und Pfeile.",
                "platz_and_tech": "Platzbedarf: 2 m x 2 m                                                                                  technische Daten: 230 V / 16 A",
                "netto": "200,00 €",
                "brutto": "238,00 €"
            },
            {
                "titel": "Sumo Wrestling",
                "bild": "",
                "beschreibung": "Fühlen Sie sich wie ein asiatischer Sumo Kämpfer und versuchen Sie Ihren Gegner auf die Matte zu legen. Die Anzüge sind in verschiedenen Größen erhältlich. Im Mietpreis enthalten:       eine Wettkampfmatte und mehrere Anzüge\n                                                                                                                    ",
                "platz_and_tech": "Platzbedarf: ca. 5 m x 5 m\ntechnische Daten: keine",
                "netto": "495,00 €",
                "brutto": "589,05 €"
            },
            {
                "titel": "Tischkicker XXL",
                "bild": "",
                "beschreibung": "Dieser Tischkicker eignet sich hervorragend für Gruppen. Für vier bis sechs Spieler ist der Tisch eine besondere Herausforderung, denn je weniger Spieler am Tisch spielen, desto mehr muss sich bewegt werden.\n",
                "platz_and_tech": "Platzbedarf: ca. 3 m x 0,75 m\ntechnische Daten: keine",
                "netto": "300,00 €",
                "brutto": "357,00 €"
            },
            {
                "titel": "Twall",
                "bild": "",
                "beschreibung": "Die Twall ist eine interaktive Spielewand, die Lichtimpulse aussendet, die durch Berührung wieder ausgeschaltet werden. Somit können je nach Trainingsprogramm Kondition, Beweglichkeit und Reaktionsfähigkeit trainiert werden.\n",
                "platz_and_tech": "Platzbedarf: 2,5 m x 1,2 m x 2,2 m\ntechnische Daten: 230 V                          Bemerkung: Überdachung ist zwingend erforderlich ",
                "netto": "1.200,00 €",
                "brutto": "1.428,00 €"
            },
            {
                "titel": " Alle Bruttopreise enthalten die gesetzliche Mehrwertsteuer. Die oben erwähnten Geräte können nur durch die Personal der S.O.F.A. GmbH  benutzt werden. Die Preise beziehen sich auf eine Einsatzdauer von sechs Stunden pro Tag + Auf - und Abbau und zuzüglich Betreuung. Die Angaben sind ohne Gewähr. Änderungen sind vorbehalten. Die Artikel von den Bildern können  abweichen.",
                "bild": "",
                "beschreibung": "%footer%",
                "platz_and_tech": "",
                "netto": "",
                "brutto": ""
            }
        ],
    },
    {
        url: "/prices/schlittschuhbahn",
        doNotSort: true,
        items: [
            {
                "titel": "100 m² Schlittschuhbahn\n\nSchlittschuhplatten aus Kunststoff\nBande aus Holz\nSchlittschuhe in den gängigsten Größen für Damen, Herren und Kinder\nSchleifmaschine\nAuf- und Abbauhelfer\nImprägnier Flüssigkeit",
                "bild": "",
                "beschreibung": "%footer%",
                "netto": "",
                "brutto": ""
            },
            {
                "titel": "1 - 3 Tage *",
                "bild": "/img/preisliste/detailed/100 m² 1-3 Tage.jpg",
                "beschreibung": "",
                "netto": "2.418,57 €",
                "brutto": "2.878,10 €"
            },
            {
                "titel": "4 - 7 Tage *",
                "bild": "/img/preisliste/detailed/100 m² 4-7 Tage.jpg",
                "beschreibung": "",
                "netto": "5.266,80 €",
                "brutto": "6.267,49 €"
            },
            {
                "titel": "2 Wochen",
                "bild": "/img/preisliste/detailed/100 m² 2 Wochen.jpg",
                "beschreibung": "",
                "netto": "8.653,26 €",
                "brutto": "10.297,38 €"
            },
            {
                "titel": "3 Wochen",
                "bild": "/img/preisliste/detailed/100 m² 3 Wochen.jpg",
                "beschreibung": "",
                "netto": "11.628,54 €",
                "brutto": "13.837,96 €"
            },
            {
                "titel": "4 Wochen",
                "bild": "/img/preisliste/detailed/100 m² 4 Wochen.jpg",
                "beschreibung": "",
                "netto": "14.603,82 €",
                "brutto": "17.378,55 €"
            },
            {
                "titel": "150 m² Schliitschuhbahn\n\nSchlittschuhplatten aus Kunststoff\nBande aus Holz\nSchlittschuhe in den gängigsten Größen für Damen, Herren und Kinder\nSchleifmaschine\nAuf- und Abbauhelfer\nImprägnier Flüssigkeit",
                "bild": "",
                "beschreibung": "%footer%",
                "netto": "",
                "brutto": ""
            },
            {
                "titel": "1 - 3 Tage *",
                "bild": "/img/preisliste/detailed/150 m² 1-3 Tage.jpg",
                "beschreibung": "",
                "netto": "3.423,42 €",
                "brutto": "4.073,87 €"
            },
            {
                "titel": "4 - 7 Tage *",
                "bild": "/img/preisliste/detailed/150 m² 4-7 Tage.jpg",
                "beschreibung": "",
                "netto": "7.318,08 €",
                "brutto": "8.708,52 €"
            },
            {
                "titel": "2 Wochen",
                "bild": "/img/preisliste/detailed/150 m² 2 Wochen.jpg",
                "beschreibung": "",
                "netto": "11.910,36 €",
                "brutto": "14.173,33 €"
            },
            {
                "titel": "3 Wochen",
                "bild": "/img/preisliste/detailed/150 m² 3 Wochen.jpg",
                "beschreibung": "",
                "netto": "15.492,02 €",
                "brutto": "18.435,50 €"
            },
            {
                "titel": "4 Wochen",
                "bild": "/img/preisliste/detailed/150 m² 4 Wochen.jpg",
                "beschreibung": "",
                "netto": "20.286,42 €",
                "brutto": "24.140,84 €"
            },
            {
                "titel": "200 m² Schliitschuhbahn\n\nSchlittschuhplatten aus Kunststoff\nBande aus Holz\nSchlittschuhe in den gängigsten Größen für Damen, Herren und Kinder\nSchleifmaschine\nAuf- und Abbauhelfer\nImprägnier Flüssigkeit",
                "bild": "",
                "beschreibung": "%footer%",
                "netto": "",
                "brutto": ""
            },
            {
                "titel": "1 - 3 Tage *",
                "bild": "/img/preisliste/detailed/200 m² 1-3 Tage.jpg",
                "beschreibung": "",
                "netto": "4.081,77 €",
                "brutto": "4.857,31 €"
            },
            {
                "titel": "4 - 7 Tage *",
                "bild": "/img/preisliste/detailed/200 m² 4-7 Tage.jpg",
                "beschreibung": "",
                "netto": "9.015,93 €",
                "brutto": "10.728,96 €"
            },
            {
                "titel": "2 Wochen",
                "bild": "/img/preisliste/detailed/200 m² 2 Wochen.jpg",
                "beschreibung": "",
                "netto": "13.088,46 €",
                "brutto": "15.575,27 €"
            },
            {
                "titel": "3 Wochen",
                "bild": "/img/preisliste/detailed/200 m² 3 Wochen.jpg",
                "beschreibung": "",
                "netto": "20.713,77 €",
                "brutto": "24.649,39 €"
            },
            {
                "titel": "4 Wochen",
                "bild": "/img/preisliste/detailed/200 m² 4 Wochen.jpg",
                "beschreibung": "",
                "netto": "25.969,02 €",
                "brutto": "30.903,13 €"
            },
            {
                "titel": "Super-Sonder-Angebot 300 m² Schlittschuhbahn von Januar - März\n\nSchlittschuhplatten aus Kunststoff\nSchlittschuhe in den gängigsten Größen für Damen, Herren und Kinder, Bande aus Holz\nSchleifmaschine, Imprägnier Flüssigkeit\nAuf- und Abbauhelfer",
                "bild": "",
                "beschreibung": "%footer%",
                "netto": "",
                "brutto": ""
            },
            {
                "titel": "4 Wochen",
                "bild": "/img/preisliste/detailed/300 m² 4 Wochen.jpg",
                "beschreibung": "",
                "netto": "20.900,00 €",
                "brutto": "24.871,00 €"
            },
            {
                "titel": "2 Monate",
                "bild": "/img/preisliste/detailed/300 m² 2 Monate.jpg",
                "beschreibung": "",
                "netto": "31.350,00 €",
                "brutto": "37.306,50 €"
            },
            {
                "titel": "3 Monate",
                "bild": "/img/preisliste/detailed/300 m² 3 Monate.jpg",
                "beschreibung": "",
                "netto": "41.800,00 €",
                "brutto": "49.742,00 €"
            },
            {
                "titel": "Super-Sonder-Angebot 400 m² Schlittschuhbahn von Januar - März\n\nSchlittschuhplatten aus Kunststoff\nSchlittschuhe in den gängigsten Größen für Damen, Herren und Kinder, Bande aus Holz\nSchleifmaschine, Imprägnier Flüssigkeit\nAuf- und Abbauhelfer",
                "bild": "",
                "beschreibung": "%footer%",
                "netto": "",
                "brutto": ""
            },
            {
                "titel": "4 Wochen",
                "bild": "/img/preisliste/detailed/400 m² 4 Wochen.jpg",
                "beschreibung": "",
                "netto": "27.500,00 €",
                "brutto": "32.725,00 €"
            },
            {
                "titel": "2 Monate",
                "bild": "/img/preisliste/detailed/400 m² 2 Monate.jpg",
                "beschreibung": "",
                "netto": "41.800,00 €",
                "brutto": "49.742,00 €"
            },
            {
                "titel": "3 Monate",
                "bild": "/img/preisliste/detailed/400 m² 3 Monate.jpg",
                "beschreibung": "",
                "netto": "54.450,00 €",
                "brutto": "64.795,50 €"
            },
            {
                "titel": "Rodelbahn\n\nPlatzbedarf: 24 m x 6 m x 3,5 m (l x b x h)\ntechnische Daten: keine\nBemerkung: ebener Boden, kein Gefälle, kein Ascheplatz",
                "bild": "",
                "beschreibung": "%footer%",
                "netto": "",
                "brutto": ""
            },
            {
                "titel": "Tagespreis",
                "bild": "/img/preisliste/detailed/Rodelbahn Tagespreis.jpg",
                "beschreibung": "",
                "netto": "1.045,00 €",
                "brutto": "1.130,50 €"
            },
            {
                "titel": "2 Tage",
                "bild": "/img/preisliste/detailed/Rodelbahn 2 Tage.jpg",
                "beschreibung": "",
                "netto": "1.567,50 €",
                "brutto": "1.695,75 €"
            },
            {
                "titel": "1 Woche",
                "bild": "/img/preisliste/detailed/Rodelbahn 1 Woche.jpg",
                "beschreibung": "",
                "netto": "367,50 €",
                "brutto": "3.956,75 €"
            },
            {
                "titel": "1 Monat",
                "bild": "/img/preisliste/detailed/Rodelbahn 1 Monate.jpg",
                "beschreibung": "",
                "netto": "9.405,00 €",
                "brutto": "10.174,50 €"
            },
            {
                "titel": "2 Monate",
                "bild": "/img/preisliste/detailed/Rodelbahn 2 Monate.jpg",
                "beschreibung": "",
                "netto": "14.107,50 €",
                "brutto": "15.261,75 €"
            },
            {
                "titel": "* Die Preise sind Pauschalangebote und somit unabhängig von den genauen Buchungstagen. Der Preis ist der Gleiche, egal ob die Schlittschuhbahn ein oder drei Tage aufgebaut wird. Das gleiche Prinzip gilt beim Buchen von vier bis sieben Tagen.\n" +
                    "Die Preise sind Pauschalangebote und exklusive Betreuungspersonal. \n" +
                    "Zusatzmodule: Lichtanlage, Musikanlage, Laufhilfen, Ausgabe Hütte oder Pagodenzeit, Regale, Tisch und Bänke, Zeltboden, Teppichboden und vieles mehr.",
                "bild": "",
                "beschreibung": "%footer%",
                "netto": "",
                "brutto": ""
            }
        ],
    },
    {
        url: "/prices/catering",
        items: [
            {
                "titel": "Belegte Brötchen",
                "bild": "",
                "beschreibung": "80 belegte Brötchen auf Servierplatten mit verschiedenen Belägen wie Käse, Salami, Schinken, Hummus, Ei, Mett, Frischkäse und vielem mehr. Der Belag kann natürlich per Absprache angepasst werden.",
                "platz_and_tech": "Platzbedarf: ca. 2 m x 2 m                       technische Daten: keine",
                "netto": "200,00 €",
                "brutto": "238,00 €"
            },
            {
                "titel": "Crepe Stand",
                "bild": "",
                "beschreibung": "Leckere Crêpes mit verschiedenen Toppings. Zum Beispiel: Nutella, Apfelmus, Zimt und Zucker, Eierlikör, Käse, Wurst und vielem mehr.",
                "platz_and_tech": "Platzbedarf: ca. 3 m x 3 m                       technische Daten: 230 V / 16 A",
                "netto": "Preis auf Anfrage.",
                "brutto": ""
            },
            {
                "titel": "Food Truck",
                "bild": "",
                "beschreibung": "Egal ob frische Burger, leckere Pommes, selbstgemachte Pizza oder würzige Asia Nudeln, wir finden den richtigen Food Truck für Ihre Veranstaltung.",
                "platz_and_tech": "Platzbedarf: ca. 7 m x 5 m\ntechnische Daten: 230 V / 16 A",
                "netto": "Preis auf Anfrage.",
                "brutto": ""
            },
            {
                "titel": "Gebrannte Mandeln",
                "bild": "",
                "beschreibung": "Knusprig, süße Mandeln inkl. Tüten und Wärmebehältnis für 60 Personen / je 100 g.",
                "platz_and_tech": "Platzbedarf: ca. 1 m x 1 m                       technische Daten: 230 V / 16 A",
                "netto": "260,00 €",
                "brutto": "309,40 €"
            },
            {
                "titel": "Getränkewagen",
                "bild": "",
                "beschreibung": "Getränkwagen mit Kühlung, Spüle und Personal.                            Inkl. Kaffe, Cola, Fanta, Sprite, Mezzo, Apfelsaft, Wasser und Bier vom Fass.",
                "platz_and_tech": "Platzbedarf: ca. 7 m x 5 m\ntechnische Daten: 230 V / 16 A",
                "netto": "Preis auf Anfrage.",
                "brutto": ""
            },
            {
                "titel": "Grillbuffet",
                "bild": "",
                "beschreibung": "Essenspauschale für ein Grillbuffet inkl.:                                            - Bratwurst, Nackensteak, Geflügelsteak                                                                                                               - Salatbeilagen                                                                                                  Sowie: Teller, Besteck und Gläser    ",
                "platz_and_tech": "Platzbedarf: ca. 4 m x 2 m\ntechnische Daten: keine",
                "netto": "Preis auf Anfrage.",
                "brutto": ""
            },
            {
                "titel": "Oktoberfest Buffet",
                "bild": "",
                "beschreibung": " - Kalter Braten / Pfefferbeißern / Kaminwurzen\n- Mini-Partyleberkäse, pikante Fleischbällchen, Obatzda\n-  frische Laugenbrezen, hausgemachte Kartoffelknödel\n- Jungschweinbraten mit Weißbierjus, Mini Schnitzel\n- Kartoffel-Rucola Salat, Bayerischer Krautsalat\n- Bayerische Creme mit Beeren, Apfelstrudel mit Vanillesoße",
                "platz_and_tech": "Platzbedarf: ca. 4 m x 2 m\ntechnische Daten: keine",
                "netto": "Preis auf Anfrage.",
                "brutto": ""
            },
            {
                "titel": "Popcornmaschine",
                "bild": "",
                "beschreibung": "Bereiten Sie selbst frisches Popcorn zu mit der nostalgischen Popcornmaschine. Inklusive Mais für 50 Portionen, Popcornfett, Zucker und Tüten. ",
                "platz_and_tech": "Platzbedarf: ca. 1 m x 1 m\ntechnische Daten: 230 V / 16 A",
                "netto": "Preis auf Anfrage.",
                "brutto": ""
            },
            {
                "titel": "Slush Maschine",
                "bild": "",
                "beschreibung": "An heißen Tagen genau das Richtige. Eine leckere Abkühlung in vielen verschiedenen Geschmackssorten und Farben.\nZwei verschiedene Sorten (Mindestmenge 10 Liter).\n                                                                                                                                                    ",
                "platz_and_tech": "Platzbedarf: 1 m x 1 m \ntechnische Daten: 230 V / 16 A  ",
                "netto": "350,00 €",
                "brutto": "416,50 €"
            },
            {
                "titel": "Zuckerwattenmaschine",
                "bild": "",
                "beschreibung": "Leckere Zuckerwatte, die jedes Kinderherz zum leuchten bringt.                                                                                                                      Inkl. Zutaten für 50 Portionen. Jede weitere Portion wird mit 0,30 € berechnet.",
                "platz_and_tech": "\"Platzbedarf: ca. 1,5 m x 3 m\ntechnische Daten: 230 V / 16 A",
                "netto": "295,00 €",
                "brutto": "351,05 €"
            },
            {
                "titel": " Alle Bruttopreise enthalten die gesetzliche Mehrwertsteuer. Die oben erwähnten Geräte können nur durch die Personal der S.O.F.A. GmbH  benutzt werden. Die Preise beziehen sich auf eine Einsatzdauer von sechs Stunden pro Tag + Auf - und Abbau und zuzüglich Betreuung. Die Angaben sind ohne Gewähr. Änderungen sind vorbehalten. Die Artikel von den Bildern können  abweichen.",
                "bild": "",
                "beschreibung": "%footer%",
                "platz_and_tech": "",
                "netto": "",
                "brutto": ""
            }
        ],
    },
    {
        url: "/prices/erwachsene-spiele",
        items: [
            {
                "titel": "Baumstamm sägen",
                "bild": "",
                "beschreibung": "Hier können Sie Ihre Holzfäller-Qualitäten unter Beweis stellen, egal ob im Team oder im Einzelkampf. Wer den Stamm als erstes zu Kleinholz verarbeitet hat, gewinnt.",
                "platz_and_tech": "Platzbedarf: 4 m x 4 m                                                                                   technische Daten: keine",
                "netto": "150,00 €",
                "brutto": "178,50 €"
            },
            {
                "titel": "Black Jack Tisch",
                "bild": "",
                "beschreibung": "Professioneller Black Jack Tisch aus Holz. Mit Chips, Spielkarten und Mischmaschine. Black Jack ist das beliebteste Kartenspiel bei Anfängern und Profis.\n",
                "platz_and_tech": "Platzbedarf: 1,65 m x 1,15 m x 1,2 m\ntechnische Daten: keine",
                "netto": "Preis auf Anfrage",
                "brutto": ""
            },
            {
                "titel": "Bogenschießen",
                "bild": "",
                "beschreibung": " Versuchen Sie mit Hilfe des Bogens den Pfeil in die Mitte der Zielscheibe zu schießen. Im Mietpreis sind ein Bogen, eine Zielscheibe und 12 Pfeile enthalten.",
                "platz_and_tech": "Platzbedarf: ca. 12 m x 10 m                                                                                                            technische Daten: keine",
                "netto": "365,00 €",
                "brutto": "434,35 €"
            },
            {
                "titel": "Boxball",
                "bild": "",
                "beschreibung": "Messen Sie die Stärke Ihres Schlages an unserem Boxautomat. Je stärker der Schlag, desto mehr Punkte werden auf dem Display angezeigt.\n \n",
                "platz_and_tech": "Platzbedarf: 2,5 m x 2,5 m\ntechnische Daten: 230 V / 16 A",
                "netto": "660,00 €",
                "brutto": "785,40 €"
            },
            {
                "titel": "Dart",
                "bild": "",
                "beschreibung": "\nZiel des Spieles ist, zum Beispiel, bei den Varianten 301, 501 und 701 mit der angegebenen Höchstpunktzahl beginnend am schnellsten durch Treffen der entsprechenden Dartsegmente auf genau 0 Punkte herunterzuspielen.",
                "platz_and_tech": "Platzbedarf: 4 m x 3 m                           technische Daten: keine",
                "netto": "50,00 €",
                "brutto": "59,50 €"
            },
            {
                "titel": "Golfspiel Putten",
                "bild": "",
                "beschreibung": "Der Spieler versucht bei diesem Geschicklichkeitsspiel den Golfball in dem Loch zu versenken. Der Spieler, der dafür die wenigsten Schläge benötigt, hat das Putten gewonnen.\n",
                "platz_and_tech": "Platzbedarf: 3 m x 3 m\ntechnische Daten: keine",
                "netto": "150,00 €",
                "brutto": "178,50 €"
            },
            {
                "titel": "Maßkrug schieben",
                "bild": "",
                "beschreibung": "Beim Maßkrug-Schieben wird das gefüllte Bierglas mit viel Gefühl über die Theke geschoben. Dabei gibt es am Ende der Theke verschiedene Ziele / Punkte, welche es zu treffen gilt. \n",
                "platz_and_tech": "Platzbedarf: ca. 6 m x 1 m\ntechnische Daten: keine",
                "netto": "355,00 €",
                "brutto": "422,45 €"
            },
            {
                "titel": "Maßkrugstemmen",
                "bild": "",
                "beschreibung": "Zwei Spieler versuchen so lange wie möglich den Maßkrug mit ausgestrecktem Arm oben zu halten. Wer als erstes den Arm sinken lässt, hat verloren. ",
                "platz_and_tech": "Platzbedarf: 4 m x 3 m                           technische Daten: keine",
                "netto": "370,00 €",
                "brutto": "440,30 €"
            },
            {
                "titel": "Nagelschlagen",
                "bild": "",
                "beschreibung": "Bei diesem Geschwindigkeitsspiel kommt es darauf an, so viele Nägel wie möglich in den Holzbalken zu schlagen. Hierbei kommt es auf die Zeit und die Tiefe der Nägel an. \n",
                "platz_and_tech": "Platzbedarf: ca. 3 m x 1 m\ntechnische Daten: keine ",
                "netto": "150,00 €",
                "brutto": "178,50 €"
            },
            {
                "titel": "Roulette Tisch",
                "bild": "",
                "beschreibung": "Roulette ist das beliebteste Glücksspiel bei Anfängern und auch bei Profis. Wir bieten Ihnen einen professionellen Roulette Tisch, in ansprechender Optik, inklusive Chips, Kugeln und Schieber\n ohne Croupier.",
                "platz_and_tech": "Platzbedarf: 2,5 m x 1,5 m\ntechnische Daten: keine",
                "netto": "Preis auf Anfrage",
                "brutto": ""
            },
            {
                "titel": "Segways",
                "bild": "",
                "beschreibung": "Der perfekte Fahrspaß für Erwachsene. Trainieren Sie ihr Gleichgewicht und durchfahren Sie den schwierigen Parcour mit unseren Segways. Inkl. vier Segways und Parcourmaterial (Wippe,      Verkehrshütchen ...)\n                                                                          ",
                "platz_and_tech": "Platzbedarf: mindestens 15 m x 8 m\ntechnische Daten: 230 V / 16 A (aufladen nachts nötig)",
                "netto": "1.250,00 €",
                "brutto": "1.487,50 €"
            },
            {
                "titel": "Alle Bruttopreise enthalten die gesetzliche Mehrwertsteuer. Die oben erwähnten Geräte können nur durch die Personal der S.O.F.A. GmbH  benutzt werden. Die Preise beziehen sich auf eine Einsatzdauer von sechs Stunden pro Tag + Auf - und Abbau und zuzüglich Betreuung. Die Angaben sind ohne Gewähr. Änderungen sind vorbehalten. Die Artikel von den Bildern können  abweichen.",
                "bild": "",
                "beschreibung": "%footer%",
                "platz_and_tech": "",
                "netto": "",
                "brutto": ""
            }
        ],
    },
    {
        url: "/prices/kinder-spiele",
        items: [
            {
                "titel": "Armbrustschießen",
                "bild": "",
                "beschreibung": "Im Mietpreis sind zwei Kinderarmbrüste, zwei Zielscheiben, 12 Pfeile ohne Spitzen und ein Marktstand enthalten.                                                                                      ",
                "platz_and_tech": "Platzbedarf: ca. 12 m x 5 m                                                                            technische Daten: keine",
                "netto": "200,00 €",
                "brutto": "238,00 €"
            },
            {
                "titel": "Autorennbahn",
                "bild": "",
                "beschreibung": "Im Mietpreis  sind die Bahn, mit Unterkonstruktion und vier Rennautos mit Fernbedienung enthalten.\n",
                "platz_and_tech": "Platzbedarf: 4 m x 6 m\ntechnische Daten: 230 V / 16 A",
                "netto": "700,00 €",
                "brutto": "833,00 €"
            },
            {
                "titel": "Bingo Maschine",
                "bild": "",
                "beschreibung": "Der Spielklassiker mit 600 Bingo Karten.  Wer eine Reihe (waagerecht oder diagonal) voll hat und als erster Bingo ruft, ist der Sieger. \n",
                "platz_and_tech": "Platzbedarf: 0,5 m x 0,5 m\ntechnische Daten: 230 V / 16 A",
                "netto": "125,00 €",
                "brutto": "148,75 €"
            },
            {
                "titel": "Bobby Cars",
                "bild": "",
                "beschreibung": "Die spezielle Form des Spielautos für Kleinkinder. Meist in rot, können die ganz kleinen durch die Gegend flitzen oder kleine Rennen fahren.\n",
                "platz_and_tech": "Platzbedarf: 3 m x 5 m                                                                   technische Daten: keine",
                "netto": "10,00 €",
                "brutto": "11,90 €"
            },
            {
                "titel": "Bogenschießen",
                "bild": "/img/preisliste/detailed/bogenschießen-kinder.jpg",
                "beschreibung": "Im Mietpreis sind zwei Kinderbögen, zwei Zielscheiben, sowie 12 Pfeile ohne Spitzen mit Saugnäpfen enthalten.                                                                                              ",
                "platz_and_tech": "Platzbedarf: ca. 12 m x 10 m                                                                                                           technische Daten: keine",
                "netto": "180,00 €",
                "brutto": "214,20 €"
            },
            {
                "titel": "Dosenwerfen",
                "bild": "",
                "beschreibung": "Eines der beliebtesten Werf- und Geschicklichkeitsspiele. Im Mietpreis  sind drei Dosenwerf-Sets (à 6 Dosen + 3 Bälle) und ein Marktstand enthalten.                \n",
                "platz_and_tech": "Platzbedarf: ca. 2 m x 1 m\ntechnische Daten: keine",
                "netto": "70,00 €",
                "brutto": "83,30 €"
            },
            {
                "titel": "Entenangeln",
                "bild": "",
                "beschreibung": "Viele gelbe Gummienten befinden sich in einem Becken, welche geangelt werden müssen. Dazu gibt es viele kleine Preise, die man gewinnen kann.\n",
                "platz_and_tech": "Platzbedarf: ca. 2 m x 2 m \ntechnische Daten: keine",
                "netto": "265,00 €",
                "brutto": "315,35 €"
            },
            {
                "titel": "Glücksrad",
                "bild": "",
                "beschreibung": " Das Glücksrad ist auf einem Metallständer befestigt. Gegen einen Aufpreis kann auch ein Glücksrad mit Firmenlogo oder in den Firmenfarben gefertigt werden.\n",
                "platz_and_tech": "Platzbedarf: ca. Ø 0,8m x H 2m\ntechnische Daten: keine",
                "netto": "70,00 €",
                "brutto": "83,30 €"
            },
            {
                "titel": "Go-Kart-Bahn mit Pylonen",
                "bild": "",
                "beschreibung": "Die sechs Kinder Tretkarts werden in drei verschiedenen Größen geliefert. Durch die Pylonen kann der Parcour individuell gestaltet und umgebaut werden. ",
                "platz_and_tech": "Platzbedarf: je nach Parcour\ntechnische Daten: keine",
                "netto": "300,00 €",
                "brutto": "357,00 €"
            },
            {
                "titel": "Go-Kart-Bahn mit Umrandung",
                "bild": "",
                "beschreibung": "Die sechs Kinder Tretkarts werden in drei verschiedenen Größen geliefert. Der aufblasbare Parcour bietet ein viel Sicherheit, da Zusammenstöße mit der Umrandung unbedenklich sind.\n",
                "platz_and_tech": "Platzbedarf: 18 m x 12 m\ntechnische Daten: 230 V / 16 A",
                "netto": "450,00 €",
                "brutto": "535,50 €"
            },
            {
                "titel": "Hau den Lukas",
                "bild": "/img/preisliste/detailed/Hau den Lukas Kinder.jpg",
                "beschreibung": "Die Kinder hauen mit einem leichten Holzhammer  auf die hervorstehenden Platte. Desto stärker gehauen wird, desto höher geht die Kugel nach oben und im besten Fall ertönt ein \"Gong\".                                                                                                 ",
                "platz_and_tech": "Platzbedarf: ca. 2 m hoch\ntechnische Daten: keine",
                "netto": "100,00 €",
                "brutto": "119,00 €"
            },
            {
                "titel": "Heißer Draht",
                "bild": "",
                "beschreibung": "Bei diesem Geschicklichkeitsspiel kommt es auf ein ruhiges Händchen und einen kühlen Kopf an. Es gilt einen Stab sicher durch das Drahtgewirr zu führen. \n",
                "platz_and_tech": "Platzbedarf: ca. 1,50 m x  0,40 m\ntechnische Daten: 230V / 16A",
                "netto": "70,00 €",
                "brutto": "83,30 €"
            },
            {
                "titel": "Holzschuhwurfspiel",
                "bild": "",
                "beschreibung": "Das besondere Geschicklichkeitsspiel, bei dem der gewinnt, der die meisten Bälle in die Holzschuhe wirft. Gutes Werfen und Zielen ist hier von Vorteil.\n",
                "platz_and_tech": "Platzbedarf: ca. Ø 1m\ntechnische Daten: Keine",
                "netto": "70,00 €",
                "brutto": "83,30 €"
            },
            {
                "titel": "Holzspiele",
                "bild": "",
                "beschreibung": "Drei Spieletafeln, an denen \"Mensch Ärger Dich Nicht\" und Käsekästchen gespielt werden kann, so wie eine Tafel bei der Geschicklichkeit trainiert wird.                                                                                           ",
                "platz_and_tech": "Platzbedarf: ca Ø 1 m\ntechnische Daten: keine",
                "netto": "150,00 €",
                "brutto": "178,50 €"
            },
            {
                "titel": "Hufeisenwerfen",
                "bild": "",
                "beschreibung": "Aus einer Entfernung von ca. 2 m muss der Spieler versuchen so viele Hufeisen wie möglich über die schmale senkrechte Stange zu werfen. \n",
                "platz_and_tech": "Platzbedarf: 2,5 m x 1 m\ntechnische Daten: keine",
                "netto": "100,00 €",
                "brutto": "119,00 €"
            },
            {
                "titel": "Hüpfbälle- und Tiere",
                "bild": "",
                "beschreibung": "Besonderer Hüpfspaß für die Kleinen. Mit verschiedenen Hüpfbällen können sie Koordination und Gleichgewicht spielerisch trainieren.",
                "platz_and_tech": "Platzbedarf: 3 m x 5 m                                                                   technische Daten: keine",
                "netto": "5,00 €",
                "brutto": "5,95 €"
            },
            {
                "titel": "Hut-Geweih-Wurf",
                "bild": "",
                "beschreibung": "Werfen Sie mit Hüten auf unseren Deko-Hirschen \"Hugo\".  Wer es schafft mehr Hüte in Hugos Geweih zu werfen, hat gewonnen. ",
                "platz_and_tech": "Platzbedarf: 5 m x 2 m                  technische Daten: keine",
                "netto": "450,00 €",
                "brutto": "535,50 €"
            },
            {
                "titel": "Karussell Tiere",
                "bild": "",
                "beschreibung": "Das Nostalgiekarussell mit fünf verschiedenen Tieren ist beleuchtet und spielt Musik, während es sich dreht.  Auf dem Karussell haben ca. sechs Kinder Platz.\n\n",
                "platz_and_tech": "Platzbedarf: 5 m x 5 m\ntechnische Daten: 230 V / 16 A",
                "netto": "500,00 €",
                "brutto": "595,00 €"
            },
            {
                "titel": "Kegelbahn",
                "bild": "",
                "beschreibung": "Die 10 Meter lange Kegelbahn bietet mobilen Kegelspaß für Kinder und ist ein Highlight für jedes Kinderfest. Die neun Kegel werden per Hand aufgestellt. \n",
                "platz_and_tech": "Platzbedarf: 10 m x 1,5 m                                                                               technische Daten: keine",
                "netto": "150,00 €",
                "brutto": "178,50 €"
            },
            {
                "titel": "Kinderschminken",
                "bild": "",
                "beschreibung": "Die Theaterschminke ist hautverträglich und leicht zu entfernen. Die Kinder können aus einer Vielzahl von Vorlagen ihr Wunschmotiv aussuchen. \nInklusive Tische, Bänke und aufblasbare Pavillon.",
                "platz_and_tech": "Platzbedarf: ca. 3 m x 3 m\ntechnische Daten: keine    ",
                "netto": "350,00 €",
                "brutto": "416,50 €"
            },
            {
                "titel": "Köpfe abwerfen",
                "bild": "",
                "beschreibung": "Die besondere Art des Dosenwerfens. Mit Hilfe eines Balls muss versucht werden so viele Köpfe wie möglich abzuwerfen. Die Köpfe können zuvor selbst gestaltet werden.                                                            ",
                "platz_and_tech": "Platzbedarf: ca. 2,2 m x 0,35 m                                                                           technische Daten: keine",
                "netto": "375,00 €",
                "brutto": "446,25 €"
            },
            {
                "titel": "Kreativzelt",
                "bild": "",
                "beschreibung": "Im Kreativzelt wird nach bestimmten Mottos gebastelt, geklebt oder gebaut. Betreut werden die Kinder von Animateuren, denen die Ideen nicht ausgehen.                                                                                       ",
                "platz_and_tech": "Platzbedarf: ca. 3 m x 3 m\ntechnische Daten: keine    ",
                "netto": "250,00 €",
                "brutto": "297,50 €"
            },
            {
                "titel": "Kuh zum Melken",
                "bild": "",
                "beschreibung": "Die Kuh ist eine lebensgroße Attrappe, die mit Flüssigkeit gefüllt wird. Ziel des Wettmelkens ist es, den Eimer möglichst schnell zu befüllen. \n",
                "platz_and_tech": "Platzbedarf: 2 m x 0,5 m\ntechnische Daten: keine",
                "netto": "100,00 €",
                "brutto": "119,00 €"
            },
            {
                "titel": "Lachspiegel",
                "bild": "",
                "beschreibung": "Die Spiegel machen einen groß, dick, dünn oder klein. Ein Vergnügen, das nicht nur bei Kindern großen Anklang findet. \n",
                "platz_and_tech": "Platzbedarf: 0,5 m x 0,5 m\ntechnische Daten: keine",
                "netto": "250,00 €",
                "brutto": "297,50 €"
            },
            {
                "titel": "Lasso werfen",
                "bild": "",
                "beschreibung": "Werden Sie zum echten Cowboy und versuchen Sie mit viel Schwung und Spaß die vorgegebenen Ziele zu treffen.                                                                           ",
                "platz_and_tech": "Platzbedarf: 4 m x 2 m                                                                     technische Daten: keine",
                "netto": "250,00 €",
                "brutto": "297,50 €"
            },
            {
                "titel": "Laufpferde",
                "bild": "",
                "beschreibung": "Einmal Cowboy sein! Durch das Auf- und Abbewegen setzen sich die Pferde in Bewegung und sind sowohl für kleine, als auch große Reiter bestens geeignet.\nInkl. Umrandung \n",
                "platz_and_tech": "Platzbedarf: 10 m x 10 m                                                                                        technische Daten: keine",
                "netto": "600,00 €",
                "brutto": "714,00 €"
            },
            {
                "titel": "Legobausteine",
                "bild": "",
                "beschreibung": "Auf Spielteppichen werden große und kleine Legosteine verteilt und regen die Fantasie an. Es kann gebaut werden bis kein Stein mehr übrig ist.                                                                                                        ",
                "platz_and_tech": "Platzbedarf: ca. 1,5 m x 1,5 m\ntechnische Daten: keine",
                "netto": "140,00 €",
                "brutto": "166,60 €"
            },
            {
                "titel": "Minigolf Parcour",
                "bild": "",
                "beschreibung": "Ein bunter, kleiner Mobilparcour zum Minigolf spielen mit verschiedenen Bahnen aller Schwierigkeitsgrade. Inklusive Schläger und Bälle.                                 ",
                "platz_and_tech": "Platzbedarf: 5 m x 5 m                                                                   technische Daten: keine",
                "netto": "150,00 €",
                "brutto": "178,50 €"
            },
            {
                "titel": "Pedalos",
                "bild": "",
                "beschreibung": "Ein Pedalo ist ein Spielgerät, mit dme man sich fortbewegen kann. Meistens aus Holz gefertigt mit vier Rädern, fördert es vor allem das Gleichgewicht.",
                "platz_and_tech": "Platzbedarf: 3 m x 5 m                                                                technische Daten: keine",
                "netto": "10,00 €",
                "brutto": "11,30 €"
            },
            {
                "titel": "Rasenski",
                "bild": "",
                "beschreibung": "Rasenski ist ein tolles Teamspiel, bei dem ein Team nur im Gleichschritt vorwärts kommt. Bis zu vier Personen können in einem Team mitspielen.",
                "platz_and_tech": "Platzbedarf: 3 m x 5 m                                                                technische Daten: keine",
                "netto": "10,00 €",
                "brutto": "11,30 €"
            },
            {
                "titel": "Ring-Stechbahn",
                "bild": "",
                "beschreibung": "Der Spieler sitzt auf einem Metallpferd und rutscht eine Bahn hinunter. Es muss versucht werden, mit der Lanze den Ring vom Hacken zu stechen. \n",
                "platz_and_tech": "Platzbedarf: 17 m x 3 m\ntechnische Daten: keine",
                "netto": "600,00 €",
                "brutto": "714,00 €"
            },
            {
                "titel": "Rollbahn",
                "bild": "",
                "beschreibung": " Auf den zusammengesetzten Rollbändern können Kinder bis 50 kg auf bunten Schalen herunterrutschen. Die Bahn steht sicher und fest auf Metallböcken. \n",
                "platz_and_tech": "Platzbedarf: ca. 13 m x 2 m\ntechnische Daten: keine",
                "netto": "300,00 €",
                "brutto": "357,00 €"
            },
            {
                "titel": "Schachspiel XXL",
                "bild": "",
                "beschreibung": "Für alle Schachfans ist diese XXL Variante ein besonderes Vergnügen. Egal ob für drinnen und draußen, das Spiel ist auch für Zuschauer ein besonderer Hingucker. \n \n",
                "platz_and_tech": "Platzbedarf: 1 m x 1 m\ntechnische Daten: kein",
                "netto": "200,00 €",
                "brutto": "238,00 €"
            },
            {
                "titel": "Spielteppich",
                "bild": "",
                "beschreibung": "Die Spielteppiche gibt es in vielen Farben und Motiven. Sei es der Besuch im Zoo, das Autofahren durch die Stadt oder das Füttern der Tiere auf dem Baunerhof.\n",
                "platz_and_tech": "Platzbedarf: 2 m x 2 m                                                                   technische Daten: keine",
                "netto": "5,00 €",
                "brutto": "5,95 €"
            },
            {
                "titel": "Stelzen",
                "bild": "",
                "beschreibung": "Das besondere Gleichgewichtstraining. Das Stehen auf Stelzen kann man erhöht herumspazieren und seine Koordination und Gleichgewicht trainieren.\n",
                "platz_and_tech": "Platzbedarf: 3 m x 5 m                                                                   technische Daten: keine",
                "netto": "5,00 €",
                "brutto": "5,95 €"
            },
            {
                "titel": "Tischkicker",
                "bild": "",
                "beschreibung": "Der Tischkicker ist für zwei bis vier Spieler geeignet. Die Spielfiguren haben die Farben rot und blau. Der Einwurf des Balls erfolgt über die Seiten. \n ",
                "platz_and_tech": "Platzbedarf: 1,45 m x 0,75 m\ntechnische Daten: keine",
                "netto": "130,00 €",
                "brutto": "154,70 €"
            },
            {
                "titel": "Tischtennisplatte",
                "bild": "",
                "beschreibung": "Bei einer schönen Partie Tischntennis können die Kinder ihr Können unter Beweis stellen.  Tischtennisplatte inkl. Zubehör (Marke Kettler )                                                    ",
                "platz_and_tech": "Platzbedarf: 3 m x 2 m                 technische Daten: keine",
                "netto": "250,00 €",
                "brutto": "297,50 €"
            },
            {
                "titel": "Torwand",
                "bild": "",
                "beschreibung": "Die Torwand ist immer wieder beliebt bei Jung und Alt. Der genaue Schütze versenkt den Ball! Die Fußbälle werden selbstverständlich mitgeliefert.\n\n ",
                "platz_and_tech": "Platzbedarf: 2,7 m x 1 m x H 1,83 m\ntechnische Daten: keine",
                "netto": "120,00 €",
                "brutto": "142,80 €"
            },
            {
                "titel": "XXL Vier Gewinnt",
                "bild": "",
                "beschreibung": "Vier gewinnt kennt jeder, allerdings nicht in dieser Größe. Man kann gegeneinander antreten und versuchen zuerst vier in einer Reihe zu erzielen.",
                "platz_and_tech": "Platzbedarf: 2 m x 2 m                                                                   technische Daten: keine",
                "netto": "25,00 €",
                "brutto": "29,75 €"
            },
            {
                "titel": "XXL Mensch ärger dich nicht",
                "bild": "",
                "beschreibung": "Ein altbekanntes Gesellschaftsspiel, aber in dieser Größe ist es in den meisten Haushalten nicht anzutreffen. ",
                "platz_and_tech": "Platzbedarf: 2 m x 2 m                                                                   technische Daten: keine",
                "netto": "15,00 €",
                "brutto": "17,85 €"
            },
            {
                "titel": "Alle Bruttopreise enthalten die gesetzliche Mehrwertsteuer. Die oben erwähnten Geräte können nur durch die Personal der S.O.F.A. GmbH  benutzt werden. Die Preise beziehen sich auf eine Einsatzdauer von sechs Stunden pro Tag + Auf - und Abbau und zuzüglich Betreuung. Die Angaben sind ohne Gewähr. Änderungen sind vorbehalten. Die Artikel von den Bildern können  abweichen.",
                "bild": "",
                "beschreibung": "%footer%",
                "platz_and_tech": "",
                "netto": "",
                "brutto": ""
            }
        ],
    },
    {
        url: "/prices/kuenstler-acts",
        items: [
            {
                "titel": "Ballon Künstler",
                "bild": "",
                "beschreibung": "Von Tieren, über Menschen, Pflanzen, Gegenständen oder Kopfbedeckung ist alles dabei. Freuen Sie sich auf lustigen Ballon-Spaß für Groß und Klein zum Mitnehmen.      ",
                "netto": "Preis auf Anfrage. *",
                "brutto": ""
            },
            {
                "titel": "Ballon Weitflug",
                "bild": "",
                "beschreibung": "Ballonweitflugaktionen kommen immer gut an, ob bei einer Einweihung oder für eine Hochzeit. Die Ballons werden nach Ihren Wünschen bedruckt, mit Helium befüllt und mit den beschrifteten Karten versehen. \n                                                                                                                    ",
                "netto": "Preis auf Anfrage. *",
                "brutto": ""
            },
            {
                "titel": "Clown",
                "bild": "",
                "beschreibung": "Unser Clown bringt Sie defitiv zum Lachen. Ob witzige kleine Sketches, misslunge Zaubertricks oder außergewönhliche Posen, es ist für jeden Humor etwas dabei. Ein unterhaltsamer Act für Groß und Klein.",
                "netto": "Preis auf Anfrage. *",
                "brutto": ""
            },
            {
                "titel": "Croupier",
                "bild": "",
                "beschreibung": "Für einen gelungenen Casinoabend braucht man auch einen guten Croupier, der die Spielregeln beherrscht und für Ruhe und Ordnung am Tisch sorgt. ",
                "netto": "Preis auf Anfrage. *",
                "brutto": ""
            },
            {
                "titel": "DJ",
                "bild": "",
                "beschreibung": "Mit eigenem Equipment und bester Laune sorgen unsere DJ`s zu jeder Tages- und Nachtzeit für gute Stimmung.  ",
                "netto": "Preis auf Anfrage. *",
                "brutto": ""
            },
            {
                "titel": "Feuer Show",
                "bild": "",
                "beschreibung": "Jetzt wird`s heiß ! Ob Feuer spucken, Feuerjonglage und Akrobatik, die Nerven der Zuschauer blankliegen.  Mit der passenden Musik erhält die Feuer Show noch einen mystischen Rahmen.",
                "netto": "Preis auf Anfrage. *",
                "brutto": ""
            },
            {
                "titel": "Hostesse",
                "bild": "",
                "beschreibung": "Unsere Hostesse kümmert sich um Ihre Gäste. Von Sitzplatzzuweisung, über Servieren von Getränken und Speisen, Einkassieren von Eintrittspreisen sind unsere Hostessen in vielen Gebieten einsetzbar.",
                "netto": "Preis auf Anfrage. *",
                "brutto": ""
            },
            {
                "titel": "Live Band",
                "bild": "",
                "beschreibung": "Das Repertoire unserer Livebands reicht von Klassik über Pop bis hin zu mitreißendem Rock oder Soul. Teilen Sie uns einfach Ihre Wünsche mit und lassen Sie sich von stimmgewaltiger Musik verzaubern.                                                           ",
                "netto": "Preis auf Anfrage. *",
                "brutto": ""
            },
            {
                "titel": "Maskottchen",
                "bild": "",
                "beschreibung": "Süßer, flauschiger Walking Act der Kinderaugen zum Strahlen bringt. Ideal für Foto- und Marketingkampagnen.\nAuswahl aus verschiedenen Tierchen- und Figuren. ",
                "netto": "Preis auf Anfrage. *",
                "brutto": ""
            },
            {
                "titel": "Mittsommer Blumenkränze",
                "bild": "",
                "beschreibung": "Entsprechend dem schwedischen Brauch, bieten wir für Ihre Gäste einen kleinen Workshop. Bei dem sie sich unter Anleitung und mit viel Kreativität und Phantasie floralen Haarschmuck und Kränze basteln können.",
                "netto": "Preis auf Anfrage. *",
                "brutto": ""
            },
            {
                "titel": "Moderator",
                "bild": "",
                "beschreibung": "Um die Stimmung auf einer Veranstaltung zu heben, bietet sich ein Moderator an, der die Stimmung auflockert. Im Mietpreis sind der Moderator und eine Übertragungsanlage enthalten.                                                                                                       ",
                "netto": "Preis auf Anfrage. *",
                "brutto": ""
            },
            {
                "titel": "Prinzessin",
                "bild": "",
                "beschreibung": "Lassen Sie sich und Ihre Gäste von unserer wunderbaren Prinzessin verzaubern und nutzen Sie diese als Werbeplattform für Fotocollagen in den sozialen Medien oder als Trägerin kleiner Give Aways.",
                "netto": "Preis auf Anfrage. *",
                "brutto": ""
            },
            {
                "titel": "Samba Show",
                "bild": "",
                "beschreibung": "Lassen Sie sich von unserer Samba Show verzaubern. Bunte ausgefallene Kleider, Rhythmus zu dem man sich bewegen muss und Tänzer die vor Ausstrahlung nur so strotzen.",
                "netto": "Preis auf Anfrage. *",
                "brutto": ""
            },
            {
                "titel": "Sky Dancer",
                "bild": "",
                "beschreibung": "Durch diese tänzerischen Bewegungen erhalten die ansonsten statischen Ballon-Displays eine zusätzliche dynamische Komponente. Der unübersehbare Blickfang für Ihren Event!                                      ",
                "netto": "230,00 €",
                "brutto": "273,70 €"
            },
            {
                "titel": "Weihnachtsmann",
                "bild": "",
                "beschreibung": "Der Weihnachtsmann verteilt kleine Naschereien an Groß und Klein. Zusätzlich kann er die Kinder auf den Schoß nehmen und sich ihre Weihnachtswünsche anhören.\n",
                "netto": "300,00 €",
                "brutto": "357,00 €"
            },
            {
                "titel": "Weihnachtsmannfigur",
                "bild": "",
                "beschreibung": "Der aufblasbare Weihnachtsmann ist ein schöner Blickfang. Die Weihnachtsmannfigur besteht aus einem Stoffgewebe, das über ein Gebläse gezogen wird. Die Figur ist sechs Meter hoch.                                                           ",
                "netto": "215,00 €",
                "brutto": "255,85 €"
            },
            {
                "titel": "Zauberer",
                "bild": "",
                "beschreibung": "Lassen Sie sich von unseren Magiern verzaubern und schauen Sie dabei zu wie er sowohl Groß als auch Klein zum Staunen bringen. Wir garantieren Ihnen einen magischen Abend mit viel Spaß und Freude.                                                                                         ",
                "netto": "Preis auf Anfrage. *",
                "brutto": ""
            },
            {
                "titel": "Alle Bruttopreise enthalten die gesetzliche Mehrwertsteuer. * Den Preis erhalten Sie auf Anfrage, da dieser sich aus verschiedenen Faktoren zusammen setzt. Wie zum Beispiel: Einsatzlänge?, Einsatzort?, Einsatztag?, Soll es ein odere mehrere Künstler sein? Scheuen Sie sich nicht uns anzusprechen.",
                "bild": "",
                "beschreibung": "%footer%",
                "netto": "",
                "brutto": ""
            }
        ],
    },
    {
        url: "/prices/zelte",
        items: [
            {
                "titel": "Pagoden (4 m x 4 m)",
                "bild": "",
                "beschreibung": "Weiße Pagode mit Fenstern und Spitzdach. Die einzelnen Pagoden können mit anderen Pagoden verbunden werden. Die Seitenwände können herausgenommen werden.                                                                                  ",
                "platz_and_tech": "Platzbedarf: 4 m x 4 m\ntechnische Daten: keine",
                "netto": "300,00 €",
                "brutto": "357,00 €"
            },
            {
                "titel": "Pagoden (5 m x 5 m)",
                "bild": "",
                "beschreibung": "Weiße Pagode mit Fenstern und Spitzdach. Die einzelnen Pagoden können mit anderen Pagoden verbunden werden. Die Seitenwände können herausgenommen werden.                                                                                      ",
                "platz_and_tech": "Platzbedarf: 5 m x 5 m\ntechnische Daten: keine",
                "netto": "350,00 €",
                "brutto": "416,50 €"
            },
            {
                "titel": "Pavillon",
                "bild": "",
                "beschreibung": "Der vier füßige Pavillon ist schnell und einfach aufzubauen. Dank der Falttechnik wird der Pavillon auseinander gezogen und in Position gebracht. Bei dieser Variation entfällt das Suchen nach den richtigen Stangen.\n",
                "platz_and_tech": "Platzbedarf: ca. 3 m x 3 m\ntechnische Daten: keine",
                "netto": "110,00 €",
                "brutto": "130,90 €"
            },
            {
                "titel": "Pavillon (aufblasbar)",
                "bild": "",
                "beschreibung": "Der aufblasbare Pavillon steht auf fünf gelben Füßen. Es ist möglich rote Seitenwände an dem Pavillon zu befestigen. \nSchnell aufgebaut und wasserabweisend\n",
                "platz_and_tech": "Platzbedarf: ca. Ø 6 m \ntechnische Daten: 230 V / 16 A",
                "netto": "200,00 €",
                "brutto": "238,00 €"
            },
            {
                "titel": "Zelte",
                "bild": "",
                "beschreibung": "Weißes Zelt mit Fenstern und Kassettenfußboden. In verschiedenen Größen erhältlich. Die einzelnen Seitenteile können geöffnet oder ganz herausgenommen werden. Es ist möglich das Zelt durch Trennwände in einzelne Bereiche aufzuteilen.                                                                             ",
                "platz_and_tech": "Platzbedarf: verschiedene Größen\ntechnische Daten: keine      ",
                "netto": "Preis auf Anfrage.",
                "brutto": ""
            },
            {
                "titel": "Zirkuszelt",
                "bild": "",
                "beschreibung": "Das vierfarbige runde Zelt ist besonders für den Kinderbereich geeignet. Das Zelt hat eine Mittelstange und ein Spitzdach. Das Zelt ist in verschiedenen Größen und Farben erhältlich.                                                                                                     ",
                "platz_and_tech": "Platzbedarf: ca. Ø 6 m\ntechnische Daten: keine",
                "netto": "450,00 €",
                "brutto": "535,50 €"
            },
            {
                "titel": "Alle Bruttopreise enthalten die gesetzliche Mehrwertsteuer. Die oben erwähnten Geräte können nur durch die Personal der S.O.F.A. GmbH  benutzt werden. Die Preise beziehen sich auf eine Einsatzdauer von sechs Stunden pro Tag + Auf - und Abbau und zuzüglich Betreuung. Die Angaben sind ohne Gewähr. Änderungen sind vorbehalten. Die Artikel von den Bildern können  abweichen.",
                "bild": "",
                "beschreibung": "%footer%",
                "platz_and_tech": "",
                "netto": "",
                "brutto": ""
            }
        ],
    },
    {
        url: "/prices/mobi-tech",
        items: [
            {
                "titel": "Bierzeltgarnitur",
                "bild": "",
                "beschreibung": "Unsere Bierzeltgarnituren sind für jede Veranstaltung geeignet. Beim Oktoberfest, Hochzeiten oder zum Kinderbasteln die Bierzeltgarnituren sind vielseits einsetzbar. Drinnen und draußen nutzbar.",
                "platz_and_tech": "Platzbedarf: 2,5 m x 2 m\ntechnische Daten: keine",
                "netto": "30,00 €",
                "brutto": "35,70 €"
            },
            {
                "titel": "Fotobox",
                "bild": "",
                "beschreibung": "Unsere neue Fotobox ist kompakt und einfach zu bedienen und ist ein kleines Highlight auf jeder Feier.\nDie Gäste können über die gesamte Dauer Ihrer Veranstaltung selbstständig Fotos machen, welche dann auch direkt ausgedruckt werden.                                                                                ",
                "platz_and_tech": "Platzbedarf: 2 m x 2 m                                                                                         technische Daten: 230 V",
                "netto": "500,00 €",
                "brutto": "595,00 €"
            },
            {
                "titel": "Holzhütte",
                "bild": "",
                "beschreibung": "Unsere Holzhütten können  Sie zur jeder Festigkeit benutzen. Ob Jahrmarkt, Weihnachtsfeier oder für Kleinspiele. Dort stehen Sie immer warm und trocken und zusätzlich ist sie ein schicker Augenfang für Passanten.                                                                                                                           ",
                "platz_and_tech": "Platzbedarf: 2 m x 2,5 m\ntechnische Daten: keine",
                "netto": "Preis auf Anfrage",
                "brutto": ""
            },
            {
                "titel": "Icebar",
                "bild": "",
                "beschreibung": "Die Eisbar ist komplett aus Acryl , schafft die perfekte Illusion und ist ein echter Hingucker. Die Elemente der Eisbar, welche die Frontseite der Bar verkleiden, haben eine maximale Länge von 5 Metern. Die Rückwand besteht aus weiteren mobilen Elementen.",
                "platz_and_tech": "Platzbedarf: 5 m x 3 m\ntechnische Daten: keine",
                "netto": "Preis auf Anfrage",
                "brutto": ""
            },
            {
                "titel": "LED Strahler",
                "bild": "",
                "beschreibung": "Immer und überall die richtige Beleuchtung mit unseren LED Strahlern. Mit verschiedenen Farben können Sie die perfekte Atmosphäre für jede Stimmung auf der Veranstaltung erstellen.",
                "platz_and_tech": "Platzbedarf: 2 m x 2 m            technische Daten: 230 V / 16 A",
                "netto": "45,00 €",
                "brutto": "53,55 €"
            },
            {
                "titel": "Marktstand",
                "bild": "",
                "beschreibung": "Der Markstand ist aus roten Metall und mit rot-weiß gestreiftem Stoff bespannt. Er ist durch ein einfaches Stecksystem leicht und schnell aufzubauen. Der Marktstand hat ein Dach und unter dem Stand eine Ablagefläche. Daher eignet er sich für Spiele, als Cateringstand oder als Infopoint.\n",
                "platz_and_tech": "Platzbedarf: 2,5 m x 1 m\ntechnische Daten: keine",
                "netto": "130,00 €",
                "brutto": "154,70 €"
            },
            {
                "titel": "Nebelmaschine",
                "bild": "",
                "beschreibung": "Die Nebelmaschine darf auf keiner Party fehlen. Ob bei einer Disco als besonderer Effekt oder bei der Halloweenfeier als schaurige Dekoration. Die Nebelmaschine produziert Nebel in verschiedenen Stufen.                                                                                              ",
                "platz_and_tech": "Platzbedarf: ca. 0,5 m x 0,3 m\ntechnische Daten: 230 V / 16 A",
                "netto": "120,00 €",
                "brutto": "142,80 €"
            },
            {
                "titel": "Seifenblasenmaschine",
                "bild": "",
                "beschreibung": "Die kleine Maschine produziert Seifenblasen am laufenden Band. Ob als Deko oder als lustige Einlage bei Kinderfesten. Die Seifenblasenmaschine wird mit einem Liter Seifenfluid geliefert. Das Fluid mit Wasser verdünnen, in die Maschine einfüllen und einschalten. ",
                "platz_and_tech": "Platzbedarf: ca. 0,3 m x 0,3 m                                                                           technische Daten: 230 V / 16 A",
                "netto": "55,00 €",
                "brutto": "65,45 €"
            },
            {
                "titel": "Selfie Mirror",
                "bild": "",
                "beschreibung": "Sobald man bereit ist, wird durch berühren des Start Buttons der Countdown für die Kamera ausgelöst. Im Anschluss kann das Bild individuell per Touchscreen signiert und bemalt oder mit Emojis verziert werden. Noch vor Ort druckt der Spiegel das Foto aus. ",
                "platz_and_tech": "Platzbedarf: 2 m x 1 m x 1 m\ntechnische Daten: 230 V / 16 A",
                "netto": "875,00 €",
                "brutto": "1.041,25 €"
            },
            {
                "titel": "Stehtisch",
                "bild": "",
                "beschreibung": "Unsere Stehtische können Sie zu jedem Anlass nutzen. Egal ob drinnen oder draußen, sie lockern das Gestehen einer Veranstaltung gut auf. Zusätzlich können Sie die Stehtische mit unterschiedlich farbenden Hussen erhalten.",
                "platz_and_tech": "Platzbedarf: ca. 0,5 m x 0,5 m\ntechnische Daten: keine",
                "netto": "10,00 €",
                "brutto": "11,90 €"
            },
            {
                "titel": "Alle Bruttopreise enthalten die gesetzliche Mehrwertsteuer. Die oben erwähnten Geräte können nur durch die Personal der S.O.F.A. GmbH  benutzt werden. Die Preise beziehen sich auf eine Einsatzdauer von sechs Stunden pro Tag + Auf - und Abbau und zuzüglich Betreuung. Die Angaben sind ohne Gewähr. Änderungen sind vorbehalten. Die Artikel von den Bildern können  abweichen.",
                "bild": "",
                "beschreibung": "%footer%",
                "platz_and_tech": "",
                "netto": "",
                "brutto": ""
            }
        ],
    }
]