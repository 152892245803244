import React from "react";
import {Box, Paper, useMediaQuery} from "@material-ui/core";
import Ansprechpartner from "./Ansprechpartner";
import styled from "styled-components";
import {motion} from "framer-motion";

function KontaktCard({zweigstelle}) {
    const matches1400 = useMediaQuery('(max-width:1400px)');
    const matches500 = useMediaQuery('(max-width:500px)');

    return (
        <>
            <Box padding={"3%"} paddingTop={"3%"}>
                <Box width={!matches1400 ? "25%" : (!matches500 ? "75%" : "95%")} marginTop={!matches1400 ? "" : (!matches500 ? "0%" : "0%")} textAlign={"center"} marginLeft={!matches500 ? "0%" : "auto"} marginRight={matches500 && "auto"}>
                    <Paper style={{backgroundColor: "var(--main-bg-color-sec)"}}>
                        <h1 style={{padding: "20px 0 10px 0"}}>Kontakt</h1>
                        <Line style={{background: `${zweigstelle.color}`}}/>
                        <Ansprechpartner tel={zweigstelle.tel} email={zweigstelle.email} strase={zweigstelle.strase} ort={zweigstelle.ort} color={zweigstelle.color}/>
                    </Paper>
                </Box>
            </Box>
        </>
    )
}

const Line = styled(motion.div)`
height: 0.5rem;
width: 100%;
`

export default KontaktCard