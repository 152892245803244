import React from "react";
import {Box, useMediaQuery} from "@material-ui/core";
import {Link} from "react-router-dom";
import {motion} from "framer-motion";
import styled from "styled-components";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from "@mui/material";
import CustomCheckbox from "./CustomCheckbox";
import YouTubeIcon from '@mui/icons-material/YouTube';

const TextFiel = styled(TextField)({
    color: "white !important",

    '& label': {
        color: 'black',
    },

    '& .MuiInputBase-input': {
        color: 'black',
    },

    '& label.Mui-focused': {
        color: 'var(--main-accent-color)',
    },

    '& .MuiInput-underline:after': {
        borderBottomColor: 'var(--main-accent-color)',
    },

    '& input:focus': {
        color: 'black !important',
    },

    '& textarea:focus': {
        color: 'black !important',
    },
});

function Footer() {
    const matches = useMediaQuery('(max-width:800px)');

    const footer = {
        hidden: {
            x: -1000,
            transition: {
                duration: 0.5,
                ease: "easeOut",
            },
        },
        visible: {
            x: 0,
            transition: {
                duration: 1,
                ease: "easeOut",
            },
        }
    }

    const [open, setOpen] = React.useState(false);
    const [fields, setFields] = React.useState({
        firma: "",
        name: "",
        email: "",
        straseWithNr: "",
        plzWithOrt: "",
        telefon: "",
        kommentar: "",
        accept: false,
    })

    function handleChange(evt) {
        const value = evt.target.value;
        setFields({
            ...fields,
            [evt.target.name]: value
        });
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let mailtext = "";
        mailtext = mailtext + "Firma: %0A" + fields.firma + "%0A%0A"
        mailtext = mailtext + "Name: %0A" + fields.name + "%0A%0A"
        mailtext = mailtext + "E-Mail: %0A" + fields.email + "%0A%0A"
        mailtext = mailtext + "Straße, Nr: %0A" + fields.straseWithNr + "%0A%0A"
        mailtext = mailtext + "PLZ, Ort: %0A" + fields.plzWithOrt + "%0A%0A"
        mailtext = mailtext + "Telefon: %0A" + fields.telefon + "%0A%0A"
        mailtext = mailtext + "Kommentar: %0A" + fields.kommentar + "%0A%0A"
        window.open(`mailto:info@sofa-gmbh.de?subject=Kontakt&body=Sehr geehrte Damen und Herren, %0A%0AIch habe folgendes Anliegen: %0A${mailtext}%0AVielen Dank im Voraus!%0A%0AMit freundlichen Gruessen`, "_blank");
        handleClose();
    }

    return (
        <>
            <motion.div variants={footer} initial={"hidden"} animate={"visible"} exit={"hidden"}
                        style={{
                            margin: "20px 0px",
                            marginBottom: "100px",
                            paddingTop: "25px",
                            paddingLeft: "20px",
                            paddingRight: "20px",
                        }}>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Kontakt</DialogTitle>
                    <form onSubmit={handleSubmit}>
                        <DialogContent>
                            <TextFiel label="Firma" name={"firma"} margin={"dense"} value={fields.firma}
                                      onChange={handleChange} fullWidth variant="standard" type={"text"}/>
                            <TextFiel autoFocus label="Name" name={"name"} margin={"dense"} value={fields.name} required
                                      onChange={handleChange} fullWidth variant="standard" type={"text"}/>
                            <TextFiel label="E-Mail" name={"email"} margin={"dense"} value={fields.email} required
                                      onChange={handleChange} fullWidth variant="standard" type={"email"}/>
                            <TextFiel label="Straße, Nr." name={"straseWithNr"} margin={"dense"}
                                      value={fields.straseWithNr} onChange={handleChange} fullWidth variant="standard"
                                      required
                                      type={"text"}/>
                            <TextFiel label="PLZ, Ort" name={"plzWithOrt"} margin={"dense"}
                                      value={fields.plzWithOrt} onChange={handleChange} fullWidth variant="standard"
                                      required
                                      type={"text"}/>
                            <TextFiel label="Telefon" name={"telefon"} margin={"dense"} value={fields.telefon} required
                                      onChange={handleChange} fullWidth variant="standard" type={"tel"}/>
                            <TextFiel label="Kommentar" name={"kommentar"} margin={"dense"} value={fields.kommentar}
                                      onChange={handleChange} fullWidth variant="standard" type={"text"} multiline/>
                            <CustomCheckbox required name={"accept"} defaultChecked={false}
                                            checked={Boolean(fields.accept)}
                                            value={Boolean(fields.accept)}
                                            onClick={handleChange}
                                            text={"Ich stimme den Datenschutzbestimmungen zu."}/>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Abbrechen</Button>
                            <Button type={"submit"}>Anfrage senden</Button>
                        </DialogActions>
                    </form>
                </Dialog>
                <Box display={"flex"} width={"100%"} flexDirection={matches && "column"}
                     justifyContent={"space-between"}>
                    <Box display={"flex"} alignItems={"center"} style={{cursor: "pointer"}}>
                        <FacebookIcon fontSize={"large"} style={{marginRight: "10px", color: "#0f91f3"}} onClick={() => window.open("https://www.facebook.com/sofagmbh", "_blank")}/>
                        <InstagramIcon fontSize={"large"} style={{marginRight: "10px", color: "#d42e77"}} onClick={() => window.open("https://www.instagram.com/sofagmbh", "_blank")}/>
                        <YouTubeIcon fontSize={"large"} style={{marginRight: "10px", color: "#f70000"}} onClick={() => window.open("https://www.youtube.com/channel/UCSlBHDBlmSwDJtbGBTGtB-A", "_blank")}/>
                    </Box>
                    <Box display={"flex"} flexDirection={matches && "column"}
                         alignItems={"center"}>
                        <Link to={"/impressum"} style={{color: "black"}}>
                            Impressum
                        </Link>
                        <Link to={"/datenschutz"}
                              style={{marginLeft: !matches && "20px", color: "black"}}>
                            Datenschutz
                        </Link>
                        <Link to={"/agb"}
                              style={{marginLeft: !matches && "20px", color: "black"}}>
                            AGB
                        </Link>
                        <a href={"/preisliste"}
                           style={{marginLeft: !matches && "20px", color: "black"}}>
                            Preisliste
                        </a>
                        <Link to={"/kataloge"}
                              style={{marginLeft: !matches && "20px", color: "black"}}>
                            Kataloge
                        </Link>
                        <div onClick={handleClickOpen}
                             style={{
                                 marginLeft: !matches && "20px",
                                 color: "black",
                                 textDecoration: "underline",
                                 cursor: "pointer"
                             }}>
                            Kontakt
                        </div>
                    </Box>
                </Box>
            </motion.div>
        </>
    )
}

export default Footer