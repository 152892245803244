import React from "react";
import { motion } from "framer-motion";
import { pageAnimation } from "../animation";
import ScrollTop from "../components/ScrollTop";
import styled from "styled-components";
import { Box, useMediaQuery } from "@material-ui/core";

function Datenschutz() {
    const matches = useMediaQuery('(max-width:800px)');

    return (
        <>
            <motion.div
                exit="exit"
                variants={pageAnimation}
                initial="hidden"
                animate="show"
            >
                <Style>
                    <Box width={!matches ? "50%" : "100%"} margin={"0 auto"}>
                        <h2 style={{ fontSize: matches && "2.5rem" }}>Datenschutzerklärung</h2>
                        <Line />
                        <Heading>Allgemeine Hinweise</Heading>
                        <p>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen
                            Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit
                            denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema
                            Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.</p>
                        <Heading>Datenerfassung auf unserer Website</Heading>
                        <SmallHeading>Wer ist
                            verantwortlich für die Datenerfassung auf dieser Website?</SmallHeading>
                        <p>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten
                            können Sie dem Impressum dieser Website entnehmen.</p>

                        <SmallHeading>Wie erfassen wir Ihre Daten?</SmallHeading>
                        <p>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich
                            z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.</p>
                        <p>
                            Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das
                            sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des
                            Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website
                            betreten.
                        </p>
                        <SmallHeading>Wofür nutzen wir Ihre Daten?</SmallHeading>

                        <p>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten.
                            Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.</p>

                        <SmallHeading>Welche Rechte haben Sie bezüglich Ihrer Daten?</SmallHeading>
                        <p>Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer
                            gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die
                            Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen
                            zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an
                            uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde
                            zu.</p>
                        <Heading>Analyse-Tools und Tools von Drittanbietern</Heading>
                        <p>Beim Besuch unserer Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht
                            vor allem mit Cookies und mit sogenannten Analyseprogrammen. Die Analyse Ihres Surf-Verhaltens
                            erfolgt in der Regel anonym; das Surf-Verhalten kann nicht zu Ihnen zurückverfolgt werden. Sie
                            können dieser Analyse widersprechen oder sie durch die Nichtbenutzung bestimmter Tools
                            verhindern. Detaillierte Informationen dazu finden Sie in der folgenden
                            Datenschutzerklärung.</p>
                        <p>Sie können dieser Analyse widersprechen. Über die Widerspruchsmöglichkeiten werden wir Sie in
                            dieser Datenschutzerklärung informieren.</p>
                        <Heading>Allgemeine Hinweise und Pflichtinformationen</Heading>
                        <p>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln
                            Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen
                            Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>
                        <p>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben.
                            Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die
                            vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen.
                            Sie erläutert auch, wie und zu welchem Zweck das geschieht.</p>
                        <p>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per
                            E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
                            Dritte ist nicht möglich.</p>
                        <SmallHeading>Hinweis zur verantwortlichen Stelle</SmallHeading>
                        <p>
                            Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
                            <br /><br />
                            <p>S.O.F.A. GmbH<br />
                                Lothar Genz<br />
                                Birkenring 18<br />
                                23795 Bad Segeberg <br /><br />

                                Telefon: 04551 9994726<br />
                                E-Mail: info@sofa-gmbh.de</p>

                            Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit
                            anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen,
                            E-Mail-Adressen o. Ä.) entscheidet.
                        </p>
                        <SmallHeading>Widerruf Ihrer Einwilligung zur Datenverarbeitung</SmallHeading>
                        <p>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie
                            können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose
                            Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
                            Datenverarbeitung bleibt vom Widerruf unberührt.</p>
                        <SmallHeading>Beschwerderecht bei der zuständigen Aufsichtsbehörde</SmallHeading>
                        <p>Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der
                            zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen
                            ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat.
                            Eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link entnommen
                            werden: <Link
                                href={"https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links- node.html"}>https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-
                                node.html. </Link></p>

                        <SmallHeading>Recht auf Datenübertragbarkeit</SmallHeading>
                        <p>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines
                            Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen,
                            maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an
                            einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>
                        <SmallHeading>SSL- bzw. TLS-Verschlüsselung</SmallHeading>
                        <p>Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher</p>
                        <p>Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden,
                            eine SSL-bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die
                            Adresszeile des Browsers von “http://” auf “https://” wechselt und an dem Schloss-Symbol in
                            Ihrer Browserzeile.</p>
                        <p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns
                            übermitteln, nicht von Dritten mitgelesen werden.</p>
                        <SmallHeading>Auskunft, Sperrung, Löschung</SmallHeading>
                        <p>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf
                            unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und
                            Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder
                            Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können
                            Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.</p>
                        <SmallHeading>Widerspruch gegen Werbe-Mails</SmallHeading>
                        <p>Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von
                            nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen.
                            Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der
                            unverlangten Zusendung von Werbeinformationen, etwa durch Spam-E-Mails, vor.</p>

                        <Heading>Datenerfassung auf unserer Website</Heading>
                        <SmallHeading>Cookies</SmallHeading>
                        <p>Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner
                            keinen Schaden an und enthalten keine Viren. Cookies dienen dazu, unser Angebot
                            nutzerfreundlicher, effektiver und sicherer zu machen. Cookies sind kleine Textdateien, die auf
                            Ihrem Rechner abgelegt werden und die Ihr Browser speichert.</p>
                        <p>Die meisten der von uns verwendeten Cookies sind so genannte “Session-Cookies”. Sie werden nach
                            Ende Ihres Besuchs automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert
                            bis Sie diese löschen. Diese Cookies ermöglichen es uns, Ihren Browser beim nächsten Besuch
                            wiederzuerkennen.</p>
                        <p>Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden
                            und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder
                            generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browser
                            aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website
                            eingeschränkt sein.</p>
                        <p>Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs oder zur Bereitstellung
                            bestimmter, von Ihnen erwünschter Funktionen (z.B. Warenkorbfunktion) erforderlich sind, werden
                            auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert. Der Websitebetreiber hat ein
                            berechtigtes Interesse an der Speicherung von Cookies zur technisch fehlerfreien und optimierten
                            Bereitstellung seiner Dienste. Soweit andere Cookies (z.B. Cookies zur Analyse Ihres
                            Surfverhaltens) gespeichert werden, werden diese in dieser Datenschutzerklärung gesondert
                            behandelt.</p>
                        <SmallHeading>Server-Log-Dateien</SmallHeading>
                        <p>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten
                            Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:
                        </p>
                        <List>
                            <li>Browsertyp und Browserversion</li>
                            <li>verwendetes Betriebssystem</li>
                            <li>Referrer URL</li>
                            <li>Hostname des zugreifenden Rechners</li>
                            <li>Uhrzeit der Serveranfrage</li>
                            <li>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</li>
                        </List>
                        <p>Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung von
                            Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.</p>
                        <SmallHeading>Kontaktformular</SmallHeading>
                        <p>Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem
                            Anfrageformular inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der
                            Anfrage und für den Fall von Anschlussfragen bei uns gespeichert. Diese Daten geben wir nicht
                            ohne Ihre Einwilligung weiter.</p>
                        <p>Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt somit ausschließlich auf
                            Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung
                            jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit
                            der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.</p>
                        <p>Die von Ihnen im Kontaktformular eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung
                            auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung
                            entfällt (z.B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche
                            Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.</p>
                        <Heading>Soziale Medien</Heading>
                        <SmallHeading>Inhalte teilen über Plugins (Facebook, Google+1, Twitter & Co.)</SmallHeading>
                        <p>Die Inhalte auf unseren Seiten können datenschutzkonform in sozialen Netzwerken wie Facebook,
                            Twitter oder Google+ geteilt werden. Diese Seite nutzt dafür das <Link
                                href={"https://www.e-recht24.de/erecht24-%20safe-sharing.html#datenschutz"}>eRecht24 Safe
                                Sharing Tool</Link>. Dieses Tool stellt den direkten Kontakt zwischen den Netzwerken und
                            Nutzern erst dann her, wenn der Nutzer aktiv auf einen dieser Button klickt.</p>
                        <p>Eine automatische Übertragung von Nutzerdaten an die Betreiber dieser Plattformen erfolgt durch
                            dieses Tool nicht. Ist der Nutzer bei einem der sozialen Netzwerke angemeldet, erscheint bei der
                            Nutzung der Social-Buttons von Facebook, Google+1, Twitter & Co. ein Informations-Fenster, in
                            dem der Nutzer den Text vor dem Absenden bestätigen kann.</p>
                        <p>Unsere Nutzer können die Inhalte dieser Seite datenschutzkonform in sozialen Netzwerken teilen,
                            ohne dass komplette Surf-Profile durch die Betreiber der Netzwerke erstellt werden.</p>
                        <SmallHeading>Facebook-Plugins (Like & Share-Button)</SmallHeading>
                        <p>Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook, Anbieter Facebook Inc., 1 Hacker
                            Way, Menlo Park, California 94025, USA, integriert. Die Facebook-Plugins erkennen Sie an dem
                            Facebook-Logo oder dem "Like-Button" ("Gefällt mir") auf unserer Seite. Eine Übersicht über die
                            Facebook-Plugins finden Sie hier: <Link
                                href={"https://developers.facebook.com/docs/plugins/"}>https://developers.facebook.com/docs/plugins/</Link>.
                        </p>
                        <p>Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte Verbindung zwischen Ihrem
                            Browser und dem Facebook-Server hergestellt. Facebook erhält dadurch die Information, dass Sie
                            mit Ihrer IP- Adresse unsere Seite besucht haben. Wenn Sie den Facebook "Like-Button" anklicken
                            während Sie in Ihrem Facebook-Account eingeloggt sind, können Sie die Inhalte unserer Seiten auf
                            Ihrem Facebook-Profil verlinken. Dadurch kann Facebook den Besuch unserer Seiten Ihrem
                            Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis
                            vom Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook erhalten. Weitere
                            Informationen hierzu finden Sie in der Datenschutzerklärung von Facebook unter: <Link
                                href={"https://de-de.facebook.com/policy.php"}>https://de- de.facebook.com/policy.php</Link>.
                        </p>
                        <p>Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten Ihrem Facebook-Nutzerkonto
                            zuordnen kann, loggen Sie sich bitte aus Ihrem Facebook- Benutzerkonto aus.</p>
                        <Heading>Analyse Tools und Werbung</Heading>
                        <SmallHeading>Google Analytics</SmallHeading>
                        <p>Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google
                            Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.</p>
                        <p>Google Analytics verwendet so genannte "Cookies". Das sind Textdateien, die auf Ihrem Computer
                            gespeichert werden und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die
                            durch den Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel
                            an einen Server von Google in den USA übertragen und dort gespeichert.</p>
                        <p>Die Speicherung von Google- Analytics-Cookies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f
                            DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der Analyse des Nutzerverhaltens,
                            um sowohl sein Webangebot als auch seine Werbung zu optimieren.</p>
                        <SmallHeading>IP Anonymisierung</SmallHeading>
                        <p>Wir haben auf dieser Website die Funktion IP-Anonymisierung aktiviert. Dadurch wird Ihre
                            IP-Adresse von Google innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
                            Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum vor der Übermittlung in die
                            USA gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den
                            USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese
                            Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
                            Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der
                            Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen. Die im
                            Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen
                            Daten von Google zusammengeführt.</p>
                        <SmallHeading>Browser Plugin</SmallHeading>
                        <p>Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
                            Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
                            gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
                            Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf Ihre Nutzung der
                            Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten
                            durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin
                            herunterladen und installieren: <Link
                                href={"https://tools.google.com/dlpage/gaoptout?hl=de"}>https://tools.google.com/dlpage/gaoptout?hl=de</Link>.
                        </p>
                        <SmallHeading>Widerspruch gegen Datenerfassung</SmallHeading>
                        <p>Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden
                            Link klicken. Es wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen
                            Besuchen dieser Website verhindert: Google Analytics deaktivieren.</p>
                        <p>Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der
                            Datenschutzerklärung von Google: <Link
                                href={"https://support.google.com/analytics/answer/6004245?hl=de"}>https://support.google.com/analytics/answer/6004245?hl=de</Link>.
                        </p>
                        <Heading>Plugins und Tools</Heading>
                        <SmallHeading>YouTube</SmallHeading>
                        <p>Unsere Website nutzt Plugins der von Google betriebenen Seite YouTube. Betreiber der Seiten ist
                            die YouTube, LLC, 901 Cherry Ave., San Bruno, CA 94066, USA.</p>

                        <p>Wenn Sie eine unserer mit einem YouTube-Plugin ausgestatteten Seiten besuchen, wird eine
                            Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt,
                            welche unserer Seiten Sie besucht haben.</p>

                        <p>Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube, Ihr Surfverhalten
                            direkt Ihrem persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus
                            Ihrem YouTube-Account ausloggen.</p>

                        <p>Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung unserer
                            Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO
                            dar.</p>

                        <p>Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von
                            YouTube unter: <Link
                                href={"https://www.google.de/intl/de/policies/privacy"}>https://www.google.de/intl/de/policies/privacy</Link>.
                        </p>
                    </Box>
                </Style>
            </motion.div>
            <ScrollTop />
        </>
    )
}

const Style = styled.div`
p {
    padding: 1.5rem 0rem;
}
`

const Link = styled.a`
color: var(--main-accent-color);
text-decoration: underline;
font-size: 1.4rem;
overflow-wrap: break-word;
`

const Line = styled(motion.div)`
height: 0.5rem;
background: var(--main-accent-color);
margin-bottom: 3rem;
`

const List = styled.ul`
font-size: 1.4rem;
color: var(--text-color);

li {
    margin-left: 35px;
    margin-top: 7.5px;
    margin-bottom: 7.5px;
}
`

const SmallHeading = styled.h3`
font-size: 17px;
color: var(--text-color);
margin-top: 10px;
`

const Heading = styled.strong`
font-size: 20px;
border-bottom: 2px solid var(--main-accent-color);
color: var(--text-color);
`

export default Datenschutz