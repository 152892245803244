import React from "react";
import CarouselItem from "./CarouselItem";
import Carousel from "react-material-ui-carousel";
import styled from "styled-components";

function FotoShow({images}) {

    return (
        <>
            <CustomCSS>
                <Carousel indicators={true} stopAutoPlayOnHover={false} autoPlay={true} animation={"fade"}
                          indicatorIconButtonProps={{
                              style: {
                                  margin: "5px",
                              }
                          }}
                          activeIndicatorIconButtonProps={{
                              style: {
                                  backgroundColor: 'var(--main-bg-color)',
                              }
                          }}
                          indicatorContainerProps={{
                              style: {
                                  marginTop: '-25px',
                                  paddingBottom: '25px',
                              }
                          }}
                >
                    {
                        images.map((item, i) => <CarouselItem key={i} item={item}/>)
                    }
                </Carousel>
            </CustomCSS>
        </>
    );
}

const CustomCSS = styled.div`
span {
    color: var(--main-bg-color-sec);
}
`

export default FotoShow