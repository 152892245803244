import React, {useEffect, useState} from "react";
import {pageAnimation} from "../animation";
import ScrollTop from "../components/ScrollTop";
import styled from "styled-components";
import {motion} from "framer-motion";
import {zweigstellenData} from "../zweigstellenData";
import {Box, useMediaQuery} from "@material-ui/core";
import KontaktCard from "../components/KontaktCard";
import {useLocation} from "react-router-dom";

function Zweigstelle() {
    const location = useLocation();
    const url = location.pathname;
    const matches900 = useMediaQuery('(max-width:900px)');
    const [Zweigstellen, setZweigstellen] = useState(zweigstellenData);
    const [zweigstelle, setZweigstelle] = useState(null);

    //UseEffect
    useEffect(() => {
        const currentZweig = Zweigstellen.filter((zweigstelle) => zweigstelle.url === url);
        setZweigstelle(currentZweig[0]);
        console.log(setZweigstellen);
    }, [Zweigstellen, url]);

    return (
        <>
            <motion.div
                exit="exit"
                variants={pageAnimation}
                initial="hidden"
                animate="show"
            >
                {zweigstelle && (
                    <>
                        <Content style={{
                            backgroundImage: `url('${zweigstelle.backgroundImage}')`,
                            backgroundSize: "contain",
                            backgroundRepeat: "round"
                        }}>
                            <div className={"flex-middle"} style={{borderLeft: `2px solid ${zweigstelle.color}`}}>
                                <h1 style={{fontSize: "250%"}}>{zweigstelle.title}</h1>
                                <p style={{
                                    whiteSpace: "pre-line",
                                    fontSize: "135%",
                                    width: "75%",
                                    padding: "1rem 0rem",
                                }}>{zweigstelle.desc}</p>
                            </div>
                        </Content>

                        <Box width={"100%"} height={"100%"} style={{backgroundColor: `${zweigstelle.color}`}}>
                            <KontaktCard zweigstelle={zweigstelle}/>
                        </Box>
                    </>
                )}
            </motion.div>
            <ScrollTop/>
        </>
    )
}

const Line = styled(motion.div)`
  height: 0.5rem;
  width: 100%;
`

const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  color: var(--other-color);
  z-index: 10;

  .flex-middle {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    background-color: rgb(0, 0, 0, 0.8);
    padding: 20px 50px;
    width: 50%;
    border-right: none;
    @media (max-width: 1200px) {
      width: unset !important;
      padding: 10px 25px;
    }
    @media (max-width: 400px) {
      text-align: center;
    }
  }

  a {
    text-decoration: none;
  }

  h1, p {
    color: white;
    text-align: right;
  }

  h1 {
    font-size: 50px;
    width: 100%;
    @media (max-width: 900px) {
      font-size: 30px;
    }
    @media (max-width: 400px) {
      font-size: 25px;
      width: 100%;
    }
  }

  p {
    font-size: 25px;
    margin: 20px 0;
    padding-left: 20px;
    width: 100%;
    float: right;
    @media (max-width: 900px) {
      font-size: 15px;
      margin: 10px 0;
    }
    @media (max-width: 400px) {
      font-size: 12.5px;
      width: 90%;
      margin: 5px 0;
    }
  }
`;

export default Zweigstelle