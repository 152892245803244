export const zweigstellenData = [
    {
        title: "Berlin",
        desc: "Tauchen Sie ein, in eine Welt voller Illusionen und Attraktionen! Planen Sie Ihre Veranstaltung mit uns und wir versprechen Ihnen einen Erfolg auf ganzer Linie! Bieten Sie Ihren Mitarbeitern, Gästen, Geschäftspartnern oder Kunden aus aller Welt etwas ganz Besonderes. Schaffen Sie zusammen mit uns Erlebnisse und Emotionen.\n" +
            "Sie suchen nach passenden Künstlern oder leckerem Catering für Ihre Veranstaltung? Sie planen eine Tagung, ein Incentive oder ein lockeres Get-Together? Die S.O.F.A. GmbH ist Ihr Ansprechpartner für alles, was Sie und Ihre Veranstaltung brauchen.",
        backgroundImage: "/img/zweigstellen/berlin.jpg",
        color: "#DB2925",
        url: "/zweigstelle/berlin",
        tel: "030 34787215",
        email: "ulicnik@sofa-gmbh.de",
        strase: "Lina-Morgenstern-Straße 35",
        ort: "10317 Berlin",
    },
    {
        title: "Hamburg",
        desc: "Die S.O.F.A. GmbH macht das Unmögliche möglich! Es ist nicht immer einfach eine perfekte Veranstaltung zu planen und die richtigen Emotionen bzw. Ziele zu erlangen. Nur eine Bratwurstbude, ein Bierwagen und eine Hüpfburg machen noch kein Firmenevent und locken die Mitarbeiter nur zum Essen an. Sie planen Ihre Veranstaltung mit uns und wir versprechen Ihnen einen Erfolg auf ganzer Linie. Doch das ist noch lange nicht alles, was das Team der S.O.F.A. GmbH rund um Lothar Genz Ihnen bieten kann – alles ist bei uns möglich und wahrscheinlich haben Sie einige Möglichkeiten noch gar nicht in Erwägung gezogen. Wir bauen gerne die Kinder, unsere Zukunft, bei jedem Event mit in das Programm ein. Alles aus einer Hand, ein kompetenter Ansprechpartner, ein Team, das Atmosphäre zaubern kann!",
        backgroundImage: "/img/zweigstellen/hamburg.jpg",
        color: "#eaa238",
        url: "/zweigstelle/hamburg",
        tel: "040 50740565",
        email: "moldt@sofa-hamburg.de",
        strase: "Hinschenfelder Str.12",
        ort: "22041 Hamburg",
    },
    {
        title: "NRW",
        desc: "Planen Sie Ihre perfekte Veranstaltung in Nordrhein-Westfalen!\n" +
            "Wir konzipieren, organisieren und betreuen Ihre Veranstaltung, um sie zu einem unvergesslichen Erlebnis zu machen!\n" +
            "Egal ob Sie sich nur ein einzelnes Unterhaltungsmodul oder gleich ein komplettes Eventkonzept wünschen- wir bieten Ihnen alles. Von der geeigneten Location, über ein leckeres Catering, bis zum kleinsten Dekoelement, wir machen jeden Wunsch wahr. Melden Sie sich bei der S.O.F.A. GmbH und machen Sie Ihre Veranstaltung in Nordrhein-Westfalen zu einem wahren Highlight!",
        backgroundImage: "/img/zweigstellen/nrw.jpg",
        color: "#00a03b",
        url: "/zweigstelle/nrw",
        tel: "02195 927877",
        email: "Aleksanyan@sofa-gmbh.de",
        strase: "Hulverscheidt 1a",
        ort: "42477 Radevormwald",
    },
    {
        title: "Bad Segeberg",
        desc: "Herzlich willkommen in unserer Hauptstelle in Bad Segeberg!\n" +
            "Wir sind die S.O.F.A. GmbH, eine Full-Service Eventagentur, basiert im wunderschönen Schleswig-Holstein.\n" +
            "Kundenorientiert, kreativ und flexibel. Diese drei Eigenschaften begleiten uns bereits seit 1993 und sind Teil eines Leitbildes, welches uns nun schon fast 30 Jahre ausmacht.\n" +
            "Als Full-Service Dienstleister im Bereich Konzeption, Planung und Durchführung begleiten wir sehr erfolgreich europaweit Firmen, Städte und Einzelpersonen auf Ihren Veranstaltungen. Ob Firmenfeiern, Weihnachtsmärkte, Stadtfeste oder Animation, wir sind für jeden Spaß zu haben!\n" +
            "Lassen auch Sie sich verzaubern und machen Sie Ihre Veranstaltung zu einem absoluten Highlight mit der S.O.F.A. GmbH!",
        backgroundImage: "/img/zweigstellen/segeberg.jpg",
        color: "#0093dd",
        url: "/zweigstelle/bad-segeberg",
        tel: "04551 9994727",
        email: "Genz@sofa-gmbh.de",
        strase: "Birkenring 18",
        ort: "23795 Bad Segeberg",
    }
]