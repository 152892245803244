import React from "react";
import {Button, Card, CardActions} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import {PUBLIC_CND_BASEURL} from "../App";

function KatalogCard({thumbnail, redirect, buttonText}) {
    const useStyles = makeStyles({
        root: {
            maxWidth: "100%",
            minHeight: "300px",
            marginBottom: "50px",
        },
    });

    const classes = useStyles();

    // womöglich: https://fliphtml5.com/login.php?register
    // falls die das umblättern haben wollen.

    return (
        <>
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        image={PUBLIC_CND_BASEURL + thumbnail}
                        onClick={() => window.open(redirect, "_blank")}
                        alt={"Kein Bild erhalten"}
                    />
                </CardActionArea>
                <CardActions>
                    <a href={redirect} target={"_blank"} style={{textDecoration: "none"}}>
                        <Button size="large" color="primary">
                            {buttonText}
                        </Button>
                    </a>
                </CardActions>
            </Card>
        </>
    )
}

export default KatalogCard