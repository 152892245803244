import React, {useState} from "react";

function ReadMore({children, style}) {
    const text = children;
    const isReadMore = text.length > 75;
    const [readingMore, setReadingMore] = useState(false);
    const toggleReadMore = () => {
        setReadingMore(!readingMore);
    };

    return (
        <>
            {isReadMore && !readingMore ? <p style={style}>{text.slice(0, 75)}</p> : <p style={style}>{text}</p>}
            {isReadMore && !readingMore ? "..." : ""}
            {isReadMore &&
            <span onClick={toggleReadMore} style={{cursor: "pointer"}} className="read-or-hide">
                            {!readingMore ? "mehr" : "weniger"}
            </span>
            }
        </>
    )
}

export default ReadMore