import React, {useEffect, useState} from "react";
import {pageAnimation} from "../animation";
import ScrollTop from "../components/ScrollTop";
import {motion} from "framer-motion";
import Block from "../components/Block";
import {verleihData} from "../verleihData";
import PicGalery from "../components/PicGalery";
import {useLocation} from "react-router-dom";

function Verleihung() {
    const location = useLocation();
    const url = location.pathname;
    const [Verleihungen, setVerleihungen] = useState(verleihData);
    const [verleihung, setVerleihung] = useState(null);

    useEffect(() => {
        const currentVerleihung = Verleihungen.filter((verleihung) => verleihung.url === url);
        setVerleihung(currentVerleihung[0]);
        console.log(setVerleihungen);
    }, [Verleihungen, url]);

    return (
        <>
            <motion.div
                exit="exit"
                variants={pageAnimation}
                initial="hidden"
                animate="show"
            >
                {verleihung && (
                    <>
                        <Block blockWidth={"75%"} title={verleihung.title}
                               desc={verleihung.desc}/>
                        <PicGalery pictures={verleihung.pictures} cols={3}/>
                    </>
                )}
            </motion.div>
            <ScrollTop/>
        </>
    )
}

export default Verleihung