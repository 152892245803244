import React from "react";
import styled from "styled-components";
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import {Paper} from "@material-ui/core";
import {PUBLIC_CND_BASEURL} from "../App";

function PersonCard({img, name, stelle, bereich, tel, mail}) {
    return (
        <>
            <Holder>
                <div className={"container"}>
                    <div className={"img"}>
                        <Paper elevation={20}>
                            <img src={PUBLIC_CND_BASEURL + img} alt={name} title={name}/>
                        </Paper>
                    </div>
                    <div className={"content"}>
                        <p className={"noPadding"} style={{fontSize: "1.75rem", fontWeight: "bold"}}>{name}</p>
                        <p className={"noPadding"} style={{fontStyle: "italic"}}>{stelle}</p>
                        <br/><br/>
                        <p className={"noPadding"} style={{fontSize: "16px"}}>{bereich}</p>

                        <Linking icon={<CallIcon fontSize={"large"}/>} content={tel} href={`tel:${tel}`}/>
                        <Linking icon={<EmailIcon fontSize={"large"}/>} content={mail} href={`mailto:${mail}`}/>
                    </div>
                </div>
            </Holder>
        </>
    )
}

export function Linking({icon, href, content}) {
    return (
        <>
            <p className={"noPadding"} style={{display: "flex"}}>
                {icon}
                <a href={href} style={{marginLeft: "10px"}}>
                    <p className={"noPadding"}>
                        {content}
                    </p>
                </a>
            </p>
        </>
    )
}


const Holder = styled.div`
  padding-left: 1.5rem;
  padding-bottom: 1.5rem;
  // width: 25%;
  flex: 1 1 25%;
  position: relative;
  top: 0;
  left: 0;
  vertical-align: top;
  max-width: 33%;

  @media (min-width: 2000px){
    max-width: 20%;
  }
  
  .container {
    height: 100%;
    position: relative;
    z-index: 1;

    &::before {
      position: absolute;
      content: "";
      display: block;
      top: 20px;
      right: 0;
      bottom: 0;
      left: 20px;
      background: var(--main-bg-color-sec);
      z-index: -1;
    }

    .img {
      max-width: 100%;
      position: relative;
      padding-right: 20px;

      img {
        display: block;
        max-width: 100%;
        height: auto;
      }
    }

    .content {
      background: transparent;
      margin-right: 0;
      min-height: 240px;
      right: 20px;
      position: relative;
      left: 0;
      width: 100%;
      border-left: 20px solid var(--main-bg-color);
      padding: 30px 30px 30px 30px;
      padding: 1.8rem 1.8rem 1.8rem 1.8rem;
      z-index: -1;

      .noPadding {
        padding: 0;

        a {
          text-decoration: none;
        }
      }
    }
  }
`

export default PersonCard