import React from "react";
import { motion } from "framer-motion";
import { pageAnimation } from "../animation";
import ScrollTop from "../components/ScrollTop";
import styled from "styled-components";
import { Box, useMediaQuery } from "@material-ui/core";

function Impressum() {
    const matches = useMediaQuery('(max-width:800px)');

    return (
        <>
            <motion.div
                exit="exit"
                variants={pageAnimation}
                initial="hidden"
                animate="show"
            >
                <Box width={!matches ? "50%" : "100%"} margin={"0 auto"} textAlign="center">
                    <h2>Impressum</h2>
                    <br />
                    <LittleHeading>Angaben gemäß § 5 TMG</LittleHeading>
                    <p>S.O.F.A. GmbH<br />
                        Lothar Genz<br />
                        Birkenring 18<br />
                        23795 Bad Segeberg</p>

                    <LittleHeading>Kontakt</LittleHeading>
                    <p>Telefon: 04551 - 9994727<br />
                        E-Mail: info@sofa-gmbh.de</p>

                    <LittleHeading>Registereintrag</LittleHeading>
                    <div style={{ width: "100%", justifyContent: "center", display: "flex" }}>
                        <p>
                            <table>
                                <tr>
                                    <td>Eintragung im Registergericht: </td>
                                    <td>Kiel</td>
                                </tr>
                                <tr>
                                    <td>Registernummer: </td>
                                    <td>HR B 26090</td>
                                </tr>
                                <tr>
                                    <td>Steuernummer: </td>
                                    <td>20 297 11848</td>
                                </tr>
                                <tr>
                                    <td>Ust-Nr.: </td>
                                    <td>DE161296363</td>
                                </tr>
                            </table>
                        </p>
                    </div>
                </Box>
            </motion.div>
            <ScrollTop />
        </>
    )
}

const LittleHeading = styled.strong`
font-size: 20px;
color: var(--text-color);
`

export default Impressum