export const veranstaltungData = [
    {
        title: "Mottoparty",
        url: "/veranstaltung/mottoparty",
        types: [
            {
                showLike: "Nostalgischer Jahrmarkt",
                thumbnail: "/img/nostalgischerjahrmarkt/thumbnail.JPG",
                descTitle: ".",
                desc: "Machen Sie mit uns eine Zeitreise auf einen nostalgischen Jahrmarkt. Durch unsere originellen Buden und Spielgeräte zaubern wir Ihnen ein ganz besonderes Flair. Sie können sich von unseren drei vorgefertigten Varianten inspirieren lassen oder sich Ihr ganz persönliches Paket selbst zusammenstellen.",
                variants: [
                    {
                        title: "Variante Small",
                        points: [
                            "Marktstand Dosenwerfen",
                            "Marktstand Heißer Draht",
                            "Marktstand Popcorn",
                            "Marktstand schokofrüchte",
                            "Zuckerwatte",
                            "Hau den Lukas",
                            "Dekoration",
                            "Personal im passenden Outfit",
                        ],
                    },
                    {
                        title: "Variante Medium",
                        points: [
                            "Small +",
                            "Nostalgischer Zaun",
                            "Eingangsbogen",
                            "Holzbrett Gestaltung",
                            "Marktstand mit Gebäck",
                            "Nostalgischer Musik",
                        ],
                    },
                    {
                        title: "Variante Large",
                        points: [
                            "Medium +",
                            "Pferderennbahn",
                            "Nostalgisches Karussell",
                            "Kleines Cafe",
                            "Nostalgischer Bierwagen",
                            "Bimmelbahn",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/nostalgischerjahrmarkt/pic (1).JPG',
                    },
                    {
                        src: '/img/nostalgischerjahrmarkt/pic (2).JPG',
                    },
                    {
                        src: '/img/nostalgischerjahrmarkt/pic (3).jpg',
                    },
                    {
                        src: '/img/nostalgischerjahrmarkt/pic (4).JPG',
                    },
                    {
                        src: '/img/nostalgischerjahrmarkt/pic (5).jpg',
                    },
                ],
            },
            {
                showLike: "Oktoberfest",
                thumbnail: "/img/oktoberfest/thumbnail.jpg",
                descTitle: ".",
                desc: "O’ zapft is! Jedes Jahr findet in München das weltweit größte Volksfest statt. Die Rede ist vom Oktoberfest, welches von Jahr zu Jahr beliebter wird. Wieso also das Oktoberfest nicht auch im Kleinen mit Freunden, Familie oder den Kollegen feiern?",
                variants: [
                    {
                        title: "Variante Small",
                        points: [
                            "Hut-Geweih-Wurf",
                            "Baumstammsägen",
                            "Maßkrugstemmen",
                            "Milchkübel werfen",
                            "Maßkrug schieben",
                            "Hau den Lukas",
                            "Kuh Wettmelken",
                            "Nagelschlagen",
                            "Dosenwerfen",
                            "Holzschuhrennen",
                            "Brenzschnappen",
                            "Dekoration",
                            "Personal im passenden Outfit",
                        ],
                    },
                    {
                        title: "Variante Medium",
                        points: [
                            "Small +",
                            "Bierzeit",
                            "Bierzeltgarnitur",
                            "Bierzapfanlage",
                            "Tresen",
                            "Bayerische Musik",
                            "Rodeo",
                            "Hüpfburg Kuh",
                            "Dartspiel",
                            "Fressendes Schwein",
                        ],
                    },
                    {
                        title: "Variante Large",
                        points: [
                            "Medium +",
                            "Marktstände",
                            "Brezel",
                            "Weißwurst",
                            "Leberkäse",
                            "Lebkuchen selbst gestalten",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/oktoberfest/pic (2).JPG',
                    },
                    {
                        src: '/img/oktoberfest/pic (3).JPG',
                    },
                    {
                        src: '/img/oktoberfest/pic (4).jpg',
                    },
                    {
                        src: '/img/oktoberfest/pic (5).jpg',
                    },
                ],
            },
            {
                showLike: "Piraten",
                thumbnail: "/img/piratenfest/thumbnail.jpg",
                descTitle: ".",
                desc: "“Yo ho… und’ ne Buddel voll Rum”. Hissen Sie die Piratenflagge und feiern sie eine ausgelassene Party in aufregender Piraten-Atmosphäre. Wir gestalten für Sie ein Piratenfest, welches kein spannendes Klischee auslässt.",
                variants: [
                    {
                        title: "Variante Small",
                        points: [
                            "Schatzsuche",
                            "Kostüm basteln",
                            "Piratenpass absolvieren",
                            "Haileiter",
                            "Neptuntaufe",
                            "Hüpfburg Leuchtturm",
                            "Dekoration",
                            "Personal im passenden Outfit",
                        ],
                    },
                    {
                        title: "Variante Medium",
                        points: [
                            "Small +",
                            "Hüpfburg Piratenschiff",
                            "Piratennetz",
                            "Schießstand",
                            "Kokosnuss-Fangspiel",
                            "Palmenklettern",
                        ],
                    },
                    {
                        title: "Variante Large",
                        points: [
                            "Medium +",
                            "Cocktailbar",
                            "Hängematten",
                            "Liegestühle",
                            "Swimmingpool",
                            "Finger Food",
                            "Wasser Zorbs",
                            "Schaukelschiff",
                            "Hüpfburg fressender Hai",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/piratenfest/pic (1).JPG',
                    },
                    {
                        src: '/img/piratenfest/pic (1).png',
                    },
                    {
                        src: '/img/piratenfest/pic (2).JPG',
                    },
                    {
                        src: '/img/piratenfest/pic (3).JPG',
                    },
                    {
                        src: '/img/piratenfest/pic (4).JPG',
                    },
                    {
                        src: '/img/piratenfest/pic (5).JPG',
                    },
                ],
            },
            {
                showLike: "Wild West",
                thumbnail: "/img/wildwest/thumbnail.jpg",
                descTitle: ".",
                desc: "Howdy Partner! Holstert die Pistolen und sattelt eure Pferde. Erleben Sie mit Ihren Gästen einen Tag wie aus einem guten Western Film. Wir verwandeln Ihre Party Location in eine staubige Prärie.",
                variants: [
                    {
                        title: "Variante Small",
                        points: [
                            "Rodeo Bulle",
                            "Melkkuh",
                            "Hufeisenwerfen",
                            "Popcornmaschine",
                            "Bogenschießen",
                            "Stochfangspiel",
                            "Hau den Lukas",
                            "Lassowerfen",
                            "Dekoration",
                            "Personal im passenden Outfit",
                        ],
                    },
                    {
                        title: "Variante Medium",
                        points: [
                            "Small +",
                            "Schießstand",
                            "Goldwaschen",
                            "Laufpferde",
                            "Hüpfburg Cowboy",
                            "Fotobox",
                            "Western Bar",
                        ],
                    },
                    {
                        title: "Variante Large",
                        points: [
                            "Medium +",
                            "Country Musik",
                            "Tipi Zelt",
                            "Saloon Casino",
                            "Roulette",
                            "Black Jack",
                            "Poker",
                            "Dart",
                            "Catering",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/wildwest/pic (1).jpg',
                    },
                    {
                        src: '/img/wildwest/pic (2).JPG',
                    },
                    {
                        src: '/img/wildwest/pic (3).jpg',
                    },
                    {
                        src: '/img/wildwest/pic (4).jpg',
                    },
                    {
                        src: '/img/wildwest/pic (5).jpg',
                    },
                    {
                        src: '/img/wildwest/pic (6).jpg',
                    },
                ],
            },
            {
                showLike: "Winterzauber",
                thumbnail: "/img/winterzauber/thumbnail.jpg",
                descTitle: ".",
                desc: "Das Leben einer Eisprinzessin hautnah miterleben. Das können Sie mit unseren wunderschönen Winterlandschaften. Mit den verschiedensten Geräten, dem Personal im passenden Outfit und der traumhaften Dekoration gelingt Ihnen eine Veranstaltung ganz im Zeichen des Winterzaubers.",
                variants: [
                    {
                        title: "Variante Small",
                        points: [
                            "Rentier Rodeo",
                            "1x Hüpfburg nach Wahl",
                            "Skisimulator",
                            "Schneemann Wrestling",
                            "Winterhütte",
                            "Eishockey Shoot Out",
                            "Pinguin-Rennen",
                            "Dekoration",
                            "Personal im passenden Outfit",

                        ],
                    },
                    {
                        title: "Variante Medium",
                        points: [
                            "Small +",
                            "Biathlon Simulator",
                            "Eisstockbahn",
                            "Rodelbahn",
                            "Klettereisberg",
                            "Iglu",
                            "Winterliches Mobiliar",
                            "Bar in Eis-Optik",
                            "Snowboard Simulator",
                            "Musik",

                        ],
                    },
                    {
                        title: "Variante Large",
                        points: [
                            "Medium +",
                            "Klettereiswand",
                            "Walking Acts",
                            "Weihnachtsmarktstände",
                            "Schneemaschine",
                            "Catering",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/winterzauber/pic (1).JPG',
                    },
                    {
                        src: '/img/winterzauber/pic (2).jpg',
                    },
                    {
                        src: '/img/winterzauber/pic (3).jpg',
                    },
                    {
                        src: '/img/winterzauber/pic (4).jpg',
                    },
                    {
                        src: '/img/winterzauber/pic (5).jpg',
                    },
                ],
            },
        ]
    },
    {
        title: "Spielmobile",
        url: "/veranstaltung/spielmobile",
        types: [
            {
                showLike: "Mini",
                thumbnail: "/img/spielmobile/mini/thumbnail.jpg",
                descTitle: ".",
                desc: "",
                variants: [
                    {
                        title: "Extragerät:",
                        points: [
                            "-",
                        ],
                    },
                    {
                        title: "Hüpfburg:",
                        points: [
                            "Lila Launeburg",
                        ],
                    },
                    {
                        title: "Grundausstattung:",
                        points: [
                            "XXL Menschen ärgere dich nicht",
                            "Bobby Cars",
                            "XXL Lego Baustelle",
                            "Spielteppich",
                            "XXL 4 Gewinnt",
                            "Lustige Hüpfbälle & Tiere",
                            "Stelzen",
                            "Pedalos",
                            "und natürlich Kinderschminken!",
                        ],
                    },
                    {
                        title: "Personal:",
                        points: [
                            "1 Person",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/spielmobile/mini/pic (1).jpg',
                    },
                    {
                        src: '/img/spielmobile/mini/pic (2).jpg',
                    },
                    {
                        src: '/img/spielmobile/mini/pic (4).jpg',
                    },
                    {
                        src: '/img/spielmobile/mini/pic (5).jpg',
                    },
                ],
            },
            {
                showLike: "Mittel",
                thumbnail: "/img/spielmobile/mittel/thumbnail.jpg",
                descTitle: ".",
                desc: "",
                variants: [
                    {
                        title: "Extragerät:",
                        points: [
                            "Dosen werfen",
                            "Kinder Hau den Lukas",
                        ],
                    },
                    {
                        title: "Hüpfburg:",
                        points: [
                            "Big Circus",
                        ],
                    },
                    {
                        title: "Grundausstattung:",
                        points: [
                            "XXL Menschen ärgere dich nicht",
                            "Bobby Cars",
                            "XXL Lego Baustelle",
                            "Spielteppich",
                            "XXL 4 Gewinnt",
                            "Lustige Hüpfbälle & Tiere",
                            "Stelzen",
                            "Pedalos",
                            "und natürlich Kinderschminken!",
                        ],
                    },
                    {
                        title: "Personal:",
                        points: [
                            "2 Person",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/spielmobile/mittel/pic (1).jpg',
                    },
                    {
                        src: '/img/spielmobile/mittel/pic (2).jpg',
                    },
                    {
                        src: '/img/spielmobile/mittel/pic (3).jpg',
                    },
                    {
                        src: '/img/spielmobile/mittel/pic (4).jpg',
                    },
                    {
                        src: '/img/spielmobile/mittel/pic (5).jpg',
                    },
                ],
            },
            {
                showLike: "Mega",
                thumbnail: "/img/spielmobile/mega/thumbnail.jpg",
                descTitle: ".",
                desc: "",
                variants: [
                    {
                        title: "Extragerät:",
                        points: [
                            "Rodeo Bulle",
                            "Spieltafeln",
                            "Rollbahn",
                        ],
                    },
                    {
                        title: "Hüpfburg:",
                        points: [
                            "Villa Kunterbunt",
                        ],
                    },
                    {
                        title: "Grundausstattung:",
                        points: [
                            "XXL Menschen ärgere dich nicht",
                            "Bobby Cars",
                            "XXL Lego Baustelle",
                            "Spielteppich",
                            "XXL 4 Gewinnt",
                            "Lustige Hüpfbälle & Tiere",
                            "Stelzen",
                            "Pedalos",
                            "und natürlich Kinderschminken!",
                        ],
                    },
                    {
                        title: "Personal:",
                        points: [
                            "3 Person",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/spielmobile/mega/pic (1).JPG',
                    },
                    {
                        src: '/img/spielmobile/mega/pic (2).jpg',
                    },
                    {
                        src: '/img/spielmobile/mega/pic (3).jpg',
                    },
                    {
                        src: '/img/spielmobile/mega/pic (4).jpg',
                    },
                    {
                        src: '/img/spielmobile/mega/pic (5).JPG',
                    },
                ],
            },
            {
                showLike: "Kreativ",
                thumbnail: "/img/spielmobile/kreativ/thumbnail.jpg",
                descTitle: ".",
                desc: "",
                variants: [
                    {
                        title: "Extragerät:",
                        points: [
                            "Heißer Draht",
                            "Bastelstation",
                            "Glitzertattoos",
                        ],
                    },
                    {
                        title: "Hüpfburg:",
                        points: [
                            "Villa Kunterbunt",
                        ],
                    },
                    {
                        title: "Grundausstattung:",
                        points: [
                            "XXL Menschen ärgere dich nicht",
                            "Bobby Cars",
                            "XXL Lego Baustelle",
                            "Spielteppich",
                            "XXL 4 Gewinnt",
                            "Lustige Hüpfbälle & Tiere",
                            "Stelzen",
                            "Pedalos",
                            "und natürlich Kinderschminken!",
                        ],
                    },
                    {
                        title: "Personal:",
                        points: [
                            "2 Person",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/spielmobile/kreativ/pic (1).jpg',
                    },
                    {
                        src: '/img/spielmobile/kreativ/pic (2).jpg',
                    },
                    {
                        src: '/img/spielmobile/kreativ/pic (3).jpg',
                    },
                    {
                        src: '/img/spielmobile/kreativ/pic (4).jpg',
                    },
                    {
                        src: '/img/spielmobile/kreativ/pic (5).jpg',
                    },
                ],
            },
            {
                showLike: "Sport",
                thumbnail: "/img/spielmobile/sport/thumbnail.jpg",
                descTitle: ".",
                desc: "",
                variants: [
                    {
                        title: "Extragerät:",
                        points: [
                            "Fußball-Dart",
                            "Kindertorwand",
                        ],
                    },
                    {
                        title: "Hüpfburg:",
                        points: [
                            "Villa Kunterbunt",
                        ],
                    },
                    {
                        title: "Grundausstattung:",
                        points: [
                            "XXL Menschen ärgere dich nicht",
                            "Bobby Cars",
                            "XXL Lego Baustelle",
                            "Spielteppich",
                            "XXL 4 Gewinnt",
                            "Lustige Hüpfbälle & Tiere",
                            "Stelzen",
                            "Pedalos",
                            "und natürlich Kinderschminken!",
                        ],
                    },
                    {
                        title: "Personal:",
                        points: [
                            "2 Person",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/spielmobile/sport/pic (1).jpg',
                    },
                    {
                        src: '/img/spielmobile/sport/pic (2).jpg',
                    },
                    {
                        src: '/img/spielmobile/sport/pic (3).jpg',
                    },
                    {
                        src: '/img/spielmobile/sport/pic (4).jpg',
                    },
                    {
                        src: '/img/spielmobile/sport/pic (5).jpg',
                    },
                    {
                        src: '/img/spielmobile/sport/pic (6).jpg',
                    },
                ],
            },
            {
                showLike: "Wasser",
                thumbnail: "/img/spielmobile/wasser/thumbnail.jpg",
                descTitle: ".",
                desc: "",
                variants: [
                    {
                        title: "Extragerät:",
                        points: [
                            "Entenangeln",
                            "Kletterleuchtturm",
                        ],
                    },
                    {
                        title: "Hüpfburg:",
                        points: [
                            "Meerestraum",
                        ],
                    },
                    {
                        title: "Grundausstattung:",
                        points: [
                            "XXL Menschen ärgere dich nicht",
                            "Bobby Cars",
                            "XXL Lego Baustelle",
                            "Spielteppich",
                            "XXL 4 Gewinnt",
                            "Lustige Hüpfbälle & Tiere",
                            "Stelzen",
                            "Pedalos",
                            "und natürlich Kinderschminken!",
                        ],
                    },
                    {
                        title: "Personal:",
                        points: [
                            "2 Person",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/spielmobile/wasser/pic (1).jpg',
                    },
                    {
                        src: '/img/spielmobile/wasser/pic (2).jpg',
                    },
                    {
                        src: '/img/spielmobile/wasser/pic (3).JPG',
                    },
                    {
                        src: '/img/spielmobile/wasser/pic (4).jpg',
                    },
                    {
                        src: '/img/spielmobile/wasser/pic (5).jpg',
                    },
                ],
            },
        ]
    },
    {
        title: "Action und Sport",
        url: "/eventmodule/actionundsport",
        types: [
            {
                showLike: "Rodeos",
                thumbnail: "/img/eventmodule/rodeos/thumbnail.jpg",
                descTitle: ".",
                desc: "Mit verschiedenen Geschwindigkeitsstufen ist das Rodeo für erfahrene und unerfahrene Personen geeignet. Durch das aufblasbare Kissen kann dem Reiter nichts passieren. Der Bulle ist durch ein manuelles Stufenprogramm steuerbar.",
                variants: [
                    {
                        title: "Gilt für alle Rodeos:",
                        points: [
                            "Platzbedarf: 6 m x 7 m",
                            "Technische Daten: 230 V / 16 A",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/rodeos/pic (1).jpg',
                    },
                    {
                        src: '/img/eventmodule/rodeos/pic (2).jpg',
                    },
                    {
                        src: '/img/eventmodule/rodeos/pic (3).JPG',
                    },
                    {
                        src: '/img/eventmodule/rodeos/pic (4).jpg',
                    },
                    {
                        src: '/img/eventmodule/rodeos/pic (5).jpg',
                    },
                    {
                        src: '/img/eventmodule/rodeos/pic (6).jpg',
                    },
                    {
                        src: '/img/eventmodule/rodeos/pic (7).jpg',
                    },
                ],
            },
            {
                showLike: "Jumper",
                thumbnail: "/img/eventmodule/jumper/thumbnail.jpg",
                descTitle: ".",
                desc: "Der Jumper ermöglicht Trampolinspringen in ganz neuen Dimensionen. Die Personen werden mit Hilfe eines Gurtes an Bungeeseile gebunden und auf optimale Höhe gezogen.\n" +
                    "Durch die Seile kann man höher und sicherer springen. Der Jumper kann von 20 kg bis 80 kg Gewicht genutzt werden. Die maximale Sprunghöhe beträgt 4m und 5m.\n",
                variants: [
                    {
                        title: "Platzbedarf 4-fach Jumper",
                        points: [
                            "10 m x 10 m",
                        ],
                    },
                    {
                        title: "Technische Daten",
                        points: [
                            "230 V / 16 A",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/jumper/pic (1).JPG',
                    },
                    {
                        src: '/img/eventmodule/jumper/pic (2).jpg',
                    },
                    {
                        src: '/img/eventmodule/jumper/pic (3).JPG',
                    },
                    {
                        src: '/img/eventmodule/jumper/pic (5).JPG',
                    },
                    {
                        src: '/img/eventmodule/jumper/pic (4).jpg',
                    },
                    {
                        src: '/img/eventmodule/jumper/pic (6).JPG',
                    },
                    {
                        src: '/img/eventmodule/jumper/pic (7).JPG',
                    },
                ],
            },
            {
                showLike: "Klettern",
                thumbnail: "/img/eventmodule/klettern/thumbnail.JPG",
                descTitle: ".",
                desc: "",
                variants: [
                    {
                        title: "Kistenklettern",
                        points: [
                            "Diese besondere Art des Kletterns findet in einem 10 Meter hohen Turm statt. Der Kletterer versucht mit Hilfe von Getränkekisten bis ganz nach oben zu gelangen. Er baut sich einen Turm aus Kisten, indem er diese aufeinander stapelt. Gesichert wird er mit an einem Klettergurt und mit Seilen. Beim Kistenklettern sichert eine Person und die Andere reicht die Kisten. Durch das Netz im Turm werden die Zuschauer vor den herabstürzenden Kisten geschützt.",
                            "Platzbedarf: ca. 6 m x 6 m",
                            "Technische Daten: 230 V / 16 A",
                        ],
                    },
                    {
                        title: "Kletter Eiswand",
                        points: [
                            "Platzbedarf: 12 m x 4 m x 10 m",
                            "Technische Daten: Keine",
                        ],
                    },
                    {
                        title: "Kletterpfahl",
                        points: [
                            "Platzbedarf: 5 m x 5 m ",
                            "Technische Daten: Keine",
                        ],
                    },
                    {
                        title: "Winterberg",
                        points: [
                            "Dieser 9 Meter hohe Kletterberg ist ganz auf den Winter abgestimmt. Die Kletterer erklimmen über die “Eisbrocken” den Gipfel, an welchem eine Glocke befestigt ist. An dieser wird geklingelt, sobald man es geschafft hat.",
                            "Die Teilnehmer werden von unserem S.O.F.A. Personal mit einem Seil gesichert.",
                            "Platzbedarf: 8 m x 8 m",
                            "Technische Daten: 230 V / 16 A",
                        ],
                    },
                    {
                        title: "Palmenklettern",
                        points: [
                            "Platzbedarf: 6 m x 6 m",
                            "Technische Daten: 230 V / 16 A",
                        ],
                    },
                    {
                        title: "Kletterturm",
                        points: [
                            "Platzbedarf: 12 m x 6 m",
                            "Technische Daten: 230 V / 16 A",
                        ],
                    },
                    {
                        title: "Piratennetz",
                        points: [
                            "Platzbedarf: 6 m x 6 m",
                            "Technische Daten: Keine",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/klettern/pic (1).jpg',
                    },
                    {
                        src: '/img/eventmodule/klettern/pic (2).JPG',
                    },
                    {
                        src: '/img/eventmodule/klettern/pic (3).jpg',
                    },
                    {
                        src: '/img/eventmodule/klettern/pic (4).jpg',
                    },
                    {
                        src: '/img/eventmodule/klettern/pic (5).jpg',
                    },
                    {
                        src: '/img/eventmodule/klettern/pic (6).jpg',
                    },
                    {
                        src: '/img/eventmodule/klettern/pic (7).jpg',
                    },
                    {
                        src: '/img/eventmodule/klettern/pic (8).JPG',
                    },
                ],
            },
            {
                showLike: "Fußball",
                thumbnail: "/img/eventmodule/fußball/thumbnail.jpg",
                descTitle: ".",
                desc: "",
                variants: [
                    {
                        title: "Fußball Bouncer",
                        points: [
                            "Fußball Bouncer ist eine etwas andere Variante zum Fußball spielen. Die Spieler steigen in die aufblasbaren Bälle, bei welchen der Kopf und die Beine frei bleiben. Auf diese Weise bleiben sie unverletzt und können so in Teams gegeneinander zu einem Fußballspiel antreten. Es lassen sich auch ganze Turniere damit veranstalten.\n" +
                            "Mit dabei ist eine Umrandung mit Toren. Diese kann jedoch auch weggelassen werden, um ein größeres Spielfeld zu nutzen.\n",
                            "Platzbedarf: 10 m x 5 m",
                            "Technische Daten: 230 V / 16 A",
                        ],
                    },
                    {
                        title: "Fußball Dart",
                        points: [
                            "Platzbedarf: 2,5 m x 1,5 m",
                            "Technische Daten: 230 V / 16 A",
                        ],
                    },
                    {
                        title: "Fußball Fangen",
                        points: [
                            "Platzbedarf: 2 m x 2 m",
                            "Technische Daten: 230 V / 16 A",
                        ],
                    },
                    {
                        title: "Human Table Soccer",
                        points: [
                            "Fühlen Sie sich wie eine Figur beim Tischfußball und erleben Sie das Kickergefühl einmal anders. In dem aufblasbaren Spielfeld ist Platz für 10 Spieler. Die Mannschaften Rot und Blau versuchen den Ball ins gegnerische Tor zu schießen. Die Spieler können sich zu den Seiten hin bewegen, aber müssen in ihrer Polsterung bleiben. Es kann mit Leder-, aber auch mit Softbällen gespielt werden.",
                            "Platzbedarf:17 m x 7 m",
                            "Technische Daten: 230 V / 16 A",
                        ],
                    },
                    {
                        title: "Fußball Rodeo",
                        points: [
                            "Platzbedarf: 5 m x 5 m",
                            "Technische Daten: 230 V / 16 A",
                        ],
                    },
                    {
                        title: "Tischkicker XXL",
                        points: [
                            "Erleben Sie Tischfußball wie noch nie zuvor an unserem 3 Meter langen Tischkicker. Hier können bis zu 16 Personen gleichzeitig spielen. Der Tisch ist in verschiedenen Ausführungen erhältlich. Er eignet sich hervorragend für Gruppen und Turniere. Man kann jedoch auch mit weniger Personen an dem Tisch spielen, was den Sportfaktor durch die notwendigen Bewegung erhöht.",
                            "Platzbedarf: 3  m x 0,75 m",
                            "Technische Daten: Keine",
                        ],
                    },
                    {
                        title: "Tischkicker",
                        points: [
                            "Platzbedarf: 1,45  m x 0,75 m",
                            "Technische Daten: Keine",
                        ],
                    },
                    {
                        title: "Torwand",
                        points: [
                            "Platzbedarf: 2,7  m x 1 m",
                            "Technische Daten: Keine",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/fußball/pic (1).JPG',
                    },
                    {
                        src: '/img/eventmodule/fußball/pic (2).jpg',
                    },
                    {
                        src: '/img/eventmodule/fußball/pic (3).jpg',
                    },
                    {
                        src: '/img/eventmodule/fußball/pic (4).jpg',
                    },
                    {
                        src: '/img/eventmodule/fußball/pic (5).JPG',
                    },
                    {
                        src: '/img/eventmodule/fußball/pic (6).jpg',
                    },
                    {
                        src: '/img/eventmodule/fußball/pic (7).jpg',
                    },
                    {
                        src: '/img/eventmodule/fußball/pic (8).jpg',
                    },
                ],
            },
            {
                showLike: "Golf",
                thumbnail: "/img/eventmodule/golf/thumbnail.jpg",
                descTitle: "Golf spielen einmal anders",
                desc: "Stellen Sie Ihr Golftalent bei unseren verschiedenen Golfvarianten unter Beweis. Ein sportiver Spaß für alle Golffans.",
                variants: [
                    {
                        title: "Platzbedarf",
                        points: [
                            "kann variabel sein",
                        ],
                    },
                    {
                        title: "Technische Daten",
                        points: [
                            "Bei Golf Shoot Out und Golf Simulator: 230 V / 16 A",
                            "Die anderen Geräte benötigen keinen Strom.",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/golf/pic (1).JPG',
                    },
                    {
                        src: '/img/eventmodule/golf/pic (2).jpg',
                    },
                    {
                        src: '/img/eventmodule/golf/pic (3).jpg',
                    },
                    {
                        src: '/img/eventmodule/golf/pic (4).JPG',
                    },
                    {
                        src: '/img/eventmodule/golf/pic (5).jpg',
                    },
                    {
                        src: '/img/eventmodule/golf/pic (6).jpg',
                    },
                ],
            },
            {
                showLike: "Water World",
                thumbnail: "/img/eventmodule/waterworld/thumbnail.jpg",
                descTitle: ".",
                desc: "Egal, ob im Schwimmbad, im Badesee oder im Meer, die Water World ist immer eine mega Attraktion im Sommer!\n\nMan kann aus einem individuellen Baukasten seine Favoriten heraussuchen und sich so seine eigene Water World kreieren.",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/waterworld/pic (1).JPG',
                    },
                    {
                        src: '/img/eventmodule/waterworld/pic (2).jpg',
                    },
                    {
                        src: '/img/eventmodule/waterworld/pic (3).jpg',
                    },
                    {
                        src: '/img/eventmodule/waterworld/pic (4).jpg',
                    },
                    {
                        src: '/img/eventmodule/waterworld/pic (5).jpg',
                    },
                    {
                        src: '/img/eventmodule/waterworld/pic (6).jpg',
                    },
                    {
                        src: '/img/eventmodule/waterworld/pic (7).jpg',
                    },
                ],
            },
            {
                showLike: "Schießen",
                thumbnail: "/img/eventmodule/schießen/thumbnail.jpg",
                descTitle: ".",
                desc: "Schon seit Uhrzeiten benutzt der Menschen Schusswaffen, sei es zur Nahrungsbeschaffung oder in unerbittlichen Kämpfen.\n\nBei uns wird allerdings nur zum Spaß und zur sportlichen Betätigung geschossen!\n\nBei allen Schießvarianten ist das Bestreben, so gut wie möglich das jeweilige Ziel zu treffen.",
                variants: [
                    {
                        title: "Biathlon Anlage",
                        points: [
                            "Platzbedarf kann variieren",
                            "Technische Daten: 230 V / 16 A",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/schießen/pic (1).JPG',
                    },
                    {
                        src: '/img/eventmodule/schießen/pic (2).jpg',
                    },
                    {
                        src: '/img/eventmodule/schießen/pic (3).jpg',
                    },
                    {
                        src: '/img/eventmodule/schießen/pic (5).JPG',
                    },
                    {
                        src: '/img/eventmodule/schießen/pic (6).JPG',
                    },
                    {
                        src: '/img/eventmodule/schießen/pic (7).JPG',
                    },
                ],
            },
            {
                showLike: "Kräftemessen",
                thumbnail: "/img/eventmodule/kräftemessen/thumbnail.jpg",
                descTitle: ".",
                desc: "Erleben Sie ganz besonders ausgefallene Möglichkeiten, mit Ihren Freunden und Kollegen die Kräfte zu messen- alles natürlich vollkommen ungefährlich.\nBei allen Varianten ist man durch weiche Anzüge oder einen aufblasbaren Untergrund gut vor jeglichen Verletzungen geschützt und der Spaß kann losgehen.\n\nDabei darf natürlich auch das klassische Armdrücken nicht fehlen.",
                variants: [
                    {
                        title: "Gladiatorenkampf",
                        points: [
                            "Platzbedarf: 5 m x 5 m",
                        ],
                    },
                    {
                        title: "Sumo Wrestling",
                        points: [
                            "Platzbedarf: 5 m x 5 m",
                        ],
                    },
                    {
                        title: "Bungee Run",
                        points: [
                            "Platzbedarf: 12 m x 35 m",
                            "Technische Daten: 230 V / 16 A",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/kräftemessen/pic (1).jpg',
                    },
                    {
                        src: '/img/eventmodule/kräftemessen/pic (3).jpg',
                    },
                    {
                        src: '/img/eventmodule/kräftemessen/pic (4).jpg',
                    },
                    {
                        src: '/img/eventmodule/kräftemessen/pic (5).jpg',
                    },
                    {
                        src: '/img/eventmodule/kräftemessen/pic (6).JPG',
                    },
                    {
                        src: '/img/eventmodule/kräftemessen/pic (7).JPG',
                    },
                ],
            },
            {
                showLike: "Fun Sport",
                thumbnail: "/img/eventmodule/funsport/thumbnail.JPG",
                descTitle: ".",
                desc: "Absolvieren Sie das etwas andere Astronautentraining.\n\nUnseren Astrotrainer gibt es in zwei Varianten- entweder zum Sitzen, wie auf den Bildern zu sehen ist, oder zum Stehen.\n\nUnser Personal steuert das Gerät manuell, wodurch die Geschwindigkeit individuell anpassbar ist.\n\nDie Teilnehmer sind durch verstellbare Gurte gesichert, wodurch der Astrotrainer für Groß und Klein geeignet ist.\n\nPlatzbedarf: 4 m x 4 m\nTechnische Daten: 230 V / 16 A\n",
                variants: [
                    {
                        title: "Ringstechbahn",
                        points: [
                            "Platzbedarf: 17 m x 3 m",
                            "Technische Daten: Keine",
                        ],
                    },
                    {
                        title: "Segways",
                        points: [
                            "Platzbedarf: ca. 10 m x 10 m",
                            "Technische Daten: 220 V / 16 A ich bin ein richtig langer sehr langer text, der klickbar ist",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/funsport/pic (1).jpg',
                    },
                    {
                        src: '/img/eventmodule/funsport/pic (2).JPG',
                    },
                    {
                        src: '/img/eventmodule/funsport/pic (3).JPG',
                    },
                    {
                        src: '/img/eventmodule/funsport/pic (4).JPG',
                    },
                    {
                        src: '/img/eventmodule/funsport/pic (5).JPG',
                    },
                    {
                        src: '/img/eventmodule/funsport/pic (6).JPG',
                    },
                    {
                        src: '/img/eventmodule/funsport/pic (7).jpg',
                    },
                ],
            },
        ]
    },
    {
        title: "Kinder",
        url: "/eventmodule/kinder",
        types: [
            {
                showLike: "Hüpfbürgen",
                thumbnail: "/img/eventmodule/hüpfbürgen/thumbnail.jpg",
                descTitle: ".",
                desc: "Hüpfburgen sind auf jeder Veranstaltung ein Highlight für die Kinder. Wir haben für jedes Motto die passende Hüpfburg in unterschiedlichen Größen.\n\nAlle Hüpfburgen haben einen Strombedarf von 230 V und 16 A.",
                variants: [
                    {
                        title: "Clown",
                        points: [
                            "Platzbedarf: 5 m x 6 m",
                        ],
                    },
                    {
                        title: "Erlebniswelt",
                        points: [
                            "Platzbedarf: 3 m x 10 m",
                        ],
                    },
                    {
                        title: "Fußball",
                        points: [
                            "Platzbedarf: 4 m x 5 m",
                        ],
                    },
                    {
                        title: "Fressender Hund",
                        points: [
                            "Platzbedarf: 10 m x 10 m",
                        ],
                    },
                    {
                        title: "Lila Launeburg",
                        points: [
                            "Platzbedarf: 3 m x x m",
                        ],
                    },
                    {
                        title: "Rentier",
                        points: [
                            "Platzbedarf: 5 m x 6 m",
                        ],
                    },
                    {
                        title: "Villa Kunterbunt",
                        points: [
                            "Platzbedarf: 4 m x 5 m",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/hüpfbürgen/pic (1).jpg',
                    },
                    {
                        src: '/img/eventmodule/hüpfbürgen/pic (2).JPG',
                    },
                    {
                        src: '/img/eventmodule/hüpfbürgen/pic (3).jpg',
                    },
                    {
                        src: '/img/eventmodule/hüpfbürgen/pic (4).jpg',
                    },
                    {
                        src: '/img/eventmodule/hüpfbürgen/pic (5).jpg',
                    },
                    {
                        src: '/img/eventmodule/hüpfbürgen/pic (6).jpg',
                    },
                    {
                        src: '/img/eventmodule/hüpfbürgen/pic (7).jpg',
                    },
                ],
            },
            {
                showLike: "Spieleland",
                thumbnail: "/img/eventmodule/spieleland/thumbnail.jpg",
                descTitle: ".",
                desc: "",
                variants: [],
                pictures: [
                    {
                        src: '/img/eventmodule/spieleland/pic (1).jpg',
                    },
                    {
                        src: '/img/eventmodule/spieleland/pic (2).jpg',
                    },
                    {
                        src: '/img/eventmodule/spieleland/pic (3).JPG',
                    },
                    {
                        src: '/img/eventmodule/spieleland/pic (4).JPG',
                    },
                    {
                        src: '/img/eventmodule/spieleland/pic (5).JPG',
                    },
                    {
                        src: '/img/eventmodule/spieleland/pic (6).JPG',
                    },
                    {
                        src: '/img/eventmodule/spieleland/pic (7).jpg',
                    },
                    {
                        src: '/img/eventmodule/spieleland/pic (8).jpg',
                    },
                ],
            },
        ]
    },
    {
        title: "Spiele",
        url: "/eventmodule/spiele",
        types: [
            {
                showLike: "Jahrmarktspiele",
                thumbnail: "/img/eventmodule/jahrmarktspiele/thumbnail.jpg",
                descTitle: ".",
                desc: "Alle unsere Marktstände haben einen Platzbedarf von 2 m x 1 m.",
                variants: [
                    {
                        title: "Dosenwerfen",
                        points: [
                            "Dosenwerfen ist eines der beliebtesten Wurf- und Geschicklichkeitsspiele. Das Ziel ist es, den Turm aus Dosen mit 3 Softbällen zum Einsturz zu bringen.",
                        ],
                    },
                    {
                        title: "Dart",
                        points: [
                            "Technische Daten: 230 V / 16 A",
                        ],
                    },
                    {
                        title: "Entenangeln",
                        points: [
                            "Technische Daten: keine",
                        ],
                    },
                    {
                        title: "Hufeisenwerfen",
                        points: [
                            "Technische Daten: keine",
                        ],
                    },
                    {
                        title: "Luftballondart",
                        points: [
                            "Technische Daten: keine",
                        ],
                    },
                    {
                        title: "Pferderennbahn",
                        points: [
                            "Technische Daten: 230 V / 16 A",
                        ],
                    },
                    {
                        title: "Ringewerfen",
                        points: [
                            "Technische Daten: keine",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/jahrmarktspiele/pic (1).JPG',
                    },
                    {
                        src: '/img/eventmodule/jahrmarktspiele/pic (2).jpg',
                    },
                    {
                        src: '/img/eventmodule/jahrmarktspiele/pic (3).jpg',
                    },
                    {
                        src: '/img/eventmodule/jahrmarktspiele/pic (4).jpg',
                    },
                    {
                        src: '/img/eventmodule/jahrmarktspiele/pic (5).jpg',
                    },
                    {
                        src: '/img/eventmodule/jahrmarktspiele/pic (6).JPG',
                    },
                    {
                        src: '/img/eventmodule/jahrmarktspiele/pic (7).JPG',
                    },
                ],
            },
            {
                showLike: "Spiele Allgemein",
                thumbnail: "/img/eventmodule/spieleallgemein/thumbnail.jpg",
                descTitle: ".",
                desc: "Eine lebensgroße Kuh-Attrappe, welche mit Flüssigkeit befüllt und dann gemolken werden kann.\n" +
                    "\n" +
                    "Die Nägel werden in einen Holzbalken geschlagen. Wer in einer gewissen Zeit am meisten Nägel schlägt, hat gewonnen.\n" +
                    "\n" +
                    "Spielen Sie Schach auf einem übergroßen Spielfeld.\n" +
                    "\n" +
                    "Das klassische Twister Spiel im XXL Format. Das Kissen kann auch zum Hüpfen verwendet werden.\n",
                variants: [
                    {
                        title: "Kuh Wettmelken",
                        points: [
                            "Platzbedarf: 2 m x 0,5 m",
                            "Technische Daten: Keine",
                        ],
                    },
                    {
                        title: "Nagelschlagen",
                        points: [
                            "Platzbedarf: 3 m x 1 m",
                            "Technische Daten: Keine",
                        ],
                    },
                    {
                        title: "XXL Schach",
                        points: [
                            "Platzbedarf: 3 m x 3 m",
                            "Technische Daten: Keine",
                        ],
                    },
                    {
                        title: "XXL Twister",
                        points: [
                            "Platzbedarf: 5 m x 5 m",
                            "Technische Daten: 230 V / 16 A",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/spieleallgemein/pic (1).jpg',
                    },
                    {
                        src: '/img/eventmodule/spieleallgemein/pic (2).jpg',
                    },
                    {
                        src: '/img/eventmodule/spieleallgemein/pic (3).JPG',
                    },
                    {
                        src: '/img/eventmodule/spieleallgemein/pic (4).jpg',
                    },
                    {
                        src: '/img/eventmodule/spieleallgemein/pic (5).jpg',
                    },
                    {
                        src: '/img/eventmodule/spieleallgemein/pic (6).JPG',
                    },
                    {
                        src: '/img/eventmodule/spieleallgemein/pic (7).jpg',
                    },
                    {
                        src: '/img/eventmodule/spieleallgemein/pic (8).jpg',
                    },
                    {
                        src: '/img/eventmodule/spieleallgemein/pic (9).JPG',
                    },
                ],
            },
        ]
    },
    {
        title: "Schlittschuhbahn",
        url: "/eventmodule/schlittschuhbahn",
        types: [
            {
                showLike: "Kunststoffschlittschuhbahn",
                thumbnail: "/img/eventmodule/schlittschuhbahn/bahn/thumbnail.jpg",
                descTitle: ".",
                desc: "Unsere Kunststoff-Schlittschuhbahn ist außergewöhnlich und zählt zu den Highlights auf jeder Veranstaltung. Das Besondere an unserer Schlittschuhbahn ist, dass sie aus Kunststoff besteht und somit komplett ohne Eis betrieben wird.\n" +
                    "Sie ist ideal geeignet für Weihnachtsmärkte, Firmenveranstalttungen, oder auch für private Feste und mit unserem Winterzauber Angebot gestalten wir für Sie eine unvergessliche Winterlandschaft.\n",
                variants: [
                    {
                        title: "Vorteile",
                        points: [
                            "Ganzjährig nutzbar",
                            "Witterungsunabhängig",
                            "Keine Energiekosten",
                            "Keine Wasserkosten",
                            "Ökologisch und preisgünstig",
                        ],
                    },
                    {
                        title: "Bandensysteme",
                        points: [
                            "Holz oder Kunststoff Bandensysteme",
                            "Offene oder geschlossene Systeme",
                            "Mögliche Nutzung für Werbefläche",
                            "Höhe einer Bande - 0,85 m",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/schlittschuhbahn/bahn/pic (1).jpg',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/bahn/pic (2).jpg',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/bahn/pic (3).JPG',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/bahn/pic (4).JPG',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/bahn/pic (5).JPG',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/bahn/pic (6).jpg',
                    },
                ],
            },
            {
                showLike: "Eisstockbahn",
                thumbnail: "/img/eventmodule/schlittschuhbahn/eisstockbahn/thumbnail.jpg",
                descTitle: ".",
                desc: "Eisstockschießen ist ein Sport, der nicht nur den Profis Spaß macht. Dank einfacher Regeln eignet es sich bestens, um auf Firmenfeiern oder Stadtfesten angeboten zu werden und ist ein Freizeitspaß für Jung und Alt.\n" +
                    "Es besteht aus Kunststoff wie die Schlittschuhbahn: 10 Meter lang und 2 Meter bereit.\n",
                variants: [
                    {
                        title: "",
                        points: [
                            "der Untergrund sollte eben und plan sein",
                            "ruck zuck aufgebaut",
                            "eisähnliche Spieleigenschaften",
                            "einzeln und in Gruppen bespielbar",
                            "im Komplettpaket inkl. Eisstöcker und Personal"
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/schlittschuhbahn/eisstockbahn/pic (1).jpg',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/eisstockbahn/pic (2).jpg',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/eisstockbahn/pic (3).JPG',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/eisstockbahn/pic (4).jpg',
                    },
                ],
            },
            {
                showLike: "winterliche Attraktionen",
                thumbnail: "/img/eventmodule/schlittschuhbahn/attraktionen/thumbnail.JPG",
                descTitle: ".", //                                              url:/verleih/attraktionen
                desc: "Wir gestalten für Sie eine unvergessliche Winterlandschaft",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/schlittschuhbahn/attraktionen/pic (1).jpg',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/attraktionen/pic (2).jpg',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/attraktionen/pic (3).jpg',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/attraktionen/pic (4).jpg',
                    },
                ],
            },
            {
                showLike: "Zubehör",
                thumbnail: "/img/eventmodule/schlittschuhbahn/einkaufszentren/thumbnail.jpg",
                descTitle: ".",
                desc: "Wir bieten eine Vielzahl von Zubehör für die Schlittschuhbahn an.",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/schlittschuhbahn/einkaufszentren/pic (1).jpg',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/einkaufszentren/pic (2).jpg',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/einkaufszentren/pic (3).JPG',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/einkaufszentren/pic (4).JPG',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/einkaufszentren/pic (5).jpg',
                    },
                ],
            },
            {
                showLike: "Rodelbahn",
                thumbnail: "/img/eventmodule/schlittschuhbahn/rodelbahn/thumbnail.jpg",
                descTitle: ".",
                desc: "Die mobile Rodelbahn ist ein Anziehungsmagnet auf allen Veranstaltungen. Speziell für die kalte Jahreszeit präsentiert sich die Bahn im winterlichen Design mit weißer Piste und passenden Dekobannern. Ein Eventmodul für das ganze Jahr. Ob im Sommer als Dschungelrafting oder im Winter als Rodelbahn. Ab ca. 2 Jahren können die Kleinsten schon mit ihren großen Geschwistern oder Jugendliche erleben den rasanten Rodelspaß pur und unter Applaus der Kinder fahren die Mamas und die Papas.",
                variants: [
                    {
                        title: "",
                        points: [
                            "Platzbedarf ca 30m x 6m",
                            "schneller Aufbau",
                            "Möglichkeit zum Anbringen von Werbebannern",
                            "durch den speziellen Skipistenbelag erreicht man hohe Geschwindigkeiten",
                            "jede Fahrt ist ein Erlebnis",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/schlittschuhbahn/rodelbahn/pic (1).jpg',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/rodelbahn/pic (2).jpg',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/rodelbahn/pic (3).jpg',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/rodelbahn/pic (4).JPG',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/rodelbahn/pic (5).jpg',
                    },
                ],
            },
            {
                showLike: "Winterzauber",
                thumbnail: "/img/eventmodule/schlittschuhbahn/winterzauber/thumbnail.jpg",
                descTitle: ".",
                desc: "Wir verwandeln euch die verschiedensten Locations in eine wunderschöne Winterlandschaft. Mit schöner Dekoration, Geräten in Winterpolitik und passend angezogenem Personal, erschaffen wir einen einzigartigen Winterzauber.",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/schlittschuhbahn/winterzauber/pic (1).jpg',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/winterzauber/pic (2).jpg',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/winterzauber/pic (3).jpg',
                    },
                    {
                        src: '/img/eventmodule/schlittschuhbahn/winterzauber/pic (4).jpg',
                    },
                ],
            },
        ]
    },
    {
        title: "Motto",
        url: "/veranstaltung/motto",
        types: [
            {
                showLike: "Wild West Abend",
                thumbnail: "/img/incentives/wildwest/thumbnail.jpg",
                descTitle: "Allgemeiner Titel",
                desc: "Allgemeine Beschreibung, die ruhig länger als das hier sein darf :)",
                variants: [],
                pictures: [
                    {
                        src: '/img/incentives/wildwest/pic (1).jpg',
                    },
                ],
            },
            {
                showLike: "Oktoberfest",
                thumbnail: "/img/incentives/oktoberfest/thumbnail.jpg",
                descTitle: "Allgemeiner Titel",
                desc: "Allgemeine Beschreibung, die ruhig länger als das hier sein darf :)",
                variants: [],
                pictures: [
                    {
                        src: '/img/incentives/oktoberfest/pic (1).jpg',
                    },
                ],
            },
            {
                showLike: "Cirque du S.O.F.A.",
                thumbnail: "/img/incentives/cirque/thumbnail.jpg",
                descTitle: "Allgemeiner Titel",
                desc: "Allgemeine Beschreibung, die ruhig länger als das hier sein darf :)",
                variants: [],
                pictures: [
                    {
                        src: '/img/incentives/cirque/pic (1).jpg',
                    },
                ],
            },
            {
                showLike: "Winterzauber",
                thumbnail: "/img/incentives/winterzauber/thumbnail.jpg",
                descTitle: "Allgemeiner Titel",
                desc: "Allgemeine Beschreibung, die ruhig länger als das hier sein darf :)",
                variants: [],
                pictures: [
                    {
                        src: '/img/incentives/winterzauber/pic (1).jpg',
                    },
                ],
            },
        ]
    },
    {
        title: "Firmenevents",
        url: "/veranstaltung/firmenevents",
        types: [
            {
                showLike: "Beach Games",
                thumbnail: "/img/incentives/beachgames/thumbnail.JPG",
                descTitle: ".",
                desc: "Bei unseren Beach Games ist Bewegung im Spiel! Sobald die Teams  bereit sind und alles erklärt wurde, geht es gemeinsam an den Strand. Dort sind bereits spannende Aktivstationen aufgebaut, welche die Teamfähigkeit der Kollegen auf die Probe stellen und das Zusammengehörigkeitsgefühl stärken.",
                variants: [
                    {
                        title: "Mögliche Stationen",
                        points: [
                            "Boccia",
                            "Beachvolleyball",
                            "Strandski",
                            "Luftmatratzen Rallye",
                            "Wissensquiz",
                            "Limbo.",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/incentives/beachgames/pic (1).jpg',
                    },
                    {
                        src: '/img/incentives/beachgames/pic (2).JPG',
                    },
                    {
                        src: '/img/incentives/beachgames/pic (3).JPG',
                    },
                    {
                        src: '/img/incentives/beachgames/pic (4).JPG',
                    },
                    {
                        src: '/img/incentives/beachgames/pic (5).JPG',
                    },
                ],
            },
            {
                showLike: "Firmenfeier am Kalkberg im Indian Village",
                thumbnail: "/img/incentives/firmenfeierkalkberg/thumbnail.jpg",
                descTitle: ".",
                desc: "Machen Sie mit uns eine Zeitreise ins Indian Village. Tauchen Sie an diesem besonderen Ort mit uns in die Welt der Indianer ein.\nDas Indian Village liegt neben dem Freilichttheater am Kalkberg, wo jedes Jahr die bekannten Karl-May-Spiele stattfinden.",
                variants: [
                    {
                        title: "Ablauf",
                        points: [
                            "Treffen und Spiele",
                            "Besichtigung der Kalkhöhle",
                            "Ausstellung „Die Welt der Indianer“ im „Nebraska-Haus“",
                            "Vorstellung (30 Minuten vorher am Platz sein)",
                        ],
                    },
                    {
                        title: "Stationen Western Olympiade:",
                        points: [
                            "Rodeo",
                            "Bogenschießen",
                            "Schnäpse raten",
                            "Lassowerfen",
                            "Kuhwettmelken",
                            "Hufeisen werfen",
                        ],
                    }
                ],
                pictures: [
                    {
                        src: '/img/incentives/firmenfeierkalkberg/pic (1).jpg',
                    },
                    {
                        src: '/img/incentives/firmenfeierkalkberg/pic (2).jpg',
                    },
                    {
                        src: '/img/incentives/firmenfeierkalkberg/pic (3).JPG',
                    },
                    {
                        src: '/img/incentives/firmenfeierkalkberg/pic (4).JPG',
                    },
                    {
                        src: '/img/incentives/firmenfeierkalkberg/pic (5).jpg',
                    },
                    {
                        src: '/img/incentives/firmenfeierkalkberg/pic (6).jpg',
                    },
                ],
            },
            {
                showLike: "Piratenfest",
                thumbnail: "/img/incentives/piratenfest/thumbnail.JPG",
                descTitle: "Herzlich willkommen im Piratenfest – bei Ihrer nächsten Firmenfeier!",
                desc: "Hissen Sie die Piratenflagge und feiern Sie eine ausgelassene Party mit passender Dekoration, Equipment und Kostüme. Aufregende Piraten-Atmosphäre werden Sie und Ihre Gäste in eine Welt voller Abenteuer entführen.\nUnd für den perfekten Abschluss Ihrer Firmenfeier können Sie einen Piratenpass absolvieren.\n" +
                    "Buchen Sie jetzt Ihre Firmenfeier im Piratenfest und erleben Sie eine unvergessliche Zeitreise in einer anderen Welt.",
                variants: [
                    {
                        title: "Freuen Sie sich auf ein spannendes Programm:",
                        points: [
                            "Bogenschießen",
                            "Haileiter",
                            "Tau ziehen",
                            "Säbel fangen"
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/incentives/piratenfest/pic (1).JPG',
                    },
                    {
                        src: '/img/incentives/piratenfest/pic (2).JPG',
                    },
                    {
                        src: '/img/incentives/piratenfest/pic (3).JPG',
                    },
                    {
                        src: '/img/incentives/piratenfest/pic (4).JPG',
                    },
                    {
                        src: '/img/incentives/piratenfest/pic (5).jpg',
                    },
                ],
            },
            {
                showLike: "Kanufahrt rund um Lübeck",
                thumbnail: "/img/incentives/kanufahrt/thumbnail.jpg",
                descTitle: ".",
                desc: "Eine Kanufahrt rund um Lübeck kann ein tolles Erlebnis für Sie sein und Ihnen die Möglichkeit bieten, die schöne Natur der Region zu genießen.\n" +
                    "Nach der Einführung in die technischen Gegebenheiten, erhalten Sie Schwimmweste, Paddel und springen ins Kanu. Genießen Sie Ihre Kanufahrt durch die wunderschöne Natur.",
                variants: [
                    {
                        title: "Treffpunkt: Falkenstraße 56, Lübeck",
                        points: [],
                    },
                    {
                        title: "Dauer: 3 Stunden",
                        points: [],
                    },
                    {
                        title: "Tourlänge: 5 km",
                        points: [],
                    },
                ],
                pictures: [
                    {
                        src: '/img/incentives/kanufahrt/pic (1).jpg',
                    },
                    {
                        src: '/img/incentives/kanufahrt/pic (2).jpg',
                    },
                    {
                        src: '/img/incentives/kanufahrt/pic (3).jpg',
                    },
                ],
            },
            {
                showLike: "Floßbau",
                thumbnail: "/img/incentives/floßbau/thumbnail.jpg",
                descTitle: ".",
                desc: "Erleben Sie ein frisches und unverbrauchtes Teamevent mit hohem Spaßfaktor. Es ist wirklich leicht, ein Floß zu bauen. Aber ohne Teamarbeit, gute Kommunikation und Ideenfindung wird es nichts. Das Ergebnis wird direkt getestet. Erleben Sie einen Ausflug am Wasser. ",
                variants: [
                    {
                        title: "Region: Schleswig-Holstein",
                        points: [],
                    },
                    {
                        title: "Dauer: 3-4 Stunden",
                        points: [],
                    },
                ],
                pictures: [
                    {
                        src: '/img/incentives/floßbau/pic (1).jpg',
                    },
                    {
                        src: '/img/incentives/floßbau/pic (2).jpg',
                    },
                    {
                        src: '/img/incentives/floßbau/pic (3).jpg',
                    },
                    {
                        src: '/img/incentives/floßbau/pic (4).jpg',
                    },
                ],
            },
            {
                showLike: "Seifenkistenrennen",
                thumbnail: "/img/incentives/seifenkistenrennen/thumbnail.jpg",
                descTitle: ".",
                desc: "Das ertse große “Soap Box Derby”, also Seifenkistenrennen, fand am 19. März in Dayton mit 392 gemeldeten Fahrern statt. Wir veranstalten diese Spektakel für Sie jedoch auch im kleinen Rahmen.\n" +
                    "Es werden gleich große Teams gebildet, welche vor Ort ihre selbst entworfene Seifenkiste bauen. Wir bringen genug Material mit, so dass jede Seifenkiste ein Unikat wird.\n",
                variants: [
                    {
                        title: "",
                        points: [],
                    }
                ],
                pictures: [
                    {
                        src: '/img/incentives/seifenkistenrennen/pic (1).jpg',
                    },
                    {
                        src: '/img/incentives/seifenkistenrennen/pic (2).jpg',
                    },
                    {
                        src: '/img/incentives/seifenkistenrennen/pic (3).jpg',
                    },
                    {
                        src: '/img/incentives/seifenkistenrennen/pic (4).JPG',
                    },
                    {
                        src: '/img/incentives/seifenkistenrennen/pic (5).JPG',
                    },
                ],
            },
            {
                showLike: "Fitness & Fun",
                thumbnail: "/img/incentives/fitness/thumbnail.JPG",
                descTitle: ".",
                desc: "Fit und gesund raus aus dem Homeoffice und ab ins Freie! Zusammen im Team. Das alles ist bei unserem Fit & Fun Tag ohne Probleme möglich. Unsere ausgewählten Spielestationen sind alle sowohl einzeln als auch im Team bespielbar. Beweisen Sie Teamgeist und lassen Sie sich von unseren unterhaltsammen Sportmodulen begeistern, denn egal ob Azubi oder Seniorchef, bei uns ist für jeden garantiert etwas dabei! ",
                variants: [
                    {
                        title: "Mögliche Stationen",
                        points: [
                            "Tischtennis",
                            "Golfabschlag",
                            "Interactive Playsystem",
                            "Fußballdart",
                            "Human Table Soccer",
                            "Basketball",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/incentives/fitness/pic (1).jpg',
                    },
                    {
                        src: '/img/incentives/fitness/pic (2).jpg',
                    },
                    {
                        src: '/img/incentives/fitness/pic (3).jpg',
                    },
                    {
                        src: '/img/incentives/fitness/pic (4).jpg',
                    },
                    {
                        src: '/img/incentives/fitness/pic (5).JPG',
                    },
                ],
            },
            {
                showLike: "Friesenabitur",
                thumbnail: "/img/incentives/friesenabitur/thumbnail.JPG",
                descTitle: ".",
                desc: "Bildungsauftrag mal anders! Unsere nordische Reifeprüfung hat allerdings nichts mit dem klassischen, schulischen Abitur zu tun. Mit ganz besonderen Herausforderungen können „Nicht-Friesen“ alle Sitten und Bräuche spielerisch kennenlernen.\n" +
                    "Im Vordergrund steht hierbei jedoch vor allem der Gruppenspaß.\n",
                variants: [
                    {
                        title: "Folgende Disziplinen sind zu meistern",
                        points: [
                            "Bosseln",
                            "Gummistiefelweitwurf",
                            "Friesenquiz",
                            "Wettmelken",
                            "Schnäpse raten",
                            "Tau ziehen"
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/incentives/friesenabitur/pic (1).jpg',
                    },
                    {
                        src: '/img/incentives/friesenabitur/pic (2).JPG',
                    },
                    {
                        src: '/img/incentives/friesenabitur/pic (3).jpg',
                    },
                    {
                        src: '/img/incentives/friesenabitur/pic (4).jpg',
                    },
                ],
            },
            {
                showLike: "Mini EM",
                thumbnail: "/img/incentives/EM/thumbnail.JPG",
                descTitle: ".",
                desc: "Erleben Sie mit Ihren Gästen, passend zum Thema Fußball Deutschland ein sportliches Event der etwas anderen Art. Mit unseren unzähligen Attraktionen können Sie Fußball einmal von einer ganz anderen Seite erleben. Die Gäste werden in gleich große Mannschaften eingeteilt, welche dann gegeneinander antreten. Es gilt, jede Fußballstation zu meistern. \n\nAnschließend können Ihre Mitarbeiter sich auf Wunsch noch an unserem Reichhaltigen Catering Buffet stärken und danach gespannt ein Spiel auf einer XXL Leinwand verfolgen.",
                variants: [
                    {
                        title: "Grundpaket",
                        points: [
                            "XXL Kicker",
                            "Torwand",
                            "Human Table Soccer",
                            "Fußball Dart",
                            "Shoot Out",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/incentives/EM/pic (3).jpg',
                    },
                    {
                        src: '/img/incentives/EM/pic (2).jpg',
                    },
                    {
                        src: '/img/incentives/EM/pic (4).jpg',
                    },
                ],
            },
            {
                showLike: "Gamer Days",
                thumbnail: "/img/incentives/gamer/thumbnail.jpg",
                descTitle: ".",
                desc: "Back to the future! Tauchen Sie ein in die Welt der virtuellen Realität oder reisen Sie zurück in die frühen 90er wenn das vertraute Klickern unserer Nostalgie Flipper den Raum erfüllt. An diesem Tag dreht sich für Sie und ihr Team alles ums Thema Spaß! Ob technisch versiert oder Retrofan, ein jeder kommt bei unserem Spielemodulen voll auf seine Kosten und kann seinen inneren Gamer mal so richtig auf die Probe stellen!",
                variants: [
                    {
                        title: "Grundpaket",
                        points: [
                            "Virtual Reality 5D Simulator",
                            "Virtual Reality Gaming Ecke",
                            "Nostalgie Flipper",
                            "Airhockey Tisch",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/incentives/gamer/pic (1).jpg',
                    },
                    {
                        src: '/img/incentives/gamer/pic (2).jpg',
                    },
                    {
                        src: '/img/incentives/gamer/pic (3).jpg',
                    },
                ],
            },
            {
                showLike: "Natural Sources",
                thumbnail: "/img/incentives/natural/thumbnail.jpg",
                descTitle: ".",
                desc: "Grün ist die neue Wohlfühlfarbe! Mit unserem Programm \"Natural Sources\" erfinden wir uns im Bereich Incentives komplett neu und startet ein Green Event der etwas anderen Art. Hintergrund dieser Teambuilding Maßnahme ist es, komplett auf Technik, Strom und sonstige Einflüsse zu verzichten und sich komplett auf die eigene Kraft und das Köpfchen zu verlassen.",
                variants: [
                    {
                        title: "Grundpaket",
                        points: [
                            "Bogenschießen",
                            "Kuh melken",
                            "Tauziehen",
                            "Rasenski",
                            "Insektenhotel bauen",
                            "Wasser filtern",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/incentives/natural/pic (1).jpg',
                    },
                    {
                        src: '/img/incentives/natural/pic (2).JPG',
                    },
                    {
                        src: '/img/incentives/natural/pic (3).jpg',
                    },
                ],
            },
        ]
    },
    {
        title: "Animation",
        url: "/eventmodule/animation",
        types: [
            {
                showLike: "Kinderanimation",
                thumbnail: "/img/eventmodule/animation/kinderanimation/thumbnail.jpg",
                descTitle: ".",
                desc: "Kinderanimation ist eines der wichtigsten Unterhaltungsmodule auf Ihrem Campingplatz, denn wenn die Kinder zufrieden sind, sind es zumeist auch Ihre Eltern. Wir von der S.O.F.A. GmbH wünschen uns für Ihre kleinen Gäste mehr... Spaß am Lernen, Freude am positiven Umgang miteinander, Rücksichtnahme und Hilfsbereitschaft werden gleichwertig trainiert. Oft suchen die Kinder zu uns intensive Gespräche, sie schließen neue Freundschaften mit anderen Gästen und fördern so das Wir-Gefühl… und genau das wollen wir erreichen!\n" +
                    "Die Inhalte des Animationsprogramms sind für die Altersstufe 4 bis 12 Jahre entwickelt, können aber variiert werden. Das Programm bietet eine enorme Vielfalt!\n",
                variants: [
                    {
                        title: "Mit dabei unter anderem",
                        points: [
                            "Verschiedene Bastelaktivitäten wie: Traumfänger basteln",
                            "Naturnahe Unterhaltung z.B. unser eigener „Kinder“garten",
                            "Experimente und Erkundungen",
                            "Schatzsuchen & Ralleys",
                            "Sport- und Ballspiele",
                            "Kinderdisco, Spieleabende & Gute Nacht Geschichten",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/animation/kinderanimation/pic (1).jpg',
                    },
                    {
                        src: '/img/eventmodule/animation/kinderanimation/pic (2).jpg',
                    },
                    {
                        src: '/img/eventmodule/animation/kinderanimation/pic (3).jpg',
                    },
                    {
                        src: '/img/eventmodule/animation/kinderanimation/pic (4).jpg',
                    },
                ],
            },
            {
                showLike: "Jugendanimation",
                thumbnail: "/img/eventmodule/animation/jugendanimation/thumbnail.jpg",
                descTitle: ".",
                desc: "Die Jugendanimation steht ganz unter dem Motto: „Rein ins Abenteuer …“. Die Aktionen sind auf die Zielgruppe 12-16 Jahre zugeschnitten und beinhalten mehr als nur „chillen“ für die Teenager. Die Jugendlichen bekommen innerhalb der Aktionen die Chance, sich kreativ auszuleben, Freundschaften zu knüpfen und eigene neue Projekte zu entwickeln.\n" +
                    "Jugendanimation erfordert Spontanität und Begeisterungsfähigkeit. Die Inhalte der Animationen sind reichhaltig und breitgefächert: Sie reichen vom Spielen, Bauen und Werken bis hin zum lebendigen Wii-Abend oder lustigen Online Challenges.\n",
                variants: [
                    {
                        title: "Mit dabei unter anderem",
                        points: [
                            "Survivaltraining",
                            "Style- und Beautynachmittage",
                            "Camp-Insta- Shootings",
                            "Youtubechallanges",
                            "Filmabend",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/animation/jugendanimation/pic (1).jpg',
                    },
                    {
                        src: '/img/eventmodule/animation/jugendanimation/pic (2).JPG',
                    },
                    {
                        src: '/img/eventmodule/animation/jugendanimation/pic (3).JPG',
                    },
                    {
                        src: '/img/eventmodule/animation/jugendanimation/pic (4).jpg',
                    },
                ],
            },
            {
                showLike: "Sportanimation",
                thumbnail: "/img/eventmodule/animation/sportanimation/thumbnail.jpg",
                descTitle: ".",
                desc: "Trend- oder auch sogenannte Funsportarten folgen der Bedeutung ihres Namens und gelten als urbane, frische Establishments im Bereich Sport und Bewegung.\n" +
                    "Gerade unter jungen Erwachsenen und Teenies gewinnt das Wort \"Funsport\" immer mehr an Gewicht. Niemand will mehr einfach nur Skateboarden, wofür gibt es schließlich Pennyboards, Longboards und Waveboards?\n" +
                    "Die Vielfalt in diesem Bereich ist heutzutage größer denn je, genauso wie das Interesse. Dort mit zu halten und jede einzelne Sportart zu verfolgen kann sich mitunter schwierig gestalten, gerade wenn man nicht allzu sehr in die Materie verstrickt ist. Hier kommen wir von der S.O.F.A. GmbH, samt unseren Animateuren ins Spiel. Sie sind jung, dynamisch, sportbegeistert und stets motiviert neues auszuprobieren. Wofür also Stunden lang selbst suchen, wenn man ein komplettes Team voll frischer Ideen und Enthusiasmus zur Verfügung gestellt bekommt?\n",
                variants: [
                    {
                        title: "Mit dabei unter anderem",
                        points: [
                            "Crossgolf",
                            "Slackline",
                            "Speedminton",
                            "Acro Yoga",
                            "Hip-Hop Dance",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/animation/sportanimation/pic (1).jpg',
                    },
                    {
                        src: '/img/eventmodule/animation/sportanimation/pic (2).JPG',
                    },
                    {
                        src: '/img/eventmodule/animation/sportanimation/pic (3).JPG',
                    },
                    {
                        src: '/img/eventmodule/animation/sportanimation/pic (4).JPG',
                    },
                    {
                        src: '/img/eventmodule/animation/sportanimation/pic (5).JPG',
                    },
                    {
                        src: '/img/eventmodule/animation/sportanimation/pic (6).JPG',
                    },
                ],
            },
            {
                showLike: "Rund ums Jahr",
                thumbnail: "/img/eventmodule/animation/rund-ums-jahr/thumbnail.JPG",
                descTitle: ".",
                desc: "Bei unserer Osteraktion sind den Kindern beim Gestalten von Ostereiern, süßen Körbchen und Deko keine Grenzen gesetzt! Wir starten bunt ins Frühjahr und verschönern was wir nur können. Auf Wunsch hoppelt einer unserer hauseigenen Osterhasen auch gerne bei Ihnen über den Platz, verteilt kleine Leckereien und bespaßt Groß und Klein auf der Ostereiersuche.\n" +
                    "Der Sommer ist unsere Hauptsaison. Hier begeistern wir durch magische Wasserschlachten, Strand- und Seespiele, Naturkunde und so viel Outdooraction wie möglich, um jeden einzelnen Sonnenstrahl voll aus zu kosten!\n" +
                    "Auf Wunsch planen wir auch gerne Ihr hauseigenes Sommerfest oder schmeißen eine mega Beachparty!\n" +
                    "Im Herbst, wenn die Tage langsam wieder ein wenig kürzer werden und die Nächte länger, dann ist es Zeit für kleine Gruselmonster und flinke Geister. Wir basteln zusammen mit den Kids bunte Halloweendeko, Geistermasken und auch das alljährliche Kürbisschnitzen darf natürlich nicht auf dem Programm fehlen!\n" +
                    "In der Weihnachtsbäckerei... Gibt es nicht nur jede Menge Leckereien, sondern auch tolle Bastelaktionen, gemütliche Geschichten-Nachmittage und Spielspaß für die ganze Familie! Auch im Winter begeistern wir durch Einfallsreichtum und Kreativität. Zusammen mit Groß und Klein bemalen wir unsere eigenen Weihnachtskügelchen, kreieren süße Sockenschneemänner oder gestalten bunte Weihnachtskarten für Oma und Opa.",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/animation/rund-ums-jahr/pic (1).jpg',
                    },
                    {
                        src: '/img/eventmodule/animation/rund-ums-jahr/pic (3).jpg',
                    },
                    {
                        src: '/img/eventmodule/animation/rund-ums-jahr/pic (2).jpg',
                    },
                    {
                        src: '/img/eventmodule/animation/rund-ums-jahr/pic (4).jpg',
                    },
                    {
                        src: '/img/eventmodule/animation/rund-ums-jahr/pic (5).JPG',
                    },
                ],
            },
            {
                showLike: "Sommerfest",
                thumbnail: "/img/eventmodule/animation/sommerfest/thumbnail.jpg",
                descTitle: ".",
                desc: "Ein Tag voller Spaß und Freude, der Kinderaugen strahlen lässt! Wir von der S.O.F.A. GmbH lassen kleine und große Herzen höherschlagen.\n" +
                    "Ein paar Sandförmchen und Schwimmflügel sind längst nicht mehr alles was wir den Kindern zur heutigen Zeit bieten können und vor allem sollten. Während Mama und Papa am Strand liegen kommt nach einer gewissen Zeit oft Langeweile auf und der Wunsch nach Beschäftigung. Umso besser ist es dann, wenn direkt um die Ecke so einiges geboten wird. In dieser Hinsicht ist unser Programm breit gefächert und wird zusätzlich von unseren hauseigenen Großgeräten in passender Form unterstützt. Was wir bieten?\n",
                variants: [
                    {
                        title: "Programm",
                        points: [
                            "Neptunfest",
                            "Piratentage",
                            "Strandnachmittage",
                            "Camperfeste",
                            "Mottoparties",
                            "Turniere",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/animation/sommerfest/pic (1).jpg',
                    },
                    {
                        src: '/img/eventmodule/animation/sommerfest/pic (2).jpg',
                    },
                    {
                        src: '/img/eventmodule/animation/sommerfest/pic (3).jpg',
                    },
                    {
                        src: '/img/eventmodule/animation/sommerfest/pic (4).jpg',
                    },
                    {
                        src: '/img/eventmodule/animation/sommerfest/pic (5).jpg',
                    },
                ],
            },
            {
                showLike: "Alles mit dabei",
                thumbnail: "/img/eventmodule/animation/alles-mit-dabei/thumbnail.jpg",
                descTitle: ".",
                desc: "Wir von der S.O.F.A. GmbH kümmern uns nicht nur um die passenden Animateure für Ihren Campingplatz, wir bieten das komplette Rund-Um-Paket!\n" +
                    "Personal, Material, Planung & Konzeption. Vom Buntstift bis zum Fußball, bei uns ist alles dabei!\n" +
                    "Dementsprechend bieten wir nicht nur Service, sondern auch Qualität. Noch bevor die Saison anfängt, besuchen wir sie Vorort um sicher zu gehen, dass wir unser Programm ideal an Ihre Gegebenheiten anpassen können und kein Wunsch offenbleibt.\n",
                variants: [
                    {
                        title: "Programm",
                        points: [
                            "Fröhliches & aufgeschlossenes Personal",
                            "Verbrauchsmaterial",
                            "Gebrauchsmaterial",
                            "Planung & Wochenpläne",
                            "Großgeräte",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/eventmodule/animation/alles-mit-dabei/pic (1).jpg',
                    },
                    {
                        src: '/img/eventmodule/animation/alles-mit-dabei/pic (2).jpg',
                    },
                    {
                        src: '/img/eventmodule/animation/alles-mit-dabei/pic (3).JPG',
                    },
                    {
                        src: '/img/eventmodule/animation/alles-mit-dabei/pic (4).jpg',
                    },
                ],
            },
        ]
    },
    {
        title: "Ausstattung",
        url: "/eventmodule/ausstattung",
        types: [
            {
                showLike: "Catering",
                thumbnail: "/img/ausstatung/catering/thumbnail.JPG",
                descTitle: ".",
                desc: "Dem Catering sind keine Grenzen gesetzt - von einer Gala mit einem mehrgängigen Menü bis hin zu einem rustikalen Fest mit Grillstand. Es besteht die Möglichkeit der Leiferung, aber es kann selbstverständlich auch alles vor Ort frisch zubereitet werden.",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [
                    {
                        src: '/img/ausstatung/catering/pic (1).JPG',
                    },
                    {
                        src: '/img/ausstatung/catering/pic (2).JPG',
                    },
                    {
                        src: '/img/ausstatung/catering/pic (3).JPG',
                    },
                    {
                        src: '/img/ausstatung/catering/pic (4).jpg',
                    },
                    {
                        src: '/img/ausstatung/catering/pic (5).jpg',
                    },
                    {
                        src: '/img/ausstatung/catering/pic (6).jpg',
                    },
                    {
                        src: '/img/ausstatung/catering/pic (7).JPG',
                    },
                    {
                        src: '/img/ausstatung/catering/pic (8).jpg',
                    },
                ],
            },
            {
                showLike: "Dekoration",
                thumbnail: "/img/ausstatung/dekoration/thumbnail.jpg",
                descTitle: ".",
                desc: "Um bei einer Veranstaltung die gewünschte Stimmung aufkommen zu lassen, ist die richtige Dekoration sehr wichtig. Sie bestimmt das Thema der Veranstaltung. Sei es ein Fußballmotto mit allen möglichen Flaggen der Welt oder eine elegante Abend Gala, mit hübschen Gedecken. Wir dekorieren Ihre Veranstaltung frei nach Ihrem Geschmack, so dass keine Wünsche offen bleiben.\n\n" +
                    "Unsere Luftballon Dekoration ist immer ein ganz besonderer Hingucker. Wir erstellen für Sie tolle Ballonkränze, welche sich über den Köpfen Ihrer Gäste erstrecken, oder schmücken Säulen und Wände.\n\n" +
                    "Kein Raum und keine Veranstaltung sind zu groß für uns. Auch in großen Räumen gibt unser Dekorationsteam immer 100%. Wir verfügen über zahlreiche Dekowände mit verschiedenen Drucken, zu verschiedenen Themen. Mit Sicherheit ist auch für Ihre Veranstaltung etwas Passendes dabei.\n\n" +
                    "Unsere Themendeko ist nah angelehnt an unsere Mottoveranstaltungen, doch auch jede andere Veranstaltung kann durch Dekoration mit einem besonderen Leitfaden immer sehr interessant werden.",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [
                    {
                        src: '/img/ausstatung/dekoration/pic (1).jpg',
                    },
                    {
                        src: '/img/ausstatung/dekoration/pic (2).jpg',
                    },
                    {
                        src: '/img/ausstatung/dekoration/pic (3).jpg',
                    },
                    {
                        src: '/img/ausstatung/dekoration/pic (4).jpg',
                    },
                    {
                        src: '/img/ausstatung/dekoration/pic (6).JPG',
                    },
                    {
                        src: '/img/ausstatung/dekoration/pic (7).jpg',
                    },
                ],
            },
            {
                showLike: "Mobiliar",
                thumbnail: "/img/ausstatung/mobialer/thumbnail.jpg",
                descTitle: ".",
                desc: "Zu jeder Veranstaltung können wir natürlich auch Mobiliar dazu liefern. Je nach Größe der Veranstaltung und Anzahl der Gäste haben wir passend zum Thema und Anlass auch das richtige Mobiliar für Sie. Wir liefern Bierzeltgarnituren, Loungemöbel oder auch Strandkörbe und Liegestühle. Auch Stehtische dürfen auf keiner Veranstaltung fehlen. Unsere Tische kommen passend dekoriert und eingedeckt. ",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [
                    {
                        src: '/img/ausstatung/mobialer/pic (1).jpg',
                    },
                    {
                        src: '/img/ausstatung/mobialer/pic (2).JPG',
                    },
                    {
                        src: '/img/ausstatung/mobialer/pic (3).jpg',
                    },
                    {
                        src: '/img/ausstatung/mobialer/pic (4).JPG',
                    },
                    {
                        src: '/img/ausstatung/mobialer/pic (5).jpg',
                    },
                    {
                        src: '/img/ausstatung/mobialer/pic (6).JPG',
                    },
                    {
                        src: '/img/ausstatung/mobialer/pic (7).JPG',
                    },
                    {
                        src: '/img/ausstatung/mobialer/pic (8).jpg',
                    },
                    {
                        src: '/img/ausstatung/mobialer/pic (9).jpg',
                    },
                ],
            },
            {
                showLike: "Zelte",
                thumbnail: "/img/ausstatung/zelte/thumbnail.jpg",
                descTitle: ".",
                desc: "Zelte sind immer eine willkommene Location für eine Veranstaltung, da sie überaus wandelbar sind. Ob Oktoberfest oder schicke Gartengala, das passende Festzelt, lässt sich in so ziemlich jede Traumlocation verwandeln.\n\n" +
                    "Wir verfügen über mehrere Zelte in verschiedenen Größen, bei welchen Sie ganz sicher auch das Richtige für Ihre Veranstaltung finden. ",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [
                    {
                        src: '/img/ausstatung/zelte/pic (1).jpg',
                    },
                    {
                        src: '/img/ausstatung/zelte/pic (2).JPG',
                    },
                    {
                        src: '/img/ausstatung/zelte/pic (3).JPG',
                    },
                    {
                        src: '/img/ausstatung/zelte/pic (4).jpg',
                    },
                    {
                        src: '/img/ausstatung/zelte/pic (5).jpg',
                    },
                    {
                        src: '/img/ausstatung/zelte/pic (6).JPG',
                    },
                ],
            },
            {
                showLike: "Technik",
                thumbnail: "/img/ausstatung/technik/thumbnail.jpg",
                descTitle: ".",
                desc: "Sound, Licht, diverse Effektmaschinen. Eine Veranstaltung wäre nichts ohne die technischen Gadgets, die unser audiovisuelles Erlebnis komplementieren. Das richtige Licht kann entscheidend sein, gerade bei Abendveranstaltungen. Mit unseren LED Strahlern, PAR 56, gelingt es uns immer wieder die Nacht zum Tag zu machen. Wir verfügen auch über einen Lichtmixer, welcher die LED Spots in verschiedenen Farben erstrahlen lässt. Auch Lichterketten und Lichtschläuche sind vorhanden und sorgen für eine gemütliche Stimmung. Vervollständigt wird unser kleiner Reporter durch diverse Theaterstrahler.\n\n" +
                    "Die Lichteffekte sind ebenfalls kompatibel mit einigen unserer Musikanlagen. Diese sind in verschiedenen Größenausführungen erhältlich. Dazu kann auch immer ein Mikrofon beigefügt werden.\n\n" +
                    "Wichtig für unseren Technikbereich, aber auch als Absperrung dienlich, sind unsere Traversensysteme. Auch diese gibt es in verschiedenen Größen und Variationen.\n\n",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [
                    {
                        src: '/img/ausstatung/technik/pic (1).jpg',
                    },
                    {
                        src: '/img/ausstatung/technik/pic (2).jpg',
                    },
                    {
                        src: '/img/ausstatung/technik/pic (3).JPG',
                    },
                    {
                        src: '/img/ausstatung/technik/pic (4).jpg',
                    },
                    {
                        src: '/img/ausstatung/technik/pic (5).jpg',
                    },
                    {
                        src: '/img/ausstatung/technik/pic (6).jpg',
                    },
                    {
                        src: '/img/ausstatung/technik/pic (7).jpg',
                    },
                    {
                        src: '/img/ausstatung/technik/pic (8).jpg',
                    },
                ],
            },
        ]
    },
    {
        title: "Abendveranstaltungen",
        url: "/veranstaltung/abendveranstaltungen",
        types: [
            {
                showLike: "Beach Party",
                thumbnail: "/img/abendveranstaltungen/beach/thumbnail.JPG",
                descTitle: ".",
                desc: "",
                variants: [
                    {
                        title: "Inhalte",
                        points: [
                            "Surf Simulator",
                            "Cocktail Bar",
                            "DJ",
                            "Passende Beleuchtung",
                            "Hula Tänzerinnen",
                            "Kleine Gadgets wie Hula Ketten",
                            "Leckeres Grillbarbeque",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/abendveranstaltungen/beach/pic (1).jpg',
                    },
                    {
                        src: '/img/abendveranstaltungen/beach/pic (2).jpg',
                    },
                ],
            },
            {
                showLike: "Casino Night",
                thumbnail: "/img/abendveranstaltungen/casino/thumbnail.jpg",
                descTitle: ".",
                desc: "",
                variants: [
                    {
                        title: "Inhalte",
                        points: [
                            "Kostümiertes Personal",
                            "Black Jack Tisch",
                            "Poker Tisch",
                            "Roulette Tisch",
                            "Professioneller DJ",
                            "Passende Beleuchtung und Dekoration",
                            "Cocktail Bar",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/abendveranstaltungen/casino/pic (3).jpg',
                    },
                    {
                        src: '/img/abendveranstaltungen/casino/pic (1).jpg',
                    },
                    {
                        src: '/img/abendveranstaltungen/casino/pic (2).jpg',
                    },
                ],
            },
            {
                showLike: "20er Jahre Party",
                thumbnail: "/img/abendveranstaltungen/20jahre/thumbnail.jpg",
                descTitle: ".",
                desc: "",
                variants: [
                    {
                        title: "Inhalte",
                        points: [
                            "Jazz Band",
                            "Kostümiertes Personal",
                            "Kronleuchter",
                            "Schwarze, goldene, silberne, weiße Dekoration",
                            "Fotowand mit Federschmuck, Perlen etc.",
                            "Lustige Spiele rund um das Thema Mafia & Gangster",
                            "Nostalgie Karussell",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/abendveranstaltungen/20jahre/pic (1).JPG',
                    },
                    {
                        src: '/img/abendveranstaltungen/20jahre/pic (2).JPG',
                    },
                    {
                        src: '/img/abendveranstaltungen/20jahre/pic (3).jpg',
                    },
                ],
            },
            {
                showLike: "60er Jahre Party",
                thumbnail: "/img/abendveranstaltungen/60jahre/thumbnail.jpeg",
                descTitle: ".",
                desc: "",
                variants: [
                    {
                        title: "Inhalte",
                        points: [
                            "Bunte Regenbogen Dekoration",
                            "Disco Kugel",
                            "60´s Cover Bands oder DJ",
                            "Moderierter Kostüm-Contest",
                            "Fotowand mit winzigen Gadgets",
                            "Pinguinrennbahn"
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/abendveranstaltungen/60jahre/pic (1).JPG',
                    },
                ],
            },
            {
                showLike: "80er Jahre Party",
                thumbnail: "/img/abendveranstaltungen/80jahre/thumbnail.png",
                descTitle: ".",
                desc: "",
                variants: [
                    {
                        title: "Inhalte",
                        points: [
                            "80er Jahre DJ",
                            "Passende poppige Dekoration im Dekadenstil",
                            "Fotowand mit witzigen Gadgets",
                            "Arm-Drück-Maschine",
                            "Airhockey Tisch",
                            "Glücksrad",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/abendveranstaltungen/80jahre/pic.jpg',
                    },
                ],
            },
            {
                showLike: "Project X Party",
                thumbnail: "/img/abendveranstaltungen/projectx/thumbnail.jpeg",
                descTitle: ".",
                desc: "",
                variants: [
                    {
                        title: "Inhalte",
                        points: [
                            "Hüpfburgen",
                            "Pool",
                            "Open Contests",
                            "Body Painting / Airbrush",
                            "DJ",
                            "Akrobaten",
                            "Luftballons, Konfetti, Glitzer uvm.",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/abendveranstaltungen/projectx/pic (1).jpg',
                    },
                    {
                        src: '/img/abendveranstaltungen/projectx/pic (2).jpg',
                    },
                    {
                        src: '/img/abendveranstaltungen/projectx/pic (3).jpg',
                    },
                    {
                        src: '/img/abendveranstaltungen/projectx/pic (4).jpg',
                    },
                ],
            },
            {
                showLike: "St. Paddys Day",
                thumbnail: "/img/abendveranstaltungen/paddysday/thumbnail.jpg",
                descTitle: ".",
                desc: "",
                variants: [
                    {
                        title: "Inhalte",
                        points: [
                            "Grün - Weiße Ballons und Girlanden",
                            "DJ",
                            "Karaoke Anlagen",
                            "Typisches Pubfood",
                            "wie z. B. Chicken Fingers und Wings",
                            "Fotowand mit lustigen Gadgets",
                            "Kleine Contest und Partyspiele",
                        ],
                    },
                ],
                pictures: [
                    {
                        src: '/img/abendveranstaltungen/paddysday/pic (1).jpg',
                    },
                ],
            },
        ]
    },
    {
        title: "Preisliste",
        url: "/preisliste",
        types: [
            {
                showLike: "Eventmodule",
                thumbnail: "/img/preisliste/Großgeräte.JPG",
                descTitle: "url:/preisliste/eventmodule",
                desc: "",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [],
            },
            {
                showLike: "Schlittschuhbahn",
                thumbnail: "/img/preisliste/schlitt_thumb.jpg",
                descTitle: "url:/prices/schlittschuhbahn",
                desc: "",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [],
            },
            {
                showLike: "Veranstaltungen",
                thumbnail: "/img/preisliste/Veranstaltungen.jpg",
                descTitle: "url:/preisliste/veranstaltungen",
                desc: "",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [],
            },
            {
                showLike: "Extras",
                thumbnail: "/img/preisliste/Extras.jpg",
                descTitle: "url:/preisliste/extras",
                desc: "",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [],
            },
        ]
    },
    {
        title: "Eventmodule",
        url: "/preisliste/eventmodule",
        types: [
            {
                showLike: "Sport und Action",
                thumbnail: "/img/preisliste/nicht_aufblasbar.jpg",
                descTitle: "url:/prices/action_sport",
                desc: "",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [],
            },
            {
                showLike: "Aufblasbar",
                thumbnail: "/img/preisliste/aufblasbar.jpg",
                descTitle: "url:/prices/aufblasbar",
                desc: "",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [],
            },
            {
                showLike: "Klettern",
                thumbnail: "/img/preisliste/Klettern.jpg",
                descTitle: "url:/prices/klettern",
                desc: "",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [],
            },
            {
                showLike: "Hüpfburgen",
                thumbnail: "/img/preisliste/Hüpfburgen.jpg",
                descTitle: "url:/prices/huepfburgen",
                desc: "",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [],
            },
            {
                showLike: "Virtual Reality",
                thumbnail: "/img/preisliste/VR_Thumb.jpg",
                descTitle: "url:/prices/vr",
                desc: "",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [],
            },
        ]
    },
    {
        title: "Veranstaltungen",
        url: "/preisliste/veranstaltungen",
        types: [
            {
                showLike: "Spielmobile",
                thumbnail: "/img/preisliste/Spielmobil.jpg",
                descTitle: "url:/prices/spielmobile",
                desc: "",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [],
            },
            {
                showLike: "Weg mit dem Chef",
                thumbnail: "/img/preisliste/weg_chef.jpg",
                descTitle: "url:/prices/wegmitdemchef",
                desc: "",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [],
            },
        ]
    },
    {
        title: "Extras",
        url: "/preisliste/extras",
        types: [
            {
                showLike: "Catering",
                thumbnail: "/img/preisliste/catering_thumb.jpg",
                descTitle: "url:/prices/catering",
                desc: "",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [],
            },
            {
                showLike: "Erwachsene Spiele",
                thumbnail: "/img/preisliste/erwachs_thumb.jpg",
                descTitle: "url:/prices/erwachsene-spiele",
                desc: "",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [],
            },
            {
                showLike: "Kinder Spiele",
                thumbnail: "/img/preisliste/kinder_thumb.jpg",
                descTitle: "url:/prices/kinder-spiele",
                desc: "",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [],
            },
            {
                showLike: "Künstler & Walking Acts",
                thumbnail: "/img/preisliste/künstler_thumb.jpg",
                descTitle: "url:/prices/kuenstler-acts",
                desc: "",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [],
            },
            {
                showLike: "Zelte",
                thumbnail: "/img/preisliste/zelte_thumb.jpg",
                descTitle: "url:/prices/zelte",
                desc: "",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [],
            },
            {
                showLike: "Mobiliar & Technik",
                thumbnail: "/img/preisliste/mobi-tech-thumb.jpg",
                descTitle: "url:/prices/mobi-tech",
                desc: "",
                variants: [
                    {
                        title: "",
                        points: [],
                    },
                ],
                pictures: [],
            },
        ]
    },
]