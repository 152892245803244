import React from "react";
import {Box, useMediaQuery} from "@material-ui/core";
import Viewer from "react-viewer";
import styled from "@emotion/styled";
import {ImageList, ImageListItem} from "@mui/material";
import {PUBLIC_CND_BASEURL} from "../App";

function PicGalery({pictures, gap, cols, fullBox, ribbonsEverywhere, redlines}) {
    const matches1000 = useMediaQuery('(max-width:1000px)');
    const [visible, setVisible] = React.useState(false);
    const [activeIndex, setActiveIndex] = React.useState(0);

    const clickPic = (index) => {
        setActiveIndex(index);
        setVisible(true);
    }

    function fillArray(array) {
        const fillCount = Math.max(0, 3 - array.length);
        const startIndex = Math.floor(fillCount / 2);
        const endIndex = startIndex + array.length;
        return [...Array(startIndex).fill({}), ...array, ...Array(3 - endIndex).fill({})];
    }

    const resultArray = pictures.length <= 1 ? fillArray(pictures) : pictures;
    return (
        <>
            {/*https://openbase.com/js/react-viewer*/}
            <Viewer
                visible={visible}
                onClose={() => {
                    setVisible(false);
                }}
                images={resultArray}
                noImgDetails
                activeIndex={activeIndex}
                onMaskClick={() => {
                    setVisible(false);
                }}
            />
            <Box margin={!fullBox ? (!matches1000 ? "5% 10%" : "5% 5%") : "0%"}>
                <ImageList variant="masonry" cols={matches1000 ? 1 : (!cols ? 3 : cols)} gap={!gap ? 10 : gap}>
                    {resultArray.map((item, index) => (
                        <ImageListItem key={item.src} style={{display: "block"}}>
                            {(ribbonsEverywhere && item.label != null) &&
                                <Ribbon style={{transform: "rotate(0deg)", width: "100%", left: "0px"}}>
                                    {item.label}
                                </Ribbon>
                            }
                            {item.src &&
                                <img
                                    onClick={() => clickPic(index)}
                                    style={{border: redlines && "3px solid var(--main-accent-color)"}}
                                    src={PUBLIC_CND_BASEURL + `${item.src}?w=248&fit=crop&auto=format`}
                                    srcSet={PUBLIC_CND_BASEURL + `${item.src}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    loading="lazy"
                                    alt={"pic"}/>
                            }
                        </ImageListItem>
                    ))}
                </ImageList>
            </Box>
        </>
    )
}

const Ribbon = styled.div`
    color: white;
    left: -50px;
    position: absolute;
    top: 0;
    width: 150px;
    transform: rotate(-45deg);
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
    z-index: 5;
    text-transform: uppercase;
    padding: 15px;
    border-top: 2px solid var(--main-accent-color);
`

export default PicGalery