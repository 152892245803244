import React, {useEffect, useState} from "react";
import {preislistData} from "../preislistData";
import styled from "styled-components";
import {motion} from "framer-motion";
import structuredClone from '@ungap/structured-clone';
import {pageAnimation} from "../animation";
import ScrollTop from "../components/ScrollTop";
import {useLocation} from "react-router-dom";
import {PUBLIC_CND_BASEURL} from "../App";

function Prices({aPrice}) {
    const location = useLocation();
    const url = location.pathname;
    const [prices, setPrices] = useState(preislistData);
    const [price, setPrice] = useState(null);
    const [fullLength, setFullLength] = useState(0);

    useEffect(() => {
        if (aPrice) {
            const length = aPrice[0].personenanzahl ? (aPrice[0].platz_and_tech ? 7 : 6) : (aPrice[0].platz_and_tech ? 6 : 5);
            setFullLength(length);
            setPrice(aPrice);
        } else {
            const currentPrice = prices.filter((price) => price.url === url);
            let myPrice = [];
            currentPrice[0].items.map((item) => {
                let obj = structuredClone(item);
                Object.keys(obj).forEach(function (key, index) {
                    obj[key] = obj[key].replace(/  +/g, '\n');
                });
                myPrice.push(obj);
            });
            if (!currentPrice[0].doNotSort) {
                myPrice.sort(function (a, b) {
                    if (a.beschreibung === "%footer%") {
                        return 1;
                    } else if (a.titel < b.titel) {
                        return -1;
                    } else if (a.titel > b.titel) {
                        return 1;
                    }
                    return 0;
                });
            }
            const length = myPrice[0].personenanzahl ? (myPrice[0].platz_and_tech ? 7 : 6) : (myPrice[0].platz_and_tech ? 6 : 5);
            setFullLength(length);
            console.log("my: ", myPrice)
            setPrice(myPrice);
        }
    }, [prices, url]);

    return (<>
        <motion.div
            exit="exit"
            variants={pageAnimation}
            initial="hidden"
            animate="show"
        >
            <Style>
                {price &&
                    <table width={"100%"} id={"table"}>
                        <tr>
                            <th>Artikel</th>
                            <th>Bild</th>
                            <th>Beschreibung</th>
                            {price[0].personenanzahl &&
                                <th>Personalanzahl</th>
                            }
                            {price[0].platz_and_tech &&
                                <th>Platzbedarf & technische Daten</th>
                            }
                            <th>Netto Betrag</th>
                            <th>Brutto Betrag</th>
                        </tr>
                        {price.map((item) => {
                            if (item.beschreibung !== "%footer%") {
                                return <tr key={item.titel}>
                                    <td>{item.titel}</td>
                                    <td width={"10%"} style={{padding: 0}}><img
                                        height={"100%"} src={item.bild === "" ? PUBLIC_CND_BASEURL + "/img/preisliste/detailed/" + item.titel + ".jpg" : item.bild}
                                                                                alt="" width={"100%"}
                                                                                style={{objectFit: "contain"}}/></td>
                                    <td width={"40%"}>{item.beschreibung}</td>
                                    {item.personenanzahl &&
                                        <td>{item.personenanzahl}</td>
                                    }
                                    {item.platz_and_tech &&
                                        <td>{item.platz_and_tech}</td>
                                    }
                                    <td>{item.netto}</td>
                                    <td>{item.brutto}</td>
                                </tr>
                            } else {
                                return <tr style={{height: "50px"}} key={item.titel}>
                                    <td colspan={fullLength}><strong>{item.titel}</strong></td>
                                </tr>
                            }
                        })
                        }
                        <tr style={{height: "50px"}}>
                            <td colSpan={fullLength}><strong>Die Preisliste ist ab 01.10.2022 gültig</strong></td>
                        </tr>
                    </table>
                }
            </Style>
        </motion.div>
        <ScrollTop/>
    </>)
}

const Style = styled(motion.div)`
    #table {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    #table td, #table th, .footer {
        border: 1px solid #ddd;
        padding: 8px;
        white-space: pre-wrap;
        text-align: center;
    }

    #table tr:nth-child(even) {
        background-color: #d9e2f3;
    }

    #table th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: center;
        background-color: #d9e2f3;
        color: black;
    }
`

export default Prices