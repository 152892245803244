import React, {useEffect, useState} from "react";
import {motion} from "framer-motion";
import {pageAnimation} from "../animation";
import ScrollTop from "../components/ScrollTop";
import {veranstaltungData} from "../veranstaltungData";
import styled from "styled-components";
import {Box, useMediaQuery} from "@material-ui/core";
import Motto from "../components/Motto";
import CheckIcon from '@mui/icons-material/Check';
import Viewer from "react-viewer";
import PicGalery from "../components/PicGalery";
import ReadMore from "../components/ReadMore";
import {useLocation, useNavigate} from "react-router-dom";

function Veranstaltung() {
    const navigate = useNavigate();
    const location = useLocation();
    const url = location.pathname;
    const [Veranstaltungen, setVeranstaltungen] = useState(veranstaltungData);
    const [veranstaltung, setVeranstaltung] = useState(null);
    const [type, setType] = useState(null);
    const matches600 = useMediaQuery('(max-width:600px)');
    const [visible, setVisible] = React.useState(false);

    useEffect(() => {
        const currentWork = Veranstaltungen.filter((veranstaltung) => veranstaltung.url === url);
        setVeranstaltung(currentWork[0]);
    }, [Veranstaltungen, url]);

    const handleClick = (type) => {
        if (type.descTitle === "") {
            return;
        } else if (type.descTitle.startsWith("pdf")) {
            let pdf = type.descTitle;
            pdf = pdf.replace("pdf:", "");
            window.open(pdf, "_blank");
            return;
        } else if (type.descTitle.startsWith("url")) {
            let title = type.descTitle;
            title = title.replace("url:", "");
            navigate(title);
            return;
        }
        setType(type);
        window.scroll({
            top: 0,
            left: 0,
        });
    }

    return (
        <>
            <motion.div
                exit="exit"
                variants={pageAnimation}
                initial="hidden"
                animate="show"
            >
                {veranstaltung && (
                    <>
                        {type &&
                            <>
                                <Viewer
                                    visible={visible}
                                    onClose={() => {
                                        setVisible(false);
                                    }}
                                    images={type.pictures}
                                    noToolbar
                                    noImgDetails
                                    onMaskClick={() => {
                                        setVisible(false);
                                    }}
                                />
                                <Box display={"flex"} justifyContent={"flex-start"} alignItems={"flex-start"}
                                     width={"100%"}
                                     style={{backgroundColor: "var(--main-accent-color)"}}>
                                    <h2 style={{
                                        padding: "30px",
                                        color: "white",
                                        fontWeight: "700"
                                    }}>{type.showLike}</h2>
                                </Box>
                                <Box display={"flex"} width={"100%"}>
                                    <Box width={!matches600 ? "50%" : "100%"}
                                         style={{
                                             backgroundColor: "var(--main-bg-color)",
                                             textAlign: "left",
                                             padding: "50px 20px 0 50px",
                                             paddingBottom: "2%",
                                         }}
                                         display={type.variants.length > 0 ? "flex" : "none"}
                                         justifyContent={"flex-start"} alignItems={"flex-start"}
                                         flexDirection={"column"}>
                                        <h1 style={{
                                            lineHeight: "30px",
                                            marginBlock: "10px",
                                            display: !type.descTitle && "none"
                                        }}>{type.descTitle.replace(".", "")}</h1>
                                        <p style={{
                                            lineHeight: "30px",
                                            whiteSpace: "pre-line",
                                            display: !type.desc && "none",
                                            paddingTop: "0px",
                                            textAlign: "justify",
                                            fontSize: "1.3rem"
                                        }}>{type.desc}</p>

                                        {type.variants.map((variant) => {
                                            return (
                                                <>
                                                    <h1 style={{
                                                        margin: "15px",
                                                        marginLeft: "0px",
                                                        fontSize: "20px",
                                                        lineHeight: "1.5"
                                                    }}>{variant.title}</h1>
                                                    {variant.points.map((point, index) => {
                                                        return (
                                                            <>
                                                                <Box display={"flex"} justifyContent={"flex-start"}
                                                                     alignItems={"baseline"}
                                                                     width={"100%"}>
                                                                    <CheckIcon
                                                                        style={{color: "var(--main-accent-color)"}}/>
                                                                    <ReadMore key={index} style={{
                                                                        padding: "10px",
                                                                        textAlign: "left",
                                                                        fontSize: "14px",
                                                                        lineHeight: "1.2",
                                                                        fontWeight: "400"
                                                                    }}>
                                                                        {point}
                                                                    </ReadMore>
                                                                </Box>
                                                            </>
                                                        )
                                                    })
                                                    }
                                                </>
                                            )
                                        })
                                        }
                                    </Box>
                                    <Box width={"100%"} display={matches600 && "none"}>
                                        {/*<Box onClick={() => setVisible(true)}>*/}
                                        <PicGalery pictures={type.pictures} cols={3}/>
                                        {/*<FotoShow images={type.pictures}/>*/}
                                        {/*</Box>*/}
                                    </Box>
                                </Box>
                                <Line style={{width: "70%", margin: "0 auto"}}/>
                                <Types type={type} matches600={matches600} veranstaltung={veranstaltung}
                                       handleClick={handleClick}/>
                            </>
                        }

                        {(matches600 || !type) &&
                            <Types type={type} matches600={matches600} veranstaltung={veranstaltung}
                                   handleClick={handleClick}/>
                        }
                    </>
                )}
            </motion.div>
            <ScrollTop/>
        </>
    )
}


function Types({type, matches600, veranstaltung, handleClick}) {
    return (
        <>
            <Box margin={!matches600 ? "25px 5% 0% 5%" : "3% 0% 0% 0%"}>
                {!type &&
                    <>
                        <h2 style={{textAlign: "center"}}>{veranstaltung.title}</h2>
                    </>
                }

                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexWrap={"wrap"}>
                    {
                        veranstaltung.types.map((type, index) => {
                            return (
                                <Motto key={index} veranstaltung={veranstaltung} type={type}
                                       handleClick={handleClick}/>
                            )
                        })
                    }
                </Box>
            </Box>
        </>
    )
}

const Line = styled(motion.div)`
  height: 0.5rem;
  background: var(--main-accent-color);
  margin-bottom: 3rem;
`

export default Veranstaltung