import React from "react";
import {Box, useMediaQuery} from "@material-ui/core";
import styled from "styled-components";
import {motion} from "framer-motion";

function Block(props) {
    const matches = useMediaQuery('(max-width:600px)');

    return (
        <>
            <Box display={"flex"} width={"100%"} justifyContent={"center"} alignItems={"center"}
                 flexDirection={"column"} margin={"50px 0"}>
                <Box width={!matches ? props.blockWidth : "unset"} textAlign={"center"} color={"var(--text-color)"}>
                    <h2>{props.title}</h2>
                    <p style={{display: props.desc === "disabled" && "none", whiteSpace: "pre-line", textAlign: props.textAlign}}>{props.desc}</p>
                    {props.children}
                </Box>
            </Box>
        </>
    )
}

const Line = styled(motion.div)`
  height: 0.4rem;
  background: var(--main-accent-color);
  width: 0%;
`;

export default Block