import React, {useState} from "react";
import {SpeedDial, SpeedDialAction} from "@material-ui/lab";
import FavoriteIcon from '@mui/icons-material/Favorite';
import CopyrightIcon from '@mui/icons-material/Copyright';
import {Box} from "@material-ui/core";
import styled from "styled-components";

function Copyright() {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Style>
            <Box zIndex={10000} position={"fixed"} top={"100%"} left={"100%"} style={{transform: "translate(-100%, -100%)"}}>
                <SpeedDial
                    ariaLabel="Copyright"
                    icon={<CopyrightIcon/>}
                    style={{margin: "0 15px 15px 0"}}
                    open={open}
                    onClick={() => setOpen(!open)}
                >
                    {open &&
                    <SpeedDialAction
                        icon={<FavoriteIcon/>}
                        tooltipTitle={"Made by Pringe.de"}
                        tooltipOpen
                        onClick={() => window.open("https://pringe.de", "_blank")}
                    />
                    }
                </SpeedDial>
            </Box>
            </Style>
        </>
    )
}

const Style = styled.div`
.MuiFab-primary {
    background-color: white;
    transition: 1s;
}

.MuiFab-primary:hover {
    background-color: var(--main-bg-color-sec);
}
`

export default Copyright