import React from "react";
import {Box} from "@material-ui/core";
import styled from "styled-components";
import {motion} from "framer-motion";
import {pageAnimation} from "../animation";
import ScrollTop from "../components/ScrollTop";
import TopNav from "../components/TopNav";
import FotoShow from "../components/FotoShow";
import PicGalery from "../components/PicGalery";
import Viewer from "react-viewer";

function Startpage() {
    const images = [
        {
            src: "/img/play/pic (1).jpg",
        },
        {
            src: "/img/play/pic (2).jpg",
        },
        {
            src: "/img/play/pic (3).jpg",
        },
        {
            src: "/img/play/pic (4).JPG",
        }
    ]

    const referenzen = [
        {
            src: "/img/startseite/VR Simulatoren.jpg",
            label: "VR Simulatoren",
        },
        {
            src: "/img/startseite/Schlittschuhbahn.jpg",
            label: "Schlittschuhbahn",
        },
        {
            src: "/img/startseite/Promotion.JPG",
            label: "Promotion",
        },
        {
            src: "/img/startseite/Künstler.jpg",
            label: "Künstler",
        },
        {
            src: "/img/startseite/Incentives.jpg",
            label: "Incentives",
        },
        {
            src: "/img/startseite/Highlights.JPG",
            label: "Highlights",
        },
        {
            src: "/img/startseite/Großveranstaltungen.JPG",
            label: "Großveranstaltungen",
        },
        {
            src: "/img/startseite/Animation.jpg",
            label: "Animation",
        },
    ]

    return (
        <>
            <motion.div
                exit="exit"
                variants={pageAnimation}
                initial="hidden"
                animate="show"
            >
                <Box height={"100vh"} width={"100vw"} overflow={"hidden"}>
                    <TopNav position={"relative"}/>
                    <FotoShow images={images}/>
                </Box>
                <Box padding={"50px 0px 25px 0"}>
                    <PicGalery gap={15} cols={8} pictures={referenzen} fullBox={true} ribbonsEverywhere={true} redlines={true}/>
                </Box>
            </motion.div>
            <ScrollTop/>
        </>
    )
}

export default Startpage