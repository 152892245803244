import React from "react";
import {scrollReveal} from "../animation";
import {motion} from "framer-motion";
import styled from "styled-components";
import {useMediaQuery} from "@material-ui/core";

function Ansprechpartner({tel, email, strase, ort, color}) {
    const matches400 = useMediaQuery('(max-width:400px)');

    return (
        <>
            <Styling className={"ansprechpartner"} variants={scrollReveal} style={{padding: matches400 && "0 25px"}}>
                <div className={"flex-row"}>
                    <div className={"icon"}>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="50px" y="50px"
                             viewBox="0 0 511.999 511.999">
                            <g>
                                <g>
                                    <g>
                                        <path fill={color} d="M397.027,329.423c-20.7-20.7-54.202-20.702-74.902-0.001c-14.267,14.267-22.296,25.371-40.977,25.371
				c-10.064,0-19.524-3.919-26.641-11.035l-86.268-86.268c-7.116-7.116-11.035-16.578-11.035-26.64
				c0-18.801,11.423-27.028,25.37-40.975c20.7-20.698,20.703-54.203,0-74.904l-31.804-31.804c-20.651-20.652-54.253-20.651-74.904,0
				c-59.929,59.929-56.778,56.247-58.769,60.132c-30.684,59.85-19.347,132.015,28.211,179.573L189.128,466.69
				c47.562,47.563,119.726,58.893,179.571,28.211c3.879-1.988,0.192,1.172,60.132-58.769c20.7-20.698,20.703-54.203,0.001-74.905
				L397.027,329.423z M400.551,407.85l-52.804,52.804c-43.796,21.042-95.82,12.278-130.342-22.244L73.586,294.59
				c-34.523-34.522-43.284-86.547-22.245-130.342l52.804-52.804c5.059-5.056,13.29-5.056,18.346,0l31.804,31.802
				c5.068,5.068,5.071,13.275,0,18.346c-15.364,15.365-37.084,32.822-37.084,69.255c0,20.746,8.08,40.249,22.749,54.919
				l86.268,86.268c14.669,14.671,34.174,22.749,54.921,22.749c36.281,0,53.685-21.515,69.255-37.084
				c5.071-5.071,13.274-5.07,18.345,0l31.804,31.802C405.621,394.573,405.622,402.78,400.551,407.85z"/>
                                        <path fill={color} d="M346.134,194.141l92.318-92.316c7.809-7.808,7.809-20.47,0-28.279c-7.808-7.809-20.47-7.809-28.279,0l-92.317,92.317
				c-7.809,7.808-7.809,20.471,0,28.279C325.662,201.949,338.325,201.951,346.134,194.141z"/>
                                        <path fill={color} d="M492.002,231.098H361.446c-11.043,0-19.996,8.953-19.996,19.996c0,11.043,8.953,19.996,19.996,19.996h130.556
				c11.043,0,19.996-8.953,19.996-19.996C511.999,240.051,503.046,231.098,492.002,231.098z"/>
                                        <path fill={color} d="M260.904,170.549c11.043,0,19.996-8.953,19.996-19.996V19.996C280.901,8.953,271.948,0,260.904,0
				s-19.996,8.953-19.996,19.996v130.558C240.908,161.597,249.861,170.549,260.904,170.549z"/>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <a href={`tel:${tel}`}>
                        <p>{tel}</p>
                    </a>
                </div>
                <div className={"flex-row"}>
                    <div className={"icon"}>
                        <svg version="1.1" id="Layer_1" x="0px" y="0px"
                             viewBox="0 0 330.001 330.001">
                            <g id="XMLID_348_">
                                <path id="XMLID_350_" fill={color} d="M173.871,177.097c-2.641,1.936-5.756,2.903-8.87,2.903c-3.116,0-6.23-0.967-8.871-2.903L30,84.602
		L0.001,62.603L0,275.001c0.001,8.284,6.716,15,15,15L315.001,290c8.285,0,15-6.716,15-14.999V62.602l-30.001,22L173.871,177.097z"
                                />
                                <polygon id="XMLID_351_" fill={color}
                                         points="165.001,146.4 310.087,40.001 19.911,40 	"/>
                            </g>
                        </svg>
                    </div>
                    <a href={`mailto:${email}`}>
                        <p>{email}</p>
                    </a>
                </div>
                <div className={"flex-row"}>
                    <div className={"icon"}>
                        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 92.53 122.88">
                            <path fill={color} className="cls-1"
                                  d="M46.27,24.08A22.75,22.75,0,1,1,23.52,46.83,22.75,22.75,0,0,1,46.27,24.08Zm1.36,91.62A82.87,82.87,0,0,0,64,101.81,85.44,85.44,0,0,0,84.56,62.23c2.81-13.67,1.5-27-4.75-37.34a36.74,36.74,0,0,0-6.63-8.06C65.62,9.93,57,6.64,48.23,6.56,39.07,6.49,29.77,10,21.55,16.5a38.54,38.54,0,0,0-8.63,9.56C7.15,35.15,5.41,46.43,7.31,58.24c1.94,12,7.66,24.61,16.77,36A102.46,102.46,0,0,0,47.63,115.7Zm21.24-9.46a89.32,89.32,0,0,1-19.33,16,3.28,3.28,0,0,1-3.71.13,109.25,109.25,0,0,1-26.9-24c-9.8-12.31-16-26-18.1-39.1C-1.33,45.89.7,33,7.36,22.53a45.3,45.3,0,0,1,10.1-11.18C26.85,3.87,37.6-.09,48.29,0,58.6.09,68.79,3.92,77.6,12a43.1,43.1,0,0,1,7.82,9.52c7.15,11.8,8.71,26.83,5.57,42.05a92.2,92.2,0,0,1-22.12,42.7Z"/>
                        </svg>
                    </div>
                    <p>{strase},
                        <br/>
                        {ort}</p>
                </div>
            </Styling>
        </>
    )
}

const Styling = styled(motion.div)`
padding: 0 35px;
display: flex;
justify-content: center;
align-items: flex-start;
width: 100%;
height: 100%;
flex-direction: column;
text-align: left !important;
        
.icon {
    height: 50px;
    width: 50px;
}
        
a {
    text-decoration: none;
}
        
.flex-row {
    display: flex;
    justify-conent: center;
    align-items: center;
            
    p {
        padding-left: 30px;
    }
}
`

export default Ansprechpartner