import React from "react";
import {motion} from "framer-motion";
import {pageAnimation} from "../animation";
import ScrollTop from "../components/ScrollTop";
import styled from "styled-components";
import {Box, useMediaQuery} from "@material-ui/core";

function AGB() {
    const matches = useMediaQuery('(max-width:800px)');

    return (
        <>
            <motion.div
                exit="exit"
                variants={pageAnimation}
                initial="hidden"
                animate="show"
            >
                <Box width={!matches ? "50%" : "100%"} margin={"0 auto"}>
                    <h2>AGB der S.O.F.A. GmbH</h2>
                    <Line />
                    <List>
                        <li>Diese allgemeinen Geschäftsbedingungen sind Grundlage und Bestandteile aller
                            Vertragsverhältnisse zwischen der Firma
                            S.O.F.A. GmbH und Ihrem Vertragspartner genannt Auftraggeber.
                        </li>
                        <li>
                            Sämtliche Leistungen und Angebote der S.O.F.A. GmbH erfolgen ausschließlich aufgrund dieser
                            Geschäftsbedingungen. Sie
                            gelten auch für alle künftigen Geschäftsbeziehungen mit dem Auftraggeber.
                        </li>
                        <li>Die Angebote der S.O.F.A. GmbH sind grundsätzlich freibleibend und unverbindlich. Die
                            Auftragserteilung durch den
                            Auftraggeber sowie die Auftragsbestätigung bedürfen zur Rechtswirksamkeit der Schriftform.
                            Verträge kommen erst durch
                            schriftliche Auftragsbestätigung oder durch tatsächliche erbrachte Leistungen der S.O.F.A.
                            GmbH zustande.
                        </li>
                        <li>Für Art und Umfang von der S.O.F.A. GmbH zu erbringenden Leistungen ist ausschließlich die
                            mit dem Auftraggeber
                            getroffenen vertraglichen Vereinbarungen maßgeblich. Berät die S.O.F.A. GmbH den
                            Auftraggeber bei der Festlegung der
                            Leistung, erfolgt dieses nach bestem Wissen. Die Entscheidung über Leistung sowie deren
                            Zweckmäßigkeit trifft jedoch
                            letztendlich der Auftraggeber in eigener Verantwortung.
                        </li>
                        <li>Bei einer Stornierung des Auftrages durch den Auftraggeber 7 Tage vor der Veranstaltung
                            berechnen wir 30 % des vereinbarten
                            Mietpreises, bei einer Absage vor Ort, vor dem Aufbau der Geräte, noch 80%.
                        </li>
                        <li>Kann ein bestätigter Termin nicht eingehalten werden, werden bereits geleistete Zahlungen
                            zurückerstattet. Der S.O.F.A. GmbH
                            entstehen keine Kosten.
                        </li>
                        <li>Die Rechnungsstellung erfolgt durch die S.O.F.A. GmbH sofort nach dem jeweiligen Termin.
                            Beanstandungen der Rechnung
                            müssen der S.O.F.A. GmbH binnen 8 Tagen nach Rechnungsdatum schriftlich zugehen. Nach Ablauf
                            dieser Frist gilt die
                            Rechnung als genehmigt.
                        </li>
                        <li>Im Falle des Zahlungsrückstandes gehen sämtliche Mahn- und Inkassokosten zu Lasten des
                            Auftraggebers..
                        </li>
                        <li>Bei Anlieferung von Mietgegenständen hat der Auftraggeber unverzüglich nach Erhalt, Mengen
                            und erkennbare Mängel zu
                            prüfen.
                        </li>
                        <li>Änderungen der Geschäftsbedingungen werden durch die S.O.F.A. GmbH dem Auftraggeber
                            schriftlich bekannt gegeben. Sollten
                            nur einzelne Bestimmungen ganz oder teilweise unwirksam sein, so wird davon die Wirksamkeit
                            der übrigen Bestimmungen nicht
                            berührt.
                        </li>
                        <li>Der Gerichtsstand ist Plön für alle unmittelbar oder mittelbar ergebenen Streitigkeiten
                            zwischen der S.O.F.A. GmbH und dem
                            Auftraggeber.
                        </li>
                    </List>
                </Box>
            </motion.div>
            <ScrollTop/>
        </>
    )
}

const Line = styled(motion.div)`
height: 0.5rem;
background: var(--main-accent-color);
margin-bottom: 3rem;
`

const List = styled.ol`
font-size: 1.4rem;
color: var(--text-color);

li {
    margin-left: 35px;
    margin-top: 7.5px;
    margin-bottom: 7.5px;
}
`

export default AGB